import { Error } from "./Error";
import { getErrorProps } from "../../utils";

import type { FallbackProps, TError } from "./ErrorFallback.types";

export const ErrorFallback = (props: FallbackProps) => {
  const { error, resetErrorBoundary } = props;
  const errorProps = getErrorProps(error);

  const createActionHandler = (
    error: TError,
    resetErrorBoundary: () => void
  ): (() => void) => {
    //TOD: customize it based on error types
    return () => {
      resetErrorBoundary();
    };
  };

  return (
    <Error
      {...errorProps}
      buttonAction={createActionHandler(errorProps, resetErrorBoundary)}
    />
  );
};
