import React from "react";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { FormControlProps, BaseFormControl } from "./BaseFormControl";
import { useFormContext, Controller } from "react-hook-form";

export const DropdownFormControl: React.FC<FormControlProps> = ({
  field,
  name,
}) => {
  const { control } = useFormContext();

  const defaultValue =
    field.defaultValue !== undefined ? field.defaultValue : "";

  return (
    <BaseFormControl field={field} name={name}>
      <InputLabel>{field.label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <Select
            value={value || ""}
            onChange={onChange}
            label={field.label}
            disabled={field.disabled}
          >
            {field.options?.map((option) => (
              <MenuItem key={option.key} value={option.key}>
                {option.value}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </BaseFormControl>
  );
};
