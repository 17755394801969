import moment from "moment";
import { store } from "../redux/store";
import { VehicleQuestion } from "./paymentTypes";
import "moment/min/locales";

import { VisitJsonApiBlockWithId } from "../api/model/resources-visits.yml";
import i18next from "i18next";

export interface TransformedVccCardType {
  target_account_id: string;
  target_account_type: string;
  target_account_identifier: string;
  target_account_title: string;
  country_iso: string;
  balance: number;
  currency?: string;
}

export interface KeyValue {
  key: string;
  value: string;
}

export function dateString(visit: VisitJsonApiBlockWithId): string | null {
  return null;
  // const hideVisitMetaData =
  //   store.getState().auth.profile?.hide_visits_meta_info || visit.attributes.screening;
  // if (hideVisitMetaData) return null;
  // if (visit.attributes.createdAt) {
  //   return formatDateOnly(visit.attributes.createdAt);
  // } else {
  //   const earliest = formatDateOnly(visit.earliest_date);
  //   const latest = formatDateOnly(visit.latest_date);
  //   return `${earliest} - ${latest}`;
  // }
}

export interface Token {
  access_token: string;
  requiresPinChange: boolean;
}

/* TODO: Some of these no longer exist in the API response or they are hardcoded data */
export interface Profile {
  is_locked: boolean;
  is_displaced: boolean;
  original_country: string | null;
  name: string | null;
  email: string | null;
  is_email_verified: boolean | null;
  latest_accepted_tc?: Date;
  are_tc_rejected: boolean;
  hide_visits_meta_info: boolean;
  distance_rule_screens: VehicleQuestion[];
  available_claim_types: string[];
}

export interface VisitsResponse {
  visits: VisitJsonApiBlockWithId[];
}

export function isFutureDate(dateString: string): boolean {
  const date = moment(dateString, "YYYY-MM-DD");
  return date.isAfter();
}

export function formattedShortDate(date: string | Date) {
  return new Date(date).toLocaleDateString(i18next.language, {
    day: "2-digit",
    month: "short",
  });
}

export function formatDateOnly(dateString: string): string {
  const date = moment(dateString, "YYYY-MM-DD");
  return date.toDate().toLocaleDateString(i18next.language, {
    day: "2-digit",
    month: "narrow",
    year: "numeric",
  });
}

export function formatISODate(
  dateString: string,
  format: "date" | "datetime" = "date"
): string {
  const date = new Date(dateString);
  if (format === "date")
    return date.toLocaleDateString(i18next.language, {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

  return date.toLocaleString(i18next.language, {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
}

export const isElectricVehicle = (): boolean => {
  const questions = store.getState().auth.profile?.distance_rule_screens ?? [];
  const electricVehicleQuestion = questions.find(
    (q) => q.property === "is_electric"
  );
  if (
    electricVehicleQuestion &&
    typeof electricVehicleQuestion.selected_option?.value === "boolean"
  ) {
    return electricVehicleQuestion.selected_option.value;
  }
  return false;
};

export const isEmptyObject = (obj: any) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const cleanEmptyProperties = (obj: any, ignoreKey: string = "") => {
  const cleanedObj = Object.entries(obj)
    .filter(([key, value]) => {
      return (
        key !== ignoreKey &&
        value !== null &&
        value !== undefined &&
        value !== ""
      );
    })
    .reduce((acc: any, [key, value]) => ({ ...acc, [key]: value }), {});

  return cleanedObj;
};

export interface LoginData {
  clientId: string;
  yearOfBirth?: number;
  pin: string;
  pin_confirmation?: string;
  isNewUser: boolean;
}

// Mock data for pending stipends
export const mockPendingStipends = [
  {
    id: "1",
    attributes: {
      amount: "200.00",
      dueDate: "2024-12-01T00:00:00Z",
      title: "Study Completion Bonus",
      status: "pending",
    },
  },
  {
    id: "2",
    attributes: {
      amount: "150.00",
      dueDate: "2024-12-15T00:00:00Z",
      title: "Follow-up Visit Stipend",
      status: "pending",
    },
  },
];

export interface ExpenseRowData {
  id: string;
  createdAt: string;
  type: "Mileage" | "Stipend" | string;
  amount: string;
  status: "pending" | "approved" | "accepted" | "rejected";
  receipt_url?: string;
  label?: string;
}

// Mock data
export const mockExpenses: ExpenseRowData[] = [
  {
    id: "EXP001",
    createdAt: "2024-07-23T14:29:56+00:00",
    type: "Mileage",
    amount: "120.74",
    status: "pending",
  },
  {
    id: "EXP002",
    createdAt: "2024-07-24T10:15:30+00:00",
    type: "Meal",
    amount: "45.50",
    status: "approved",
    receipt_url: "https://example.com/receipt1.jpg",
  },
  {
    id: "EXP003",
    createdAt: "2024-07-25T09:00:00+00:00",
    type: "Hotel",
    amount: "200.00",
    status: "rejected",
    receipt_url: "https://example.com/receipt2.jpg",
  },
];

export function convertDataUrlToBlob(dataUrl: any): Blob {
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
}
