import React from "react";
import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, PaymentMethod } from "../types/payment-method-types";
import { FormControlFactory } from "./FormControl/FormControlFactory";
import { SchemaGenerator } from "../services/schema-generator";
import { AddressFieldsProvider } from "../contexts/address-fields-context";
import { usePaymentMethodForms } from "../contexts/payment-method-form-context";
import { useTranslation } from "react-i18next";

interface PaymentMethodFormProps {
  paymentMethod: PaymentMethod;
  form: Form;
  onSubmit: (values: Record<string, string>) => void;
  asyncValidators?: Record<string, (value: string) => Promise<string | null>>;
}

export const PaymentMethodForm: React.FC<PaymentMethodFormProps> = ({
  paymentMethod,
  form,
  onSubmit,
  asyncValidators = {},
}) => {
  const { t } = useTranslation();
  const { formData, updateFormData } = usePaymentMethodForms();
  const [isLoading, setIsLoading] = React.useState(false);

  // Initialize form with existing data for this form
  const defaultValues = React.useMemo(() => {
    return formData[form.name] || {};
  }, [formData, form.name]);

  const schema = React.useMemo(
    () => SchemaGenerator.generateFormSchema(form),
    [form]
  );

  const methods = useForm({
    resolver: zodResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues,
  });

  const handleSubmit = methods.handleSubmit(async (data) => {
    setIsLoading(true);

    const asyncErrors: Record<string, string> = {};
    for (const [fieldName, validator] of Object.entries(asyncValidators)) {
      const error = await validator(data[fieldName]);
      if (error) {
        asyncErrors[fieldName] = error;
      }
    }

    if (Object.keys(asyncErrors).length > 0) {
      Object.entries(asyncErrors).forEach(([field, error]) => {
        methods.setError(field, { message: error });
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    updateFormData(form.name, data);
    onSubmit(data);
  });

  const formContent = (
    <form onSubmit={handleSubmit} style={{ margin: "12px 0" }}>
      <Grid container spacing={2}>
        {form.fields.map((field) => (
          <Grid item xs={12} key={field.name}>
            {FormControlFactory.createFormControl({
              field,
              name: field.name,
              countryCode: paymentMethod.country,
            })}
          </Grid>
        ))}
        <Grid item xs={12}>
          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            type="submit"
            loading={isLoading}
          >
            {t("continue_text")}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <FormProvider {...methods}>
      <AddressFieldsProvider form={form}>{formContent}</AddressFieldsProvider>
    </FormProvider>
  );
};
