import axios, { isAxiosError } from "axios";
import { ServerError, ValidationErrorResponseResponse } from "src/api/model";
import { NETWORK_ERROR_CODES } from "src/types/network";
import { navigateToLogin } from "./navigation";

export function isApiServerError(error: unknown): error is ServerError {
  return (
    typeof error === "object" &&
    error !== null &&
    "errors" in error &&
    Array.isArray(error.errors)
  );
}

export function isValidationErrorResponse(
  error: unknown
): error is ValidationErrorResponseResponse {
  return (
    typeof error === "object" &&
    error !== null &&
    "errors" in error &&
    Array.isArray(error.errors) &&
    error.errors.length > 0 &&
    error.errors[0].source !== undefined
  );
}

const defaultError = {
  title: "error",
  message: "error_somethingWentWrong",
};

export const parseError = (error: unknown) => {
  if (!error) {
    return defaultError;
  }

  if (axios.isAxiosError(error)) {
    const errorData = error.response?.data;
    if (errorData && isApiServerError(errorData) && errorData.errors) {
      if (isValidationErrorResponse(errorData)) {
        const details = errorData.errors.map(
          (error) => error.detail || defaultError.message
        );
        return {
          title: "error_validation_title",
          message: details.join("\n"),
        };
      }

      const errorItem = errorData.errors[0];
      return {
        title: errorItem.title,
        message: errorItem.detail || defaultError.message,
      };
    }
    return {
      title: defaultError.title,
      message: error.message || defaultError.message,
    };
  }

  return defaultError;
};

const createSerializableLocation = (location: Location) => ({
  pathname: location.pathname,
  search: location.search,
  hash: location.hash,
});

export const handleQueryError = (error: unknown) => {
  const status = isAxiosError(error) ? error.response?.status : null;

  if (status === NETWORK_ERROR_CODES.UNAUTHORIZED) {
    const serializableLocation = createSerializableLocation(window.location);
    navigateToLogin(serializableLocation);
  }
};
