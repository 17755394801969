import { useListAllLanguages } from "src/api/client/languages/languages";
import { formatLanguageCode } from "../helpers";
import { useMemo } from "react";
export const useLanguages = () => {
  const { data, isLoading, error, refetch } = useListAllLanguages(undefined, {
    query: {
      staleTime: 1000 * 60 * 60,
      refetchOnWindowFocus: false,
      select: (data) => data.data,
    },
  });

  const formattedLangs = useMemo(
    () =>
      data?.map((lang) => ({
        ...lang,
        attributes: {
          ...lang.attributes,
          code: formatLanguageCode(lang.attributes.code),
        },
      })) ?? [],
    [data]
  );

  return {
    languages: formattedLangs,
    languagesLoading: isLoading,
    languagesError: error,
    refetchLanguages: refetch,
  };
};
