import { useDispatch } from "react-redux";

import { showError } from "src/redux/slices/toastSlice";
import { parseError } from "../errorHandler";
import { NavigateOptions, useLocation, useNavigate } from "react-router-dom";
import { navigateToLogin } from "../navigation";

export const useErrorHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleMutationError = (error: unknown) => {
    const { title, message } = parseError(error);
    dispatch(showError({ title, message }));
  };

  return {
    handleUnauthenticatedUser: (options: NavigateOptions = {}) => {
      navigateToLogin(location, options, navigate);
    },
    handleMutationError,
  };
};
