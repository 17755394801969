// TODO: Consolidate address fields handling.
// The current AI-generated address handling needs refinement.
// Consider implementing a dedicated formatter that groups and formats preview items based on their field types.
// This approach would keep the payment method form configuration simple while allowing flexible customization on the preview page.

import React, { useState } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  FormControlLabel,
  Checkbox,
  Link,
  Box,
} from "@mui/material";
import { usePaymentMethodForms } from "../contexts/payment-method-form-context";
import {
  PaymentMethod,
  FieldType,
  Form,
  isVirtualCard,
} from "../types/payment-method-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { getFieldIcon as getIconFromUtils } from "../utils/field-icons";
import CardContainer from "src/pages/CardContainer";
import { CardAgreementDialog } from "src/components/CardAgreementDialog";

// Helper function to get icon for a field type
const getFieldIcon = (
  fieldName: string,
  fieldType: FieldType
): React.ReactElement => {
  return getIconFromUtils(fieldName, fieldType);
};

// Helper function to format field label
const formatFieldLabel = (fieldName: string): string => {
  return fieldName
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
};

// Helper function to determine if a field is an address field
const isAddressField = (fieldType: FieldType): boolean => {
  const addressTypes = [
    "address_lineOne",
    "address_city",
    "address_state",
    "address_zip",
  ];
  return addressTypes.includes(fieldType);
};

const EXCLUDED_PREVIEW_TYPES: FieldType[] = ["search"];

// Add this helper function to find the form title by form type
const findFormTitle = (form: Form, formType: string): string | undefined => {
  if (form.type === formType) {
    return form.title;
  }

  // Recursively check 'then' form if it exists
  if (form.then) {
    return findFormTitle(form.then, formType);
  }

  return undefined;
};

// Modify the groupAddressFields function to filter out excluded types
const groupAddressFields = (
  formData: Record<string, string>,
  paymentMethod: PaymentMethod
): Record<string, { value: string; type: FieldType; label: string }> => {
  const addressValues: string[] = [];
  const result: Record<
    string,
    { value: string; type: FieldType; label: string }
  > = {};

  // Get field types and labels from payment method forms including all nested forms
  const fieldInfo = React.useMemo(() => {
    const info: Record<string, { type: FieldType; label: string }> = {};

    const addFieldsRecursively = (form: Form) => {
      form.fields.forEach((field) => {
        info[field.name] = {
          type: field.type,
          label: field.label || field.name, // Use label if available, fallback to field name
        };
      });

      if (form.then) {
        addFieldsRecursively(form.then);
      }
    };

    addFieldsRecursively(paymentMethod.form);
    return info;
  }, [paymentMethod]);

  // Process each form data entry
  Object.entries(formData).forEach(([key, value]) => {
    const fieldType = fieldInfo[key]?.type;
    const fieldLabel = fieldInfo[key]?.label;

    // Skip excluded field types
    if (EXCLUDED_PREVIEW_TYPES.includes(fieldType)) {
      return;
    }

    if (isAddressField(fieldType)) {
      addressValues.push(value);
    } else {
      result[key] = {
        value,
        type: fieldType,
        label: fieldLabel || formatFieldLabel(key), // Fallback to formatted key if no label
      };
    }
  });

  // Add combined address if there are address fields
  if (addressValues.length > 0) {
    result["address"] = {
      value: addressValues.filter(Boolean).join(", "),
      type: "address",
      label: findFormTitle(paymentMethod.form, "address") || "Address",
    };
  }

  return result;
};

export const PaymentMethodPreview: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    getAllFormData,
    paymentMethod,
    submitFormData,
    isSubmitting,
    cardAgreementAccepted,
    setCardAgreementAccepted,
  } = usePaymentMethodForms();
  const [cardAgreementDialogOpen, setCardAgreementDialogOpen] = useState(false);

  const allFormData = getAllFormData();

  if (!paymentMethod) {
    return null;
  }

  const groupedFormData = groupAddressFields(allFormData, paymentMethod);

  const handleSubmit = async () => {
    await submitFormData();
  };

  const getIsDisabled = () => {
    const isDisabled =
      isVirtualCard(paymentMethod.type) && !cardAgreementAccepted;

    return isSubmitting || isDisabled;
  };

  const onChangeCardAgreement = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCardAgreementAccepted(event.target.checked);
  };

  return (
    <CardContainer showBorder={true}>
      <CardContainer.Header>
        <CardContainer.HeaderContent>
          <CardContainer.HeaderTextItem>
            {paymentMethod?.title ?? t("mileageReview_PaymentMethod")}
          </CardContainer.HeaderTextItem>
          <CardContainer.HeaderTextItem>
            {t("auth_accountActivation_recheck_description")}
          </CardContainer.HeaderTextItem>
        </CardContainer.HeaderContent>
      </CardContainer.Header>
      <CardContainer.Content>
        <List>
          {Object.entries(groupedFormData).map(
            ([field, { value, type, label }], index, array) => {
              if (!value) return null;
              return (
                <React.Fragment key={field}>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      {getFieldIcon(field, type)}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          color="text.primary"
                          sx={{ mb: 0.5 }}
                        >
                          {value}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" color="text.secondary">
                          {formatFieldLabel(label)}
                        </Typography>
                      }
                    />
                  </ListItem>
                  {index < array.length - 1 && (
                    <Divider component="li" sx={{ my: 1 }} />
                  )}
                </React.Fragment>
              );
            }
          )}
        </List>

        {isVirtualCard(paymentMethod.type) && (
          <Box sx={{ my: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  data-test-id="accept-card-agreement-checkbox"
                  checked={cardAgreementAccepted}
                  onChange={onChangeCardAgreement}
                  name="cardAgreement"
                  color="primary"
                />
              }
              label={
                <Typography
                  data-test-id="accept-card-agreement-label"
                  variant="subtitle1"
                >
                  {t("accept")} &nbsp;
                  <Typography
                    data-test-id="accept-card-agreement-link"
                    variant="subtitle1"
                    component={Link}
                    onClick={(e) => {
                      e.preventDefault();
                      setCardAgreementDialogOpen(true);
                    }}
                  >
                    {t("card_agreement")}
                  </Typography>
                </Typography>
              }
            />
            <CardAgreementDialog
              dismissible={true}
              open={cardAgreementDialogOpen}
              onClose={() => setCardAgreementDialogOpen(false)}
              onAccept={() => {
                setCardAgreementAccepted(true);
                setCardAgreementDialogOpen(false);
              }}
            />
          </Box>
        )}

        <Button
          fullWidth
          variant="outlined"
          size="large"
          onClick={() =>
            navigate(`/payment-methods/${paymentMethod.type}/create`)
          }
          disabled={isSubmitting}
          sx={{ mb: 2 }}
        >
          Edit Information
        </Button>

        <LoadingButton
          fullWidth
          variant="contained"
          size="large"
          loading={isSubmitting}
          onClick={handleSubmit}
          disabled={getIsDisabled()}
        >
          {t("continue_text")}
        </LoadingButton>
      </CardContainer.Content>
    </CardContainer>
  );
};

export default PaymentMethodPreview;
