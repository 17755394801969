import { TFunction } from "i18next";
import { PaymentMethodType } from "../types/payment-method-types";
import { isBankAccount, isCheck } from "../types/payment-method-types";
import { isVirtualCard } from "../types/payment-method-types";

interface DescribePaymentAccountProps {
  accountType: string;
  accountTitle?: string;
  identifier?: string;
  t: TFunction;
}

export function isSamePaymentMethod(
  A: PaymentMethodType,
  B?: PaymentMethodType
) {
  if (isVirtualCard(A) && isVirtualCard(B)) {
    return true;
  }
  if (isBankAccount(A) && isBankAccount(B)) {
    return true;
  }
  if (isCheck(A) && isCheck(B)) {
    return true;
  }
  if (A === PaymentMethodType.DebitCard && B === PaymentMethodType.DebitCard) {
    return true;
  }
  return false;
}

export function describePaymentAccount({
  accountType,
  t,
  accountTitle,
  identifier,
}: DescribePaymentAccountProps) {
  if (!identifier) {
    return accountTitle ?? accountType;
  }

  if (isVirtualCard(accountType)) {
    return t("claimDetail_vccDescription", { "0": identifier.slice(-4) });
  } else if (isBankAccount(accountType)) {
    return t("claimDetail_bankDescription", { "0": identifier.slice(-4) });
  } else if (isCheck(accountType)) {
    return t("claimDetail_checkDescription", { "0": identifier });
  } else if (accountType === PaymentMethodType.DebitCard) {
    return t("claimDetail_cardDescription", { "0": identifier });
  }

  return accountTitle ?? accountType;
}

export function standardizePaymentAccountType(accountType: PaymentMethodType) {
  if (isVirtualCard(accountType)) {
    return PaymentMethodType.VirtualCard;
  } else if (isBankAccount(accountType)) {
    return PaymentMethodType.BankAccount;
  } else if (isCheck(accountType)) {
    return PaymentMethodType.Check;
  }

  return accountType;
}
