export enum NETWORK_ERROR_CODES {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  TIMEOUT = 408,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503,
  TOO_MANY_REQUESTS = 522,
  BAD_GATEWAY = 523,
  SERVICE_UNAVAILABLE_RETRY = 524,
  NETWORK_READ_TIMEOUT = 599,
}
