import React from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useCardContext } from "./CardContext";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";

const CardActions: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { cardVisible, toggleCardVisibility } = useCardContext();

  return (
    <>
      <List sx={{ backgroundColor: "white" }}>
        <ListItemButton onClick={toggleCardVisibility}>
          <ListItemIcon>
            {cardVisible ? (
              <VisibilityOffOutlinedIcon />
            ) : (
              <VisibilityOutlinedIcon />
            )}
          </ListItemIcon>
          <ListItemText
            primary={cardVisible ? t("card_hide") : t("card_show")}
          />
        </ListItemButton>
        <Divider />

        <ListItemButton onClick={() => navigate(`/card/faq`)}>
          <ListItemIcon>
            <InfoOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={t("card_about_your_card")} />
        </ListItemButton>
        <ListItemButton onClick={() => navigate(`/card/agreement`)}>
          <ListItemIcon>
            <AdminPanelSettingsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={t("card_agreement")} />
        </ListItemButton>
      </List>

      <section>
        <Typography mt={3} mb={1} variant="subtitle2" color="text.secondary">
          {t("card_upcoming_features")}
        </Typography>

        <List sx={{ backgroundColor: "white" }}>
          <ListItemButton disabled>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary={t("card_balance_history")} />
          </ListItemButton>
          <Divider />
          <ListItemButton disabled>
            <ListItemIcon>
              <LockOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t("card_lock_card")} />
          </ListItemButton>
        </List>
      </section>
    </>
  );
};

export default CardActions;
