import i18n from "i18next";

import { useGetParticipantAccountDetails } from "src/api/client/participant/participant";
import { formatLanguageCode } from "../helpers";
import { ParticipantAccountDetailsResponseResponseIncluded } from "src/api/model/resources-participant.yml/participantAccountDetailsResponseResponseIncluded";
import {
  getTranslationByLanguageCode,
  standardizeTranslations,
} from "./translations";

export const useUserDetails = () => {
  const { data, isLoading, error } = useGetParticipantAccountDetails({
    request: {
      params: {
        include:
          "language,arm,site,arm.visitSchedule.study,site.country,arm.visitSchedule.study.defaultCurrency,paymentMethods",
      },
    },
    query: {
      staleTime: 1000 * 60 * 60,
      refetchOnWindowFocus: false,
    },
  });

  const userDetails = data?.data;
  const included: ParticipantAccountDetailsResponseResponseIncluded | [] =
    data?.included ?? [];

  const arm = included.find((item: { type: string }) => item.type === "arms");
  const site = included.find((item: { type: string }) => item.type === "sites");
  const study = included.find(
    (item: { type: string }) => item.type === "studies"
  );
  const country = included?.find(
    (item: { type: string }) => item.type === "countries"
  )?.attributes.code;
  const accountLanguage = included?.filter(
    (item: { type: string }) => item.type === "languages"
  )?.[0];

  if (accountLanguage)
    accountLanguage.attributes.code = formatLanguageCode(
      accountLanguage.attributes.code
    );

  const currency = included?.find(
    (item: { type: string }) => item.type === "currencies"
  );
  const currencyId = currency?.id;
  const currencyCode = currency?.attributes.code ?? "USD";
  const claimantId = parseInt(userDetails?.id ?? "", 10);
  const participantId = userDetails?.attributes.clientId;
  const distanceUnit =
    site?.attributes?.travelRateUnitOfMeasurement ?? "kilometers";
  const taxRequirement = {
    workflow: userDetails?.attributes.taxRequirement,
    isCompliant: userDetails?.attributes.taxCompliant,
  };

  const isTaxCompliant =
    taxRequirement.isCompliant ||
    taxRequirement.workflow === "no tax requirement";

  const studyTranslations = standardizeTranslations(
    study?.attributes.translations
  );

  /* Temporarily use translations object */
  const translatedStudy = {
    ...study,
    attributes: {
      ...study?.attributes,
      ...getTranslationByLanguageCode(studyTranslations, i18n.language),
    },
  };

  return {
    distanceUnit,
    country,
    currencyCode,
    currencyId,
    accountLanguage, // not used
    claimantId,
    participantId,
    arm,
    study: translatedStudy,
    site,
    userDetails: data,
    userDetailsLoading: isLoading,
    userDetailsError: error,
    taxRequirement,
    isTaxCompliant,
  };
};
