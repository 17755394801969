import GetAppIcon from "@mui/icons-material/GetApp";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

import { ConfirmationDialog } from "../ConfirmationDialog";
import { CardAgreement } from "../LegalDocuments/CardAgreement";
import { CardAgreementDialogProps } from "./CardAgreementDialog.types";
import { getLegalDocumentLinks } from "../LegalDocuments/utils";

export const CardAgreementDialog = (props: CardAgreementDialogProps) => {
  const { open, onAccept, dismissible, onClose } = props;
  const { t } = useTranslation();
  const handleDownloadPdf = (link: string) => () => {
    window.open(link, "_blank");
  };
  const cardAgreementLinks = getLegalDocumentLinks("card-agreement");

  return (
    <ConfirmationDialog
      label="card-agreement-dialog"
      maxWidth="md"
      dismissible={dismissible}
      fullWidth={true}
      open={open}
      title={t("card_agreement")}
      message={
        <span
          style={{
            padding: 0,
            height: "60vh",
            overflow: "hidden",
            display: "block",
          }}
        >
          <CardAgreement />
        </span>
      }
      ActionsComponent={
        <Button
          data-test-id="card-agreement-dialog-action-button"
          onClick={handleDownloadPdf(cardAgreementLinks.pdf)}
          startIcon={<GetAppIcon />}
        >
          {t("button_download_pdf")}
        </Button>
      }
      confirmButtonText={t("accept")}
      onConfirm={onAccept}
      onDismiss={dismissible && onClose ? onClose : () => {}}
      hideCancelButton={true}
    />
  );
};
