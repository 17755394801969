import {
  PaymentMethod,
  PaymentMethodType,
  Form,
} from "../types/payment-method-types";

const addressForm: Form = {
  title: "Address Information",
  name: "address",
  type: "address",
  fields: [
    {
      type: "search",
      name: "search",
      label: "Search address",
      required: false,
    },
    {
      type: "address_lineOne",
      name: "lineOne",
      label: "House name or number and street",
      required: true,
      minLength: 2,
      maxLength: 100,
    },
    {
      type: "address_city",
      name: "city",
      label: "City",
      required: true,
      minLength: 2,
      maxLength: 40,
    },
    {
      type: "address_state",
      name: "state",
      label: "State",
      required: true,
      options: [
        {
          key: "AL",
          value: "Alabama",
        },
        {
          key: "AK",
          value: "Alaska",
        },
        {
          key: "AZ",
          value: "Arizona",
        },
        {
          key: "AR",
          value: "Arkansas",
        },
        {
          key: "CA",
          value: "California",
        },
        {
          key: "CO",
          value: "Colorado",
        },
        {
          key: "CT",
          value: "Connecticut",
        },
        {
          key: "DE",
          value: "Delaware",
        },
        {
          key: "FL",
          value: "Florida",
        },
        {
          key: "GA",
          value: "Georgia",
        },
        {
          key: "HI",
          value: "Hawaii",
        },
        {
          key: "ID",
          value: "Idaho",
        },
        {
          key: "IL",
          value: "Illinois",
        },
        {
          key: "IN",
          value: "Indiana",
        },
        {
          key: "IA",
          value: "Iowa",
        },
        {
          key: "KS",
          value: "Kansas",
        },
        {
          key: "KY",
          value: "Kentucky",
        },
        {
          key: "LA",
          value: "Louisiana",
        },
        {
          key: "ME",
          value: "Maine",
        },
        {
          key: "MD",
          value: "Maryland",
        },
        {
          key: "MA",
          value: "Massachusetts",
        },
        {
          key: "MI",
          value: "Michigan",
        },
        {
          key: "MN",
          value: "Minnesota",
        },
        {
          key: "MS",
          value: "Mississippi",
        },
        {
          key: "MO",
          value: "Missouri",
        },
        {
          key: "MT",
          value: "Montana",
        },
        {
          key: "NE",
          value: "Nebraska",
        },
        {
          key: "NV",
          value: "Nevada",
        },
        {
          key: "NH",
          value: "New Hampshire",
        },
        {
          key: "NJ",
          value: "New Jersey",
        },
        {
          key: "NM",
          value: "New Mexico",
        },
        {
          key: "NY",
          value: "New York",
        },
        {
          key: "NC",
          value: "North Carolina",
        },
        {
          key: "ND",
          value: "North Dakota",
        },
        {
          key: "OH",
          value: "Ohio",
        },
        {
          key: "OK",
          value: "Oklahoma",
        },
        {
          key: "OR",
          value: "Oregon",
        },
        {
          key: "PA",
          value: "Pennsylvania",
        },
        {
          key: "RI",
          value: "Rhode Island",
        },
        {
          key: "SC",
          value: "South Carolina",
        },
        {
          key: "SD",
          value: "South Dakota",
        },
        {
          key: "TN",
          value: "Tennessee",
        },
        {
          key: "TX",
          value: "Texas",
        },
        {
          key: "UT",
          value: "Utah",
        },
        {
          key: "VT",
          value: "Vermont",
        },
        {
          key: "VA",
          value: "Virginia",
        },
        {
          key: "WA",
          value: "Washington",
        },
        {
          key: "WV",
          value: "West Virginia",
        },
        {
          key: "WI",
          value: "Wisconsin",
        },
        {
          key: "WY",
          value: "Wyoming",
        },
        {
          key: "DC",
          value: "District of Columbia",
        },
        {
          key: "AS",
          value: "American Samoa",
        },
        {
          key: "GU",
          value: "Guam",
        },
        {
          key: "MP",
          value: "Northern Mariana Islands",
        },
        {
          key: "PR",
          value: "Puerto Rico",
        },
        {
          key: "UM",
          value: "United States Minor Outlying Islands",
        },
        {
          key: "VI",
          value: "U.S. Virgin Islands",
        },
      ],
    },
    {
      type: "address_zip",
      name: "zip",
      label: "ZIP Code",
      required: true,
      minLength: 1,
      maxLength: 10,
    },
  ],
};

export const usPaymentMethods: PaymentMethod[] = [
  {
    id: PaymentMethodType.VirtualCard,
    title: "Virtual Card",
    type: PaymentMethodType.VirtualCard,
    country: "US",
    form: {
      title: "Virtual Card Information",
      name: "virtual-card",
      type: "object",
      fields: [
        {
          type: "name",
          name: "givenName",
          label: "Given Name",
          description: "Your given name",
          required: true,
        },
        {
          type: "name",
          name: "middleName",
          label: "Middle Name",
          description: "Your middle name",
          required: false,
        },
        {
          type: "name",
          name: "familyName",
          label: "Family Name",
          description: "Your family name",
          required: true,
        },
        {
          type: "tel",
          name: "phone",
          label: "Phone",
          required: true,
        },
        {
          type: "email",
          name: "email",
          label: "Email",
          required: true,
          minLength: 3,
          maxLength: 200,
          pattern: "(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$)",
        },
      ],
      then: addressForm,
    },
    endpoint: "/v2/payment-methods/vc",
  },
  {
    id: PaymentMethodType.BankAccount,
    title: "Bank Account",
    type: PaymentMethodType.BankAccount,
    country: "US",
    form: {
      title: "Bank Account Information",
      name: "ach-out",
      type: "object",
      fields: [
        {
          type: "name",
          name: "fullName",
          label: "First and Last Name",
          required: true,
          minLength: 2,
          maxLength: 140,
          pattern:
            "^[a-zA-Z0-9_'.-]{2,}\\s+[a-zA-Z0-9_'.-]{2,}(\\s+[a-zA-Z0-9_'.-]{2,})*$",
          description:
            "Enter your first and last name as it appears on your bank account",
        },
        {
          type: "string",
          name: "routing",
          label: "Routing Number",
          required: true,
          minLength: 9,
          maxLength: 9,
          pattern: "^[0-9]+$",
        },
        {
          type: "string",
          name: "account",
          label: "Account Number",
          required: true,
          minLength: 4,
          maxLength: 17,
          pattern: "^[0-9]+$",
        },
        {
          type: "radio",
          name: "type",
          label: "Account type",
          required: true,
          options: [
            { key: "Checking", value: "Checking" },
            { key: "Savings", value: "Savings" },
          ],
        },
      ],
      then: addressForm,
    },
    endpoint: "/v1/payment-methods/bank-account",
  },
];
