import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, Button, Theme, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import CardContainer from "../CardContainer";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getStatusStyles } from "./taxFormUtils";
import { LoadingButton } from "@mui/lab";
import {
  TaxFormAttributes,
  TaxFormJsonApiBlockWithId,
} from "../../api/model/resources-tax-forms.yml";
import { formatISODate } from "../../types/common";
import { formatKeyText, openLink } from "../../common/helpers";

interface Props {
  data: TaxFormJsonApiBlockWithId[];
}

const InfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 0),
}));

const baseStatusStyles = (theme: Theme) => ({
  padding: theme.spacing(0.5, 2),
  fontWeight: 500,
  borderRadius: theme.shape.borderRadius,
  display: "inline-block",
});

const TaxFormsList: React.FC<Props> = ({ data }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  function topRightContentFor(item: TaxFormAttributes) {
    const status = item.formStatus.toLowerCase();
    if (item.downloadUrl)
      return (
        <Button
          size="medium"
          startIcon={<FileDownloadIcon />}
          sx={{ mr: 1, ml: 2, mt: 1 }}
          variant="contained"
          color="primary"
          onClick={() => window.open(item.downloadUrl, "_blank")}
        >
          {t("button_download_pdf")}
        </Button>
      );

    if (status === "pending")
      return (
        <Button
          size="medium"
          startIcon={<EditNoteIcon />}
          variant="contained"
          color="primary"
          sx={{
            margin: {
              sm: "20px 0px 0px",
              xs: "12px 0",
            },
          }}
          onClick={() => item.formUrl && openLink(item.formUrl, "_self")}
        >
          {t("tax_form_continue")}
        </Button>
      );
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: {
            xs: "flex-start",
            sm: "flex-end",
          },
        }}
      >
        <LoadingButton
          data-test-id="invitation-delete-button"
          size="large"
          variant="contained"
          color="primary"
          loading={false}
          loadingPosition="start"
          onClick={() => navigate("/tax/submit")}
        >
          {t("tax_update")}
        </LoadingButton>
      </Box>

      {data.map((item) => {
        const attributes = item.attributes!;

        return (
          <CardContainer key={attributes.submissionId}>
            <CardContainer.Header>
              <CardContainer.HeaderContent>
                <CardContainer.HeaderTextItem>
                  {formatKeyText(t(attributes.formStatus.toLowerCase())) ??
                    t("tax_form")}
                </CardContainer.HeaderTextItem>
                {attributes.updatedAt && (
                  <CardContainer.HeaderTextItem>
                    {t("lastUpdate", {
                      date: formatISODate(attributes.updatedAt),
                    })}
                  </CardContainer.HeaderTextItem>
                )}
              </CardContainer.HeaderContent>
              <CardContainer.TopRightContent>
                {topRightContentFor(attributes)}
              </CardContainer.TopRightContent>
            </CardContainer.Header>
            <CardContainer.Content>
              <Box component="article">
                <Box component="section">
                  <InfoBox>
                    <Typography variant="body1">
                      {t("tax_submission_id")}
                    </Typography>
                    <Typography
                      textAlign={"end"}
                      fontWeight={500}
                      variant="body1"
                    >
                      {attributes.submissionId}
                    </Typography>
                  </InfoBox>

                  <InfoBox>
                    <Typography variant="body1">
                      {t("tax_delivery_method")}
                    </Typography>
                    <Typography
                      sx={{ textTransform: "capitalize" }}
                      textAlign={"end"}
                      fontWeight={500}
                      variant="body1"
                    >
                      {t("tax_" + attributes.deliveryMethod)}
                    </Typography>
                  </InfoBox>

                  <InfoBox>
                    <Typography variant="body1">{t("status")}</Typography>
                    <Box
                      sx={{
                        ...baseStatusStyles(theme),
                        ...getStatusStyles(attributes.formStatus, theme),
                      }}
                    >
                      <Typography sx={{ fontWeight: 500 }} variant="body1">
                        {formatKeyText(t(attributes.formStatus.toLowerCase()))}
                      </Typography>
                    </Box>
                  </InfoBox>
                </Box>
              </Box>
            </CardContainer.Content>
          </CardContainer>
        );
      })}
    </>
  );
};

export default TaxFormsList;
