import { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useLocation } from "react-router-dom";

import { ErrorFallback } from "./ErrorFallback";

export const CustomErrorBoundary = (props: PropsWithChildren) => {
  const { children } = props;
  const pathname = useLocation().pathname;

  return (
    <ErrorBoundary
      key={pathname}
      FallbackComponent={ErrorFallback}
      onError={(error) => console.log("Received error", error)}
    >
      {children}
    </ErrorBoundary>
  );
};
