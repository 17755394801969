import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Divider,
  Skeleton,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import LatestPayment from "../components/Payment/LatestPayment";
import CardContainer from "./CardContainer";
import { usePaymentMethods } from "src/common/hooks/usePaymentMethods";

export interface DetailSection {
  title: string;
  subtitle?: string;
  content: string;
}

export interface VisitDetailSection {
  title: string;
  content: string;
  date: string | null;
}

export interface Action {
  label: string;
  onClick: () => void;
  color: "primary" | "secondary";
  variant: "contained" | "outlined";
  loading: boolean;
}

export interface SummaryPageUIProps {
  title?: string;
  headerIcon?: React.ReactNode;
  isLoading: boolean;
  showChangePaymentMethodButton: boolean;
  subtitle: string;
  visitSection: VisitDetailSection;
  detailSections: DetailSection[];
  notes?: string | null;
  totalSection: DetailSection;
  actions: Action[];
  topRightContent?: React.ReactNode;
  bottomContent?: React.ReactNode;
  hidePaymentMethodSection?: boolean;
}

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const SummaryPageUI: React.FC<SummaryPageUIProps> = ({
  title,
  headerIcon,
  isLoading,
  showChangePaymentMethodButton,
  subtitle,
  visitSection,
  detailSections,
  notes,
  totalSection,
  actions,
  topRightContent,
  bottomContent,
  hidePaymentMethodSection = false,
}) => {
  const { t } = useTranslation();

  const { defaultPaymentMethod, paymentMethodsFetching } = usePaymentMethods();

  return (
    <CardContainer isLoading={isLoading}>
      <CardContainer.Header>
        {headerIcon && (
          <CardContainer.Icon>
            <Avatar>{headerIcon}</Avatar>
          </CardContainer.Icon>
        )}
        <CardContainer.HeaderContent>
          {title && (
            <CardContainer.HeaderTextItem>{title}</CardContainer.HeaderTextItem>
          )}
          <CardContainer.HeaderTextItem>
            {subtitle}
          </CardContainer.HeaderTextItem>
        </CardContainer.HeaderContent>
        {topRightContent && (
          <CardContainer.TopRightContent>
            {topRightContent}
          </CardContainer.TopRightContent>
        )}
      </CardContainer.Header>

      <CardContainer.Content>
        {notes && (
          <Alert severity="error" sx={{ mb: 1 }}>
            <AlertTitle
              data-test-id="rejection-reason"
              sx={{ fontWeight: "bold" }}
            >
              {t("claimDetail_rejectionReason")}
            </AlertTitle>
            {notes}
          </Alert>
        )}
        <Typography variant="h6">{visitSection.title}</Typography>
        {isLoading ? (
          <Skeleton
            data-test-id="visit-skeleton"
            variant="rectangular"
            width={242}
            height={24}
          />
        ) : (
          <Typography data-test-id="visit-info" variant="body1">
            {visitSection.content}{" "}
            {visitSection.date && (
              <span style={{ color: "gray" }}>({visitSection.date})</span>
            )}
          </Typography>
        )}
        {detailSections.length > 0 && (
          <>
            <StyledDivider />

            {detailSections.map((section, index) => (
              <Box
                data-test-id={`details-section-${index}`}
                key={index}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography
                    data-test-id={`details-title-${index}`}
                    variant="body1"
                  >
                    {section.title}
                  </Typography>
                  <Typography
                    data-test-id={`details-subtitle-${index}`}
                    variant="subtitle2"
                    fontStyle={"italic"}
                  >
                    {section.subtitle}
                  </Typography>
                </Box>
                <Typography
                  data-test-id={`details-content-${index}`}
                  variant="h6"
                >
                  {section.content}
                </Typography>
              </Box>
            ))}
          </>
        )}

        <Divider style={{ margin: "20px 0", borderBottomWidth: "thick" }} />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography data-test-id="total-title" variant="h6">
            {totalSection.title}
          </Typography>
          <Typography
            data-test-id="total-value"
            variant="h6"
            style={{ fontWeight: "bold" }}
          >
            {totalSection.content}
          </Typography>
        </Box>

        {bottomContent && <Box>{bottomContent}</Box>}

        {!hidePaymentMethodSection &&
          defaultPaymentMethod &&
          !paymentMethodsFetching && (
            <LatestPayment
              paymentAccount={defaultPaymentMethod.attributes}
              showChangeButton={showChangePaymentMethodButton}
              useTableStyle={false}
            />
          )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            mt: 2,
            justifyContent: "end",
          }}
        >
          {actions.map((action, index) => (
            <LoadingButton
              data-test-id="submit-button"
              key={index}
              variant={action.variant}
              color={action.color}
              size="large"
              sx={{ mb: 2 }}
              onClick={action.onClick}
              loading={action.loading}
              disabled={actions.some((act) => act.loading)}
            >
              {action.label}
            </LoadingButton>
          ))}
        </Box>
      </CardContainer.Content>
    </CardContainer>
  );
};

export default SummaryPageUI;
