import CardContainer from "../../../pages/CardContainer";
import { useNavigate, useParams } from "react-router-dom";
import { PaymentMethodForm } from "../components/payment-method-form";
import { asyncValidators } from "../services/validator";
import { Form } from "../types/payment-method-types";
import { useTranslation } from "react-i18next";
import React from "react";
import { RootState } from "src/redux/store";
import { useSelector } from "react-redux";

export default function PaymentMethodPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { paymentMethodName, formName } = useParams<{
    paymentMethodName: string;
    formName: string;
  }>();

  const paymentMethod = useSelector((state: RootState) =>
    state.paymentMethods.availablePaymentMethods.find(
      (method) => method.id === paymentMethodName
    )
  );

  if (!paymentMethod) {
    throw new Error("Payment Method not found");
  }

  function getForm(formName: string, form: Form) {
    if (formName === form?.name) {
      return form;
    }
    const nextForm = form.then;
    if (nextForm) {
      return getForm(formName, nextForm);
    }
    console.error("Form not found");
    return null;
  }

  const currentForm = formName
    ? getForm(formName, paymentMethod.form)
    : paymentMethod.form;

  if (!currentForm) {
    throw new Error("Payment Method Form not found");
  }

  const handleSubmit = React.useCallback(() => {
    if (!currentForm.then) {
      navigate(`/payment-methods/${paymentMethodName}/preview`);
      return;
    }

    navigate(
      `/payment-methods/${paymentMethodName}/create/${currentForm.then.name}`
    );
  }, [currentForm, navigate, paymentMethodName]);

  return (
    <CardContainer showBorder={true}>
      <CardContainer.Header>
        <CardContainer.HeaderContent>
          <CardContainer.HeaderTextItem>
            {paymentMethod?.title ?? t("mileageReview_PaymentMethod")}
          </CardContainer.HeaderTextItem>
          <CardContainer.HeaderTextItem>
            {t("payment_dynamic_description")}
          </CardContainer.HeaderTextItem>
        </CardContainer.HeaderContent>
      </CardContainer.Header>
      <CardContainer.Content>
        <PaymentMethodForm
          paymentMethod={paymentMethod}
          form={currentForm}
          onSubmit={handleSubmit}
          asyncValidators={asyncValidators}
        />
      </CardContainer.Content>
    </CardContainer>
  );
}
