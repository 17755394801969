import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePageTitle } from "../common/TitleContext";
import { getSymbol } from "../common/helpers";
import { useUserDetails } from "src/common/hooks/useUserDetails";
import { RootState } from "src/redux/store";

function MileageThresholdPage() {
  const { t } = useTranslation();

  usePageTitle(t("claim_chooseClaimMileage"));
  const navigate = useNavigate();
  const { currencyCode } = useUserDetails();
  const currencySymbol = getSymbol(currencyCode);

  const rateCalculationResults = useSelector(
    (state: RootState) => state.claims.mileageClaim
  )?.rateCalculationResults;

  if (!rateCalculationResults) {
    return <Typography>{t("loading_text")}</Typography>;
  }

  const { rates, distance_threshold, total_distance } = rateCalculationResults;
  const oldRate = rates.length > 0 ? rates[0].rate : 0;
  const newRate = rates.length > 1 ? rates[1].rate : 0;

  function onContinue() {
    navigate("/payment/submit/mileage/summary");
  }

  return (
    <Box
      className="mileage-threshold-page"
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Box className="header" width={"100%"} mb={1}>
        <Box className="icon-and-text" width={"100%"}>
          <Box
            className="icon"
            component={"img"}
            src="/images/fuel_empty.svg"
            alignContent={"center"}
            alignSelf={"center"}
            width={"200px"}
            display={"block"}
            margin={"auto"}
          />
          <Typography
            data-test-id="threshold-title"
            textAlign={"center"}
            variant="h6"
            fontWeight={"bold"}
          >
            {t("thresholdExceeded_title", { "0": distance_threshold })}
          </Typography>
        </Box>
        <Typography
          data-test-id="threshold-description"
          variant="body1"
          textAlign={"center"}
        >
          {t("thresholdExceeded_description")}
        </Typography>
      </Box>

      <Stack
        sx={{ my: 2 }}
        direction={"row"}
        width={"100%"}
        className="distance-info"
      >
        <Typography
          data-test-id="threshold-total-title"
          className="label"
          flexGrow={"1"}
        >
          {t("thresholdExceeded_total")}
        </Typography>
        <Typography data-test-id="threshold-total-value" className="value">
          {total_distance.toLocaleString()} km
        </Typography>
      </Stack>

      <Divider sx={{ width: "100%" }} />

      <Box
        sx={{ my: 2 }}
        component={"section"}
        width={"100%"}
        className="rates-info"
      >
        <Stack direction={"row"} width={"100%"} className="old-rate">
          <Typography
            data-test-id="threshold-old-rate-title"
            variant="body1"
            className="label"
            flexGrow={"1"}
          >
            {t("thresholdExceeded_oldRate")}
          </Typography>
          <Typography
            data-test-id="threshold-old-rate-currency"
            variant="h6"
            className="value"
          >
            {currencySymbol}
            {oldRate.toFixed(2)}
          </Typography>
        </Stack>
        <Stack direction={"row"} width={"100%"} className="new-rate">
          <Typography
            data-test-id="threshold-new-rate-title"
            variant="body1"
            className="label"
            flexGrow={"1"}
          >
            {t("thresholdExceeded_newRate")}
          </Typography>
          <Typography
            data-test-id="threshold-new-rate-currency"
            variant="h6"
            className="value"
          >
            {currencySymbol}
            {newRate.toFixed(2)}
          </Typography>
        </Stack>
      </Box>

      <Button
        data-test-id="submit-button"
        fullWidth
        size="large"
        variant="contained"
        color="primary"
        onClick={onContinue}
      >
        {t("continue_text")}
      </Button>
    </Box>
  );
}

export default MileageThresholdPage;
