import React from "react";

import ErrorBox from "../Modal/ErrorBox";
import { useTranslation } from "react-i18next";

type FormErrorBoxProps = {
  error: Error | string | null;
};

const FormErrorBox: React.FC<FormErrorBoxProps> = ({ error }) => {
  const { t } = useTranslation();
  let title: string | undefined;
  let message: string;

  if (!error) {
    message = t("error_somethingWentWrong");
  } else {
    const isStringError = typeof error === "string";
    title = t("error");
    message = isStringError ? error : error.message || "";
  }

  return <ErrorBox title={title} text={message} marginBottom="20px" />;
};

export default FormErrorBox;
