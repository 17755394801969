import React, { createContext, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  isVirtualCard,
  PaymentMethod,
  PaymentMethodType,
} from "../types/payment-method-types";
import { RootState } from "../../../redux/store";
import {
  updateFormData,
  resetForm,
} from "../../../redux/slices/paymentMethodFormSlice";
import {
  getPaymentMethods,
  getPaymentMethodsQueryKey,
  useCreateNewPaymentMethod,
  useSetDefaultPaymentMethodById,
} from "src/api/client/payment-methods/payment-methods";
import { showToast } from "src/redux/slices/toastSlice";
import { useTranslation } from "react-i18next";
import {
  removeSpacesAndHyphens,
  stripTextAfterHyphen,
} from "src/common/helpers";
import { parsePhoneNumber } from "libphonenumber-js";
import { MileageClaimData, ReceiptClaimData } from "src/types/paymentTypes";
import { useErrorHandler } from "src/common/hooks";
import { useQueryClient } from "@tanstack/react-query";
interface PaymentMethodFormsContextType {
  formData: Record<string, Record<string, string>>;
  paymentMethod?: PaymentMethod;
  updateFormData: (formName: string, newData: Record<string, string>) => void;
  resetFormData: () => void;
  submitFormData: () => Promise<void>;
  isSubmitting: boolean;
  getAllFormData: () => Record<string, string>;
  setDefaultPaymentMethod: (id: string, onSuccess?: () => void) => void;
  isSettingDefaultPaymentMethod: boolean;
  goToNextPage: () => void;
  cardAgreementAccepted: boolean;
  setCardAgreementAccepted: (accepted: boolean) => void;
}

const PaymentMethodFormsContext = createContext<
  PaymentMethodFormsContextType | undefined
>(undefined);

interface PaymentMethodFormsProviderProps {
  children: React.ReactNode;
  onSubmit: (values: Record<string, string>) => Promise<void>;
}

export const PaymentMethodFormsProvider: React.FC<
  PaymentMethodFormsProviderProps
> = ({ children, onSubmit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleMutationError } = useErrorHandler();
  const { paymentMethodName } = useParams<{ paymentMethodName: string }>();
  const queryClient = useQueryClient();
  const [cardAgreementAccepted, setCardAgreementAccepted] = useState(false);

  const formData = useSelector(
    (state: RootState) => state.paymentMethodForm.formData
  );

  const receiptClaimFormData: ReceiptClaimData | null = useSelector(
    (state: RootState) => state.claims.receiptClaim
  );
  const mileageClaimFormData: MileageClaimData | null = useSelector(
    (state: RootState) => state.claims.mileageClaim
  );

  const paymentMethod = useSelector((state: RootState) =>
    state.paymentMethods.availablePaymentMethods.find(
      (method) => method.id === paymentMethodName
    )
  );

  const updateFormDataHandler = (
    formName: string,
    newData: Record<string, string>
  ) => {
    dispatch(updateFormData({ formName, data: newData }));
  };

  const resetFormData = () => {
    dispatch(resetForm());
  };

  const getAllFormData = () => {
    return Object.values(formData).reduce(
      (acc, formValues) => ({
        ...acc,
        ...formValues,
      }),
      {}
    );
  };

  const formatFormData = (formData: Record<string, Record<string, string>>) => {
    if (!paymentMethod?.form) {
      return formData;
    }

    const firstFormName = paymentMethod.form.name;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: Record<string, any> = {};

    // Process all form data
    Object.entries(formData).forEach(([formName, formValues]) => {
      // First trim all string values
      const trimmedValues = Object.fromEntries(
        Object.entries(formValues).map(([key, value]) => [
          key,
          typeof value === "string" ? value.trim() : value,
        ])
      );

      if (formName === firstFormName) {
        // Flatten first form data
        Object.assign(result, trimmedValues);
      } else {
        // Keep other forms nested under their names
        result[formName] = trimmedValues;
      }
    });

    // TOOD: ask BE team to handle this
    // Process zip code if it exists
    if (result.zip) {
      result.zip = removeSpacesAndHyphens(stripTextAfterHyphen(result.zip));
    }

    // TOOD: ask BE team to handle this
    // Process phone number if it exists
    if (result.phone) {
      const cleanedPhone = result.phone.replace(/\s+/g, "");
      try {
        const phoneObject = parsePhoneNumber(cleanedPhone);
        result.phone = phoneObject.nationalNumber;
        result.phoneCountryCode = `+${phoneObject.countryCallingCode}`;
      } catch (error) {
        console.error("Error processing phone number:", error);
      }
    }

    return result;
  };

  // TODO: SaaS BE team to handle this
  function mapPPSPaymentMethodTypeToSaaSBackendType(
    paymentMethodType: PaymentMethodType
  ) {
    switch (paymentMethodType) {
      case PaymentMethodType.VirtualCard:
        return "virtual-card";
      case PaymentMethodType.BankAccount:
        return "ach-out";
      default:
        throw new Error(
          `Unsupported payment method type: ${paymentMethodType}`
        );
    }
  }

  const {
    mutate: setDefaultPaymentMethodById,
    isPending: isSettingDefaultPaymentMethodById,
  } = useSetDefaultPaymentMethodById({
    mutation: {
      onError: (error) => {
        handleMutationError(error);
      },
    },
  });

  function setDefaultPaymentMethod(id: string, onSuccess?: () => void) {
    setDefaultPaymentMethodById(
      {
        id,
        data: {
          data: {
            type: "payment-methods",
            id,
          },
        },
      },
      {
        onSuccess: () => {
          onSuccess?.();
        },
      }
    );
  }

  const { mutate: createPaymentMethod, isPending: isCreatingPaymentMethod } =
    useCreateNewPaymentMethod({
      mutation: {
        onError: (error) => {
          handleMutationError(error);
        },
        onSuccess: async (data) => {
          const paymentMethodId = data.data?.id;
          const accountType = data.data?.attributes.accountType;

          if (!data.data || !paymentMethodId || !accountType) {
            throw new Error("Payment method ID not found");
          }

          /*
            Prefetch payment methods to update the list of payment methods.
            This is to avoid a race condition where the payment methods list
            is not updated immediately after creating a new payment method and
            the user is redirected to the payment list page again.
          */
          await queryClient.prefetchQuery({
            queryKey: getPaymentMethodsQueryKey(),
            queryFn: () => getPaymentMethods(),
          });

          handleCreationSuccess(accountType);
        },
      },
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePayment = async (formattedFormData: Record<string, any>) => {
    const formattedValues = formatFormData(formattedFormData);

    const createAttributes = {
      type: mapPPSPaymentMethodTypeToSaaSBackendType(paymentMethod!.type),
      blob: {
        paymentCountryIso: paymentMethod?.country,
        ...formattedValues,
      },
      ...(isVirtualCard(paymentMethod!.type) && {
        hasAcceptedCardholderAgreement: cardAgreementAccepted,
      }),
    };

    createPaymentMethod({
      data: {
        data: {
          type: "payment-methods",
          attributes: createAttributes,
        },
      },
    });
  };

  async function handleCreationSuccess(type: string) {
    resetFormData();
    if (isVirtualCard(type)) {
      navigate("/payment-methods/new-card-success", { replace: true });
    } else {
      dispatch(showToast(t("paymentMethod_updated")));
      goToNextPage();
    }
  }

  const submitFormData = async () => {
    if (!paymentMethod) {
      throw new Error("Payment method not set");
    }

    await handlePayment(formData);
    await onSubmit(getAllFormData());
  };

  function goToNextPage() {
    const navigationPath = receiptClaimFormData
      ? "/payment/submit/receipt/summary"
      : mileageClaimFormData
      ? "/payment/submit/mileage/summary"
      : "/payments";

    navigate(navigationPath);
  }

  return (
    <PaymentMethodFormsContext.Provider
      value={{
        formData,
        paymentMethod,
        updateFormData: updateFormDataHandler,
        resetFormData,
        submitFormData,
        isSubmitting: isCreatingPaymentMethod,
        getAllFormData,
        setDefaultPaymentMethod,
        isSettingDefaultPaymentMethod: isSettingDefaultPaymentMethodById,
        goToNextPage,
        cardAgreementAccepted,
        setCardAgreementAccepted,
      }}
    >
      {children}
    </PaymentMethodFormsContext.Provider>
  );
};

export const usePaymentMethodForms = () => {
  const context = useContext(PaymentMethodFormsContext);
  if (!context) {
    throw new Error(
      "usePaymentMethodForms must be used within a PaymentMethodFormsProvider"
    );
  }
  return context;
};
