import { useEffect, useState } from "react";
import {
  INotificationEvent,
  updateNotificationSettings,
} from "../api/notificationService";
import {
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useTranslation } from "react-i18next";
import { isNotificationSupported } from "../common/constants";
import { useErrorHandler } from "src/common/hooks";

const NotificationSwitch = () => {
  const { t } = useTranslation();
  const { handleMutationError } = useErrorHandler();
  const theme = useTheme();
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const notificationPreferences = useSelector(
    (state: RootState) => state.auth.notificationPreferences
  );

  useEffect(() => {
    if (isNotificationSupported) {
      if (notificationPreferences?.groups.length) {
        if (
          !notificationPreferences?.groups[0]?.events.some(
            (x: INotificationEvent) => x.is_allowed
          )
        ) {
          setNotificationsEnabled(false);
        } else {
          setNotificationsEnabled(Notification.permission === "granted");
        }
      }
    }
  }, [notificationPreferences, isNotificationSupported]);

  const handleNotificationChange = async (enable: boolean) => {
    if (enable && Notification.permission === "denied") {
      setDialogOpen(true);

      return;
    }

    if (enable && Notification.permission === "default") {
      const permission = await Notification.requestPermission();

      if (permission !== "granted") {
        return;
      }
    }

    setNotificationsEnabled(enable);

    try {
      await updateNotificationSettings(enable);
    } catch (error) {
      handleMutationError(error);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <form>
        <Switch
          edge="end"
          checked={notificationsEnabled}
          onChange={(event) => handleNotificationChange(event.target.checked)}
          inputProps={{
            "aria-labelledby": "switch-notification",
          }}
        />
      </form>

      <Dialog
        dir={theme.direction}
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle>{t("notification_denied_title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("notification_denied_description")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationSwitch;
