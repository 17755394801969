import BrowseGalleryOutlinedIcon from "@mui/icons-material/BrowseGalleryOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import WatchLaterSharpIcon from "@mui/icons-material/WatchLaterSharp";
import { Color, styled } from "@mui/material";
import { ReactElement } from "react";
import { getConfigValue } from "src/config/useConfig";
import { selectEnableNotification } from "src/config/selectors";

export const TOKEN_STORAGE_KEY = "accessToken";

export const getLastFourDigits = (value: string) => {
  if (value.length > 4) {
    return value.substr(value.length - 4);
  }
  return value;
};

export const displayStateIcon: Record<string, ReactElement<Color>> = {
  approved: <CheckCircleIcon color="success" />,
  pending: <BrowseGalleryOutlinedIcon color="info" />,
  incomplete: <WatchLaterSharpIcon color="info" />,
  declined: <CancelIcon color="error" />,
};

export const WhiteSecurityIcon = styled(HttpsOutlinedIcon)(({ theme }) => ({
  color: theme.palette.common.white,
  marginRight: "0.5rem",
}));

export const listItemStyle = {
  display: "flex",
  justifyContent: "space-between",
  borderBottom: 1,
  borderColor: "divider",
  pl: 0,
};

export const succesStatus = [200, 201, 204];

export const hiddenFooterPages = ["termsAndConditions", "support"];

export const clearTokenAndRedirect = () => {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
  window.location.href = "/";
};

const enableNotification = getConfigValue(selectEnableNotification);

export const isNotificationSupported =
  enableNotification && "Notification" in window && "permissions" in navigator;
