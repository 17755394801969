import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { FormControlProps, BaseFormControl } from "./BaseFormControl";
import { useFormContext, Controller } from "react-hook-form";

export const RadioGroupFormControl: React.FC<FormControlProps> = ({
  field,
  name,
}) => {
  const { control } = useFormContext();

  const defaultValue =
    field.defaultValue !== undefined ? field.defaultValue : "";

  return (
    <BaseFormControl field={field} name={name}>
      <FormControl
        fullWidth
        sx={{ textAlign: "left", pl: 1, fontSize: "0.875rem" }}
      >
        <FormLabel>{field.label}</FormLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { onChange, value } }) => (
            <RadioGroup value={value || ""} onChange={onChange}>
              {field.options?.map((option) => (
                <FormControlLabel
                  key={option.key}
                  value={option.key}
                  disabled={field.disabled}
                  control={<Radio />}
                  label={
                    <Typography fontSize="0.875rem">{option.value}</Typography>
                  }
                />
              ))}
            </RadioGroup>
          )}
        />
      </FormControl>
    </BaseFormControl>
  );
};
