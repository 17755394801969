// Claims Slice

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DistanceCalculationResult } from "../../api/paymentService";
import {
  CategoryAmount,
  Claim,
  MileageClaimData,
  ReceiptClaimData,
  UploadResponse,
} from "../../types/paymentTypes";
import { Stipend } from "../../types/stipendTypes";

export const RESET_STATE = "RESET_STATE";

type ClaimsState = {
  waitingStipends: Stipend[];
  claims: Claim[];
  carerClaims: Claim[];
  receiptClaim: ReceiptClaimData | null;
  mileageClaim: MileageClaimData | null;
  claimsPage: number;
  carerClaimsPage: number;
};

const initialState: ClaimsState = {
  waitingStipends: [],
  claims: [],
  carerClaims: [],
  receiptClaim: null,
  mileageClaim: null,
  claimsPage: 1,
  carerClaimsPage: 1,
};

const claimsSlice = createSlice({
  name: "claims",
  initialState,
  reducers: {
    setReceiptClaimVisit: (state, action: PayloadAction<string>) => {
      let id = action.payload;
      state.receiptClaim = {
        ...state.receiptClaim,
        visit_id: id,
      };
    },
    setReceiptClaimVisitDate: (state, action: PayloadAction<string>) => {
      let date = action.payload;
      state.receiptClaim = {
        ...state.receiptClaim,
        visit_date: date,
      };
    },

    setMileageClaimVisit: (state, action: PayloadAction<string>) => {
      let id = action.payload;
      state.mileageClaim = {
        ...state.mileageClaim,
        visit_id: id,
      };
    },
    setMileageClaimVisitDate: (state, action: PayloadAction<string>) => {
      let date = action.payload;
      state.mileageClaim = {
        ...state.mileageClaim,
        visit_date: date,
      };
    },
    clearMileageClaimData: (state, action: PayloadAction) => {
      state.mileageClaim = null;
    },
    clearReceiptClaimData: (state, action: PayloadAction) => {
      state.receiptClaim = null;
    },
    setMileageClaimDistance: (
      state,
      action: PayloadAction<{
        unit: string;
        rateCalculationResults: DistanceCalculationResult;
      }>
    ) => {
      const { unit, rateCalculationResults } = action.payload;
      state.mileageClaim = {
        ...state.mileageClaim,
        items: rateCalculationResults.rates.map((item) => {
          return {
            distance: item.distance,
            original_distance_unit: unit,
            rate: item.rate,
          };
        }),
        total_amount: rateCalculationResults.total_amount,
        rateCalculationResults: rateCalculationResults,
      };
    },
    setReceiptClaimCategoryAmount: (
      state,
      action: PayloadAction<{ key: string; amount: number }>
    ) => {
      const { key, amount } = action.payload;

      if (!state.receiptClaim?.items) {
        return;
      }

      const categoryIndex = state.receiptClaim.items?.findIndex(
        (category) => category.category === key
      );

      if (categoryIndex !== -1) {
        state.receiptClaim.items![categoryIndex].amount = amount;
      }

      state.receiptClaim.total_amount = state.receiptClaim.items.reduce(
        (accumulator, currentValue) => {
          return accumulator + (currentValue.amount ?? 0);
        },
        0
      );
    },
    setReceiptClaimCategories: (
      state,
      action: PayloadAction<CategoryAmount[]>
    ) => {
      const inputCategories = action.payload;
      const currentClaimCategories = state.receiptClaim?.items || [];
      const newCategories: CategoryAmount[] = [];

      // In case of backwards movement, we don't want to overrwrite the categories directly or we will lose the Amount inputs
      inputCategories.forEach((cat) => {
        const existingCat = currentClaimCategories.find(
          (x) => x.category === cat.category
        );

        if (existingCat) {
          newCategories.push(existingCat);
        } else {
          newCategories.push(cat);
        }
      });

      state.receiptClaim = {
        ...state.receiptClaim,
        items: newCategories,
      };
    },
    setReceiptClaimImageData: (state, action: PayloadAction<string>) => {
      state.receiptClaim = {
        ...state.receiptClaim,
        receiptData: action.payload,
      };
    },
    updateClaims: (
      state,
      action: PayloadAction<{ claims: Claim[]; page: number }>
    ) => {
      const { claims, page } = action.payload;
      state.claims = page === 1 ? claims : [...state.claims, ...claims];
      state.claimsPage = page;
    },
    updateCarerClaims: (
      state,
      action: PayloadAction<{ claims: Claim[]; page: number }>
    ) => {
      const { claims, page } = action.payload;
      state.carerClaims =
        page === 1 ? claims : [...state.carerClaims, ...claims];
      state.carerClaimsPage = page;
    },
    resetClaims: (state) => {
      state.claimsPage = 1;
      state.claims = [];
    },
    resetCarerClaims: (state) => {
      state.carerClaimsPage = 1;
      state.carerClaims = [];
    },
    setWaitingStipends: (state, action: PayloadAction<Stipend[]>) => {
      state.waitingStipends = action.payload;
    },
    addStipend: (state, action: PayloadAction<Stipend>) => {
      state?.waitingStipends?.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return initialState;
    });
  },
});

export const {
  clearMileageClaimData,
  setMileageClaimDistance,
  setMileageClaimVisit,
  clearReceiptClaimData,
  setReceiptClaimCategoryAmount,
  setReceiptClaimCategories,
  setReceiptClaimVisit,
  setReceiptClaimImageData,
  setWaitingStipends,
  updateClaims,
  updateCarerClaims,
  resetCarerClaims,
  resetClaims,
  addStipend,
  setReceiptClaimVisitDate,
  setMileageClaimVisitDate,
} = claimsSlice.actions;

export default claimsSlice.reducer;
