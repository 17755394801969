import React, { memo } from "react";
import { Outlet } from "react-router-dom";
import { PaymentMethodFormsProvider } from "../contexts/payment-method-form-context";

function PaymentMethodLayout() {
  // Create a stable onSubmit function
  const handleSubmit = React.useCallback(
    async (values: Record<string, string>) => {
      console.log("Final form submission:", values);
    },
    []
  );

  return (
    <PaymentMethodFormsProvider onSubmit={handleSubmit}>
      <Outlet />
    </PaymentMethodFormsProvider>
  );
}

export default memo(PaymentMethodLayout);
