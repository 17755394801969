import ErrorBase from "./ErrorBase";

export class NotAuthenticatedError extends ErrorBase {
  constructor() {
    super({
      name: "NotAuthenticatedError",
      title: "error_unauthorized_title",
      message: "error_unauthorized_message",
      icon: "/images/unauthorised.svg",
    });
    Object.setPrototypeOf(this, NotAuthenticatedError.prototype);
  }
}
