export const APP_TIMING = {
  TOAST_AUTO_HIDE_DURATION: 5000,
} as const;

export const UI = {
  HEADER: {
    HEIGHT: 64,
    MOBILE_HEIGHT: 56,
  },
  SIDEBAR: {
    WIDTH: 240,
    COLLAPSED_WIDTH: 64,
  },
  Z_INDEX: {
    HEADER: 1100,
    SIDEBAR: 1200,
    MODAL: 1300,
    TOAST: 1400,
  },
} as const;
