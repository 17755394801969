import { useListAllExpenseTypes } from "src/api/client/expense-types/expense-types";

export const useExpenseTypes = () => {
  const { data, isLoading, error } = useListAllExpenseTypes(undefined, {
    query: {
      refetchOnWindowFocus: false,
      select: (data) => data.data,
    },
  });

  return {
    expenseTypes: data ?? [],
    expenseTypesLoading: isLoading,
    expenseTypesError: error,
  };
};
