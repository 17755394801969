import React from "react";
import { FormControlProps, BaseFormControl } from "./BaseFormControl";
import { useFormContext, Controller } from "react-hook-form";
import { CountryCode } from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import AddressSearch from "../../../../components/AddressSearch";
import { useAddressFields } from "../../contexts/address-fields-context";

// SearcFormControl doesn't support disabled state and default value
export const SearchFormControl: React.FC<FormControlProps> = ({
  field,
  name,
  countryCode,
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const { enableFields, fillAddressFields } = useAddressFields();

  return (
    <BaseFormControl field={field} name={name}>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { value } }) => (
          <AddressSearch
            locale="en-US"
            apiKey={import.meta.env.VITE_LOQATE_KEY || ""}
            countries={[countryCode as CountryCode]}
            className="address-search-box"
            classes={{ list: "custom-list-item" }}
            inline
            debounce={100}
            data-test-id="search"
            onSelect={fillAddressFields}
            onManualEntrySelected={enableFields}
            manualEntryText={t("paymentMethod_enterAddressManually")}
          />
        )}
      />
    </BaseFormControl>
  );
};
