import { useListAllVisits } from "../../api/client/visits/visits";
import i18n from "i18next";
import {
  getTranslationByLanguageCode,
  standardizeTranslations,
} from "./translations";

export const useVisits = () => {
  const { data, isLoading, error, refetch, isFetching } = useListAllVisits(
    undefined,
    {
      query: {
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5,
        select: (data) => data.data,
      },
    }
  );

  /* Temporarily use translations object */
  const visits = data?.map((visitData) => {
    const visitTranslations = standardizeTranslations(
      visitData.attributes.translations
    );

    return {
      ...visitData,
      attributes: {
        ...visitData.attributes,
        ...getTranslationByLanguageCode(visitTranslations, i18n.language),
      },
    };
  });

  return {
    visits: visits ?? [],
    visitsLoading: isLoading,
    visitsError: error,
    refetchVisits: refetch,
    visitsFetching: isFetching,
  };
};
