import React, { useRef } from "react";
import { Box, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { renderFields } from "@highnoteplatform/card-viewer";
import styles from "./CardViewer.module.css";
import { useCardContext } from "./CardContext";
import { PaymentMethodVisualisationAttributes } from "../../../api/model/resources-payment-methods.yml";
import { useVisualisePaymentMethodById } from "../../../api/client/payment-methods/payment-methods";
import { useEffectOnUpdate } from "../../../common/hooks";
import { ErrorCard } from "src/components/ErrorCard";
import { useTranslation } from "react-i18next";
import { parseError } from "src/common/errorHandler";
import { useConfig } from "src/config/useConfig";
import { selectClientId } from "src/config/selectors";
import { usePaymentMethods } from "src/common/hooks/usePaymentMethods";

const CardViewer: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { getConfigValue } = useConfig();
  const clientId = getConfigValue(selectClientId);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const { setCustomToggleFunction } = useCardContext();
  const rendered = useRef(false);
  const { virtualCard } = usePaymentMethods();
  const {
    data: highnoteConfigData,
    error: highnoteConfigError,
    isLoading: loadingHighnoteConfig,
    refetch: refetchHighnoteConfig,
  } = useVisualisePaymentMethodById(virtualCard?.id ?? "");

  useEffectOnUpdate(() => {
    const renderCard = async (
      cardData: PaymentMethodVisualisationAttributes
    ) => {
      const cardEnvironment =
        cardData.environment.toLowerCase() === "live" ? "live" : "test";
      const result = await renderFields({
        clientToken: cardData.signature!,
        paymentCardId: cardData.cardId!,
        environment: cardEnvironment,
        enableClipboard: false,
        onError: (error) => {
          console.log(error);
        },
        elements: {
          cardNumber: { selector: "#cardNumber", styles: { color: "white" } },
          cvv: { selector: "#cvv", styles: { color: "white" } },
          expirationDate: {
            selector: "#expirationDate",
            styles: { color: "white" },
          },
        },
      });
      setCustomToggleFunction(() => result.toggleCardNumberMask);
    };

    if (highnoteConfigData?.data?.attributes) {
      if (rendered.current) return;
      else rendered.current = true;
      renderCard(highnoteConfigData.data.attributes);
    }
  }, [highnoteConfigData]);

  if (!virtualCard) return;

  const renderVirtualCard = () => {
    if (highnoteConfigError) {
      const { title, message } = parseError(highnoteConfigError);
      return (
        <ErrorCard
          title={t(title)}
          message={t(message)}
          buttonLabel={t("retry")}
          onButtonClick={() => refetchHighnoteConfig()}
          sx={{ height: "100%" }}
        />
      );
    }

    if (loadingHighnoteConfig) {
      return <Skeleton variant="rounded" height={"100%"} />;
    }

    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className={styles.center}>
          <div
            className={styles.fakeCard}
            style={{
              backgroundImage: `url(/images/${clientId}_wallet_prepaid.svg)`,
            }}
          >
            <div id="cardNumber"></div>
            <div id="fakeCardBottomRow">
              <div id="cvv"></div>
              <div id="expirationDate"></div>
            </div>
          </div>
        </div>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        aspectRatio: 1.55,
        maxWidth: isLargeScreen ? 500 : "100%",
        minWidth: "316px",
        margin: "0 auto",
        borderRadius: "1rem",
        overflow: highnoteConfigError ? "visible" : "hidden",
      }}
    >
      {renderVirtualCard()}
    </Box>
  );
};

export default React.memo(CardViewer);
