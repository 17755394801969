import React from "react";
import { TextField } from "@mui/material";
import { FormControlProps, BaseFormControl } from "./BaseFormControl";
import { useFormContext } from "react-hook-form";

export const TextFormControl: React.FC<FormControlProps> = ({
  field,
  name,
}) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();
  const hasError = !!errors[name];
  const defaultValue =
    field.defaultValue !== undefined ? field.defaultValue : "";

  return (
    <BaseFormControl field={field} name={name}>
      <TextField
        {...register(name, { value: defaultValue })}
        fullWidth
        label={field.label}
        placeholder={field.placeholder}
        error={hasError}
        helperText={hasError ? "" : field.description}
        type={field.type === "number" ? "number" : "text"}
        disabled={field.disabled}
        InputLabelProps={{ shrink: !!watch(name) || defaultValue !== "" }}
      />
    </BaseFormControl>
  );
};
