import { AxiosRequestConfig } from "axios";
import { getToken } from "firebase/messaging";
import { messaging } from "../services/firebase";
import StorageManager from "../services/storage";
import { nmibleAPI as api } from "./mutator/axios-instance";
import { isNotificationSupported } from "../common/constants";
import { ENV } from "src/config/envVariables";

export interface IUpdateNotificationPreferences {
  device_id: string;
  notification: {
    group: string;
    event?: string;
    is_allowed: boolean;
  };
}

export interface IRegisterFCMToken {
  device_id: string;
  fcm_token: string;
}

export interface INotificatonPreferences {
  groups: [INotificationGroup];
}

export interface INotificationGroup {
  title: string;
  type: string;
  events: [INotificationEvent];
}

export interface INotificationEvent {
  event: string;
  event_title: string;
  is_allowed: boolean;
}

export const getPreferences = (device_id: string): AxiosRequestConfig => {
  return {
    url: `/claimants/notifications?device_id=${device_id}`,
    method: "GET",
  };
};

export const updatePreferences = (
  data: IUpdateNotificationPreferences
): AxiosRequestConfig => {
  return {
    url: "/claimants/notifications/",
    method: "PUT",
    data: data,
  };
};

export const registerFCMToken = (
  data: IRegisterFCMToken
): AxiosRequestConfig => {
  return {
    url: "/claimants/fcm-device/register",
    method: "POST",
    data: data,
  };
};

export const deleteFCMToken = (device_id: string): AxiosRequestConfig => {
  return {
    url: `/claimants/fcm?device_id=${device_id}`,
    method: "DELETE",
  };
};

const getServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker.getRegistration("/");
  }

  throw new Error("The browser doesn`t support service worker.");
};

export async function updateNotificationSettings(enable: boolean) {
  if (!isNotificationSupported) {
    return;
  }

  if (!messaging) {
    return;
  }

  if (enable) {
    const permission = await Notification.requestPermission();

    if (permission !== "granted") return;

    const serviceWorkerRegistration = await getServiceWorker();

    const token = await getToken(messaging, {
      vapidKey: ENV.FIREBASE.VAPID_KEY,
      serviceWorkerRegistration,
    });

    if (!token) return;

    await deleteFCM();
    await registerFCM(token);
    await setPreference(true);
  } else {
    await setPreference(false);
  }
}

async function registerFCM(token: string) {
  await api(
    registerFCMToken({
      device_id: StorageManager.getDeviceId(),
      fcm_token: token,
    })
  );
}

async function deleteFCM() {
  await api(deleteFCMToken(StorageManager.getDeviceId()));
}

async function setPreference(value: boolean) {
  await api(
    updatePreferences({
      device_id: StorageManager.getDeviceId(),
      notification: {
        group: "Claims",
        is_allowed: value,
      },
    })
  );
}
