// Token Slice in Redux
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { Profile } from "../../../types/common";
import { INotificatonPreferences } from "../../../api/notificationService";
import { VehicleQuestion } from "../../../types/paymentTypes";
import { RootState } from "../../store";
import StorageManager from "../../../services/storage";

interface AuthState {
  profile?: Profile;
  notificationPreferences?: INotificatonPreferences;
}

const initialState: AuthState = {
  // TODO: Discuss if we need these values in the redux store, they are hardcoded currently
  profile: {
    is_locked: false,
    is_displaced: false,
    are_tc_rejected: true,
    hide_visits_meta_info: false,
    distance_rule_screens: [],
    available_claim_types: ["Receipt", "Mileage"],
    latest_accepted_tc: undefined,
    original_country: null,
    name: null,
    email: null,
    is_email_verified: null,
  },
  notificationPreferences: undefined,
};
export const RESET_STATE = "RESET_STATE";

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    /* TODO: This action is not being used anywhere currently */
    setNotificationPreferences: (state, action) => {
      state.notificationPreferences = action.payload;
    },
    /* TODO: This action is not being used anywhere currently */
    updateVehicleDetails: (state, action: PayloadAction<VehicleQuestion[]>) => {
      if (state.profile) {
        const updatedQuestions = action.payload.map((question) => ({
          ...question,
          selected_option: question.selected_option
            ? { ...question.selected_option }
            : undefined,
        }));
        state.profile.distance_rule_screens = updatedQuestions;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return initialState;
    });
  },
});

const selectAuthState = (state: RootState) => state.auth;

export const selectIsAuthenticated = createSelector([selectAuthState], () => {
  const token = StorageManager.getTokenData();

  if (!token?.access_token || token.requiresPinChange) {
    return false;
  } else {
    return true;
  }

  // TODO: Fix problem with token expiration - we are not recieveing that on login.
  // Currently commented out to access the app.
  // const currentTime = Date.now() / 1000;

  // return token.access_token && token.expires && token.expires > currentTime;
});

export const { updateVehicleDetails, setNotificationPreferences } =
  authSlice.actions;
export default authSlice.reducer;
