import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useUserDetails } from "src/common/hooks/useUserDetails";

const USTaxGuard: React.FC = () => {
  const { taxRequirement, userDetailsLoading } = useUserDetails();
  const navigate = useNavigate();

  useEffect(() => {
    if (taxRequirement.workflow !== "integration" && userDetailsLoading) {
      navigate("/", { replace: true });
    }
  }, [taxRequirement.workflow, userDetailsLoading, navigate]);

  return <Outlet />;
};

export default USTaxGuard;
