import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaymentMethod } from "../../features/payment-methods/types/payment-method-types";

interface PaymentMethodFormState {
  formData: Record<string, Record<string, string>>;
  selectedPaymentMethod: PaymentMethod | null;
}

const initialState: PaymentMethodFormState = {
  formData: {},
  selectedPaymentMethod: null,
};

export const paymentMethodFormSlice = createSlice({
  name: "paymentMethodForm",
  initialState,
  reducers: {
    updateFormData: (
      state,
      action: PayloadAction<{
        formName: string;
        data: Record<string, string>;
      }>
    ) => {
      const { formName, data } = action.payload;
      state.formData = {
        ...state.formData,
        [formName]: {
          ...(state.formData[formName] || {}),
          ...data,
        },
      };
    },
    setSelectedPaymentMethod: (
      state,
      action: PayloadAction<PaymentMethod | null>
    ) => {
      state.selectedPaymentMethod = action.payload;
    },
    resetForm: (state) => {
      state.formData = {};
      state.selectedPaymentMethod = null;
    },
  },
});

export const { updateFormData, setSelectedPaymentMethod, resetForm } =
  paymentMethodFormSlice.actions;

export default paymentMethodFormSlice.reducer;
