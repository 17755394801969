import { ExpenseTypeJsonApiBlockWithId } from "../../api/model/resources-expense-types.yml";
import { formattedShortDate } from "src/types/common";
import { ExpenseIndexResponseResponse } from "src/api/model/resources-expenses.yml";
import { InfiniteData } from "@tanstack/react-query";

const getExpenseName = (
  expenseTypeId: string,
  data: InfiniteData<ExpenseIndexResponseResponse | undefined>
): string => {
  const expenseTypesData = data.pages[0]?.included;
  if (!expenseTypesData) return "";

  // @ts-expect-error - included type should be ExpenseIndexResponseResponseIncluded[]
  const expenseName: string | undefined = expenseTypesData.find(
    (item: ExpenseTypeJsonApiBlockWithId) => item.id === expenseTypeId
  )?.attributes?.name;
  return expenseName || "";
};

export const mapExpensesData = (
  data: InfiniteData<ExpenseIndexResponseResponse | undefined>
) => {
  const items = data.pages.flatMap(
    (page) =>
      page?.data?.map((item) => {
        const expenseTypeId =
          item.relationships["expenseItems.expenseType"]?.data?.[0]?.id;
        return {
          id: item.id!,
          createdAt: formattedShortDate(item.attributes.expenseDate!),
          type: getExpenseName(expenseTypeId, data) ?? "",
          amount: item.attributes.amount ?? "0.00",
          status: item.attributes.status ?? "pending",
          receipt_url: "https://example.com", // TODO: do we use this?
        };
      }) ?? []
  );

  return items;
};
