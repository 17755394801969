import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePageTitle } from "../common/TitleContext";
import { getSymbol } from "../common/helpers";
import { useErrorHandler } from "src/common/hooks";
import { useVisits } from "../common/hooks/useVisits";
import ReceiptImagePreview from "../components/ReceiptImagePreview";
import { clearReceiptClaimData } from "../redux/slices/claimsSlice";
import { showToast } from "../redux/slices/toastSlice";
import { RootState } from "../redux/store";
import { dateString } from "../types/common";
import { CategoryAmount, ReceiptClaimData } from "../types/paymentTypes";
import SummaryPageUI, {
  Action,
  DetailSection,
  VisitDetailSection,
} from "./SummaryPageUI";
import { VisitJsonApiBlockWithId } from "../api/model/resources-visits.yml";
import { useCreateNewExpense } from "../api/client/expenses/expenses";
import { useCreateNewReceipt } from "../api/client/receipts/receipts";
import { listAllParticipantVisits } from "../api/client/participant-visits/participant-visits";
import { useCreateNewExpenseItem } from "../api/client/expense-items/expense-items";
import { useUserDetails } from "src/common/hooks/useUserDetails";

const ReceiptSummaryPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleMutationError } = useErrorHandler();

  usePageTitle(t("claim_chooseClaimReceipt"));
  const [visit, setVisit] = useState<VisitJsonApiBlockWithId | null>(null);
  const [isLoadingParticipantVisit, setLoadingParticipantVisit] =
    useState(false);
  const [participantVisitId, setParticipantVisitId] = useState<
    string | undefined
  >();
  const { currencyCode, currencyId } = useUserDetails();

  const claim: ReceiptClaimData | null = useSelector(
    (state: RootState) => state.claims.receiptClaim
  );

  const paymentCurrencySymbol = getSymbol(currencyCode);

  const { visits, visitsLoading } = useVisits();

  useEffect(() => {
    if (claim?.visit_id) {
      const visit = visits.find((item) => {
        return item.id === claim?.visit_id;
      });
      if (visit) {
        setVisit(visit);
        setLoadingParticipantVisit(true);
        listAllParticipantVisits({
          "filter[visit]": visit.id,
        })
          .then((response) => {
            const id = response.data?.[0].id;
            setParticipantVisitId(id);
          })
          .finally(() => {
            setLoadingParticipantVisit(false);
          });
      }
    }
  }, [claim, visits.length]);

  const { mutate: createExpense, isPending: isAccepting } = useCreateNewExpense(
    {
      mutation: {
        onError: (error) => {
          handleMutationError(error);
        },
        onSuccess: async (data) => {
          if (!claim?.items || claim?.items?.length === 0) return;
          const expenseId = data.data?.id;

          /*
            This code is intentional due to a known data race issue in the backend
            that can result in an incorrect total amount when multiple
            POST requests are sent concurrently.
          */
          for (const item of claim.items) {
            await mutationExpenseItems.mutateAsync({
              data: {
                data: {
                  type: "expense-items",
                  attributes: {
                    amount: item.amount!.toFixed(2),
                  },
                  relationships: {
                    expense: {
                      data: {
                        type: "expenses",
                        id: expenseId,
                      },
                    },
                    expenseType: {
                      data: {
                        type: "expense-types",
                        id: item.category,
                      },
                    },
                  },
                },
              },
            });
          }

          upload({
            data: {
              data: {
                type: "receipts",
                attributes: {
                  file: claim.receiptData?.split(",")[1],
                },
                relationships: {
                  expense: {
                    data: {
                      type: "expenses",
                      id: expenseId,
                    },
                  },
                },
              },
            },
          });
        },
      },
    }
  );
  const { mutate: upload, isPending: isUploadingReceipt } = useCreateNewReceipt(
    {
      mutation: {
        onError: (error) => {
          handleMutationError(error);
        },
        onSuccess: () => {
          dispatch(showToast(t("claim_toastConfirmationMessage")));
          dispatch(clearReceiptClaimData());
          navigate("/payments");
        },
      },
    }
  );

  const mutationExpenseItems = useCreateNewExpenseItem({
    mutation: {
      onError: (error) => {
        handleMutationError(error);
      },
    },
  });

  const submitClaim = () => {
    if (!participantVisitId) return;

    createExpense({
      data: {
        data: {
          type: "expenses",
          attributes: {
            title: "Receipt Claim",
            expenseDate: claim!.visit_date!,
          },
          relationships: {
            participantVisit: {
              data: {
                type: "participant-visits",
                id: participantVisitId,
              },
            },
            currency: {
              data: {
                type: "currencies",
                id: currencyId.toString(),
              },
            },
          },
        },
      },
    });
  };

  const handleDeclinePayment = () => {
    dispatch(clearReceiptClaimData());
    navigate("/");
  };

  if (!claim) {
    return <CircularProgress />;
  }

  const actions: Action[] = [
    {
      label: t("cancel"),
      onClick: handleDeclinePayment,
      color: "secondary",
      variant: "outlined",
      loading: false,
    },
    {
      label: t("claim_SubmitAnExpense"),
      onClick: submitClaim,
      color: "primary",
      variant: "contained",
      loading:
        isAccepting || mutationExpenseItems.isPending || isUploadingReceipt,
    },
  ];
  const detailSections: DetailSection[] =
    claim.items?.map((item) => {
      return {
        title: item.value,
        content: `${getSymbol(currencyCode)}${item.amount?.toFixed(2)}`,
      };
    }) ?? [];

  function sumAmounts(visitCategories: CategoryAmount[]): number {
    return visitCategories.reduce((total, category) => {
      return total + (category.amount || 0);
    }, 0);
  }

  const total = sumAmounts(claim.items ?? []);

  const visitSection: VisitDetailSection = {
    title: t("claimDetail_relatedVisit"),
    content: visit?.attributes.name ?? t("loading_text"),
    date: visit ? dateString(visit) : t("loading_text"),
  };

  const totalSection: DetailSection = {
    title: t("receiptClaim_amount_totalTitle"),
    content: `${paymentCurrencySymbol}${total.toFixed(2)}`,
  };
  const isLoading = visitsLoading || isLoadingParticipantVisit;

  return (
    <SummaryPageUI
      title={t("claim_reviewYourClaim")}
      isLoading={isLoading}
      showChangePaymentMethodButton={true}
      subtitle={t("claim_reviewSubtitle")}
      detailSections={detailSections}
      visitSection={visitSection}
      actions={actions}
      totalSection={totalSection}
      topRightContent={<ReceiptImagePreview />}
    />
  );
};

export default ReceiptSummaryPage;
