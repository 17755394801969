import { t } from "i18next";
import { formatNumber, getSymbol } from "../common/helpers";
import ReceiptImagePreview from "../components/ReceiptImagePreview";
import { dateString } from "../types/common";
import { Claim, ClaimItem, StipendItem } from "../types/paymentTypes";
import {
  DirectionsCar as MileageIcon,
  Receipt as ReceiptIcon,
} from "@mui/icons-material";
import {
  Action,
  DetailSection,
  SummaryPageUIProps,
  VisitDetailSection,
} from "./SummaryPageUI";
import {
  VisitAttributes,
  VisitJsonApiBlockWithId,
} from "../api/model/resources-visits.yml";
import {
  ExpenseJsonApiBlockWithId,
  ExpenseReadResponseResponse,
} from "../api/model/resources-expenses.yml";
import { ExpenseTypeJsonApiBlockWithId } from "../api/model/resources-expense-types.yml";
import { ExpenseItemJsonApiBlockWithId } from "../api/model/resources-expense-items.yml";
import {
  CategoryType,
  handleCategoryNameToTranslationKey,
} from "src/common/categories";
import i18n from "i18next";
import {
  getTranslationByLanguageCode,
  standardizeTranslations,
} from "src/common/hooks/translations";

export default function getPaymentDetails(
  expense: ExpenseReadResponseResponse,
  expenseTypes: ExpenseTypeJsonApiBlockWithId[],
  onApprove: (claim: Claim) => void,
  onReject: (claim: Claim) => void,
  isApproving: boolean,
  isRejecting: boolean
) {
  const isParticipantViewingCarerClaim = false;
  const expenseType: string =
    expense?.included?.filter((item: any) => item.type === "expense-types")?.[0]
      ?.attributes.name ?? "";
  const visitAttributes: VisitAttributes = expense?.included?.filter(
    (item: any) => item.type === "visits"
  )?.[0]?.attributes;
  const receipt_url: string =
    expense?.included?.filter((item: any) => item.type === "receipts")?.[0]
      ?.attributes.uri ?? "";
  const expenseItems: ExpenseItemJsonApiBlockWithId[] =
    expense?.included?.filter((item: any) => item.type === "expense-items") ??
    [];
  const currencySymbol: string = expense?.included?.filter(
    (item: any) => item.type === "currencies"
  )?.[0].attributes.symbol;

  const translatedVisitData = {
    ...visitAttributes,
    ...getTranslationByLanguageCode(
      standardizeTranslations(visitAttributes.translations),
      i18n.language
    ),
  };

  switch (expenseType.toLowerCase()) {
    case "mileage":
      return getMileageDetails(
        expense.data!,
        currencySymbol,
        isParticipantViewingCarerClaim,
        translatedVisitData
      );
    // case "Stipend":
    //   return getStipendDetails(
    //     expense,
    //     visit,
    //     isParticipantViewingCarerClaim,
    //     isParticipantViewingCarerClaim,
    //     onApprove,
    //     onReject,
    //     isApproving,
    //     isRejecting
    //   );
    default:
      return getReceiptDetails(
        expense.data!,
        expenseTypes,
        receipt_url,
        currencySymbol,
        isParticipantViewingCarerClaim,
        expenseItems,
        translatedVisitData
      );
  }
}

function getMileageDetails(
  claim: ExpenseJsonApiBlockWithId,
  currencySymbol: string,
  hidePaymentMethod: boolean,
  visit?: VisitAttributes
): SummaryPageUIProps {
  const metadata = claim.attributes.metadata;
  // const paymentAccountCurrency = claim.payment_details.payment_currency;
  const travelRateUnitOfMeasurement = t(
    metadata?.travelRateUnitOfMeasurement ?? ""
  );

  const detailSections: DetailSection[] = metadata
    ? [
        {
          title: travelRateUnitOfMeasurement,
          content: `${metadata.distance} ${travelRateUnitOfMeasurement}`,
        },
        {
          title:
            metadata?.travelRateUnitOfMeasurement === "miles"
              ? t("mileageReview_RatePerMile")
              : t("mileageReview_RatePerKilometer"),
          content: `${currencySymbol}${formatNumber(
            metadata.travelRateValue ?? ""
          )}`,
        },
      ]
    : [];
  const total = claim.attributes.amount ?? "0";

  const visitSection: VisitDetailSection = {
    title: t("claimDetail_relatedVisit"),
    content: visit?.name ?? "empty",
    date: null,
  };

  const totalSection: DetailSection = {
    title: t("mileageReview_Total"),
    content: `${currencySymbol}${formatNumber(total)}`,
  };

  return {
    title: t("claimDetail_claimWithId", { "0": claim.id }),
    headerIcon: <MileageIcon />,
    isLoading: visit ? false : true,
    showChangePaymentMethodButton: false,
    subtitle: "",
    detailSections: detailSections,
    visitSection: visitSection,
    notes: claim.attributes.rejectionReason,
    actions: [],
    totalSection: totalSection,
  };
}

function getReceiptDetails(
  claim: ExpenseJsonApiBlockWithId,
  expenseTypes: ExpenseTypeJsonApiBlockWithId[],
  receiptURL: string,
  currencySymbol: string,
  hidePaymentMethod: boolean,
  expenseItems: ExpenseItemJsonApiBlockWithId[],
  visit?: VisitAttributes
): SummaryPageUIProps {
  const detailSections: DetailSection[] =
    expenseItems.map((item) => {
      const expenseName = expenseTypes.find(
        (type) => type.id == item.relationships.expenseType?.data?.id
      )?.attributes.name as CategoryType;

      return {
        title: expenseName
          ? t(handleCategoryNameToTranslationKey(expenseName))
          : "Item",
        content: `${currencySymbol}${formatNumber(
          item.attributes.amount as string
        )}`,
      };
    }) ?? [];

  const total = claim.attributes.amount ?? "0";

  const visitSection: VisitDetailSection = {
    title: t("claimDetail_relatedVisit"),
    content: visit?.name ?? "empty",
    date: null,
  };

  const totalSection: DetailSection = {
    title: t("mileageReview_Total"),
    content: `${currencySymbol}${formatNumber(total)}`,
  };

  return {
    title: t("claimDetail_claimWithId", { "0": claim.id }),
    headerIcon: <ReceiptIcon />,
    isLoading: visit ? false : true,
    showChangePaymentMethodButton: false,
    subtitle: "",
    detailSections: detailSections,
    visitSection: visitSection,
    notes: claim.attributes.rejectionReason,
    actions: [],
    totalSection: totalSection,
    topRightContent: <ReceiptImagePreview source={receiptURL} />,
  };
}

function sumAmountsToPay(items: ClaimItem[]): number {
  return items.reduce((total, item) => {
    return total + (item.amount_to_pay || 0);
  }, 0);
}

function sumAmounts(items: ClaimItem[]): number {
  return items.reduce((total, item) => {
    return total + (item.amount || 0);
  }, 0);
}

function getStipendDetails(
  claim: Claim,
  visit: VisitJsonApiBlockWithId | null,
  hidePaymentMethod: boolean,
  showApproveRejectActions: boolean,
  onApprove: (claim: Claim) => void,
  onReject: (claim: Claim) => void,
  isApproving: boolean,
  isRejecting: boolean
): SummaryPageUIProps {
  const stipendItem = claim.items[0] as StipendItem;
  const hasTax = stipendItem.withholding_tax_percentage > 0;
  const detailSections: DetailSection[] = [];
  const currency = claim.payment_details.payment_currency;
  const currencySymbol = getSymbol(currency);

  const visitSection: VisitDetailSection = {
    title: t("claimDetail_relatedVisit"),
    content: visit?.attributes.name ?? t("loading_text"),
    date: visit ? dateString(visit) : t("loading_text"),
  };

  if (hasTax) {
    detailSections.push(
      {
        title: t("claim_originalTotal"),
        content: `${currencySymbol}${stipendItem.total_amount.toFixed(2)}`,
      },
      {
        title: t("claim_irsWithholding", {
          "0": `${(stipendItem.withholding_tax_percentage * 100)?.toFixed(2)}%`,
        }),
        content: `${currencySymbol}${(
          stipendItem.total_amount - stipendItem.amount
        ).toFixed(2)}`,
      }
    );
  }
  const totalSection: DetailSection = {
    title: t("mileageReview_Total"),
    content: `${currencySymbol}${(hasTax
      ? stipendItem.amount
      : stipendItem.total_amount
    ).toFixed(2)}`,
  };

  const paymentDetails =
    claim.state === "Rejected" ? null : claim.payment_details;

  const actions: Action[] = showApproveRejectActions
    ? [
        {
          label: t("claimDetail_rejectClaim"),
          onClick: () => onReject(claim),
          color: "secondary",
          variant: "outlined",
          loading: isRejecting,
        },
        {
          label: t("claimDetail_approveClaim"),
          onClick: () => onApprove(claim),
          color: "primary",
          variant: "contained",
          loading: isApproving,
        },
      ]
    : [];

  return {
    title: t("claimDetail_claimWithId", { "0": claim.id }),
    isLoading: visit ? false : true,
    showChangePaymentMethodButton: false,
    subtitle: "",
    detailSections: detailSections,
    notes: claim.state === "Rejected" ? claim.notes : null,
    visitSection: visitSection,
    actions: actions,
    totalSection: totalSection,
  };
}
