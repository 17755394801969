import {
  type DependencyList,
  type EffectCallback,
  useEffect,
  useRef,
} from "react";

export function useEffectOnUpdate(
  effect: EffectCallback,
  deps?: DependencyList
) {
  const initialRender = useRef(true);
  // useLayoutEffect
  return useEffect(
    () => {
      if (initialRender.current) {
        initialRender.current = false;
        return;
      }
      effect();
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    deps
  );
}
