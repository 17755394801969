import {
  PaymentMethod,
  PaymentMethodType,
} from "../types/payment-method-types";

export const gbPaymentMethods: PaymentMethod[] = [
  {
    id: PaymentMethodType.BankAccount,
    title: "Bank Account",
    type: PaymentMethodType.BankAccount,
    country: "GB",
    form: {
      title: "Bank Account Information",
      name: "ach-out",
      type: "object",
      fields: [
        {
          type: "name",
          name: "fullName",
          label: "First and Last Name",
          pattern:
            "^[a-zA-Z0-9_'.-]{2,}\\s+[a-zA-Z0-9_'.-]{2,}(\\s+[a-zA-Z0-9_'.-]{2,})*$",
          required: true,
          minLength: 2,
          maxLength: 140,
          description:
            "Enter your first and last name as it appears on your bank account",
        },
        {
          type: "string",
          name: "sortCode",
          label: "Sort Code",
          required: true,
          minLength: 6,
          maxLength: 6,
        },
        {
          type: "string",
          name: "account",
          label: "Account Number",
          required: true,
          minLength: 8,
          maxLength: 8,
        },
      ],
    },
    endpoint: "/v1/payment-methods/bank-account",
  },
];
