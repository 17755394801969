import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { usePageTitle } from "../../common/TitleContext";
import TaxFormsList from "./TaxFormsList";
import TaxFormsSkeleton from "./TaxFormsSkeleton";
import TaxPreview, { DisplayMode } from "./TaxPreview";
import { useListAllTaxForms } from "../../api/client/tax-forms/tax-forms";
import { useTranslation } from "react-i18next";
import { ErrorCard } from "src/components/ErrorCard";
import { AppRoute } from "src/types/routes";
import { parseError } from "src/common/errorHandler";

const USTaxPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  usePageTitle(t("tax"));
  const {
    data,
    isPending: loading,
    isError,
    refetch,
    error,
  } = useListAllTaxForms();

  if (loading) {
    return <TaxFormsSkeleton />;
  }

  if (isError) {
    const { title, message } = parseError(error);
    return (
      <ErrorCard
        title={t(title)}
        message={t(message)}
        buttonLabel={t("retry")}
        onButtonClick={() => refetch()}
      />
    );
  }

  if (!data?.data || data?.data?.length === 0)
    return (
      <TaxPreview
        mode={DisplayMode.fullpage}
        onContinue={() => {
          navigate(AppRoute.TAX_ONBOARDING);
        }}
      />
    );
  return (
    <Box>
      <TaxFormsList data={data.data} />
    </Box>
  );
};

export default USTaxPage;
