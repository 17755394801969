import React, { createContext, useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Address } from "../../../components/AddressSearch";
import {
  removeSpacesAndHyphens,
  stripTextAfterHyphen,
} from "../../../common/helpers";
import { Form } from "../types/payment-method-types";

interface AddressFieldsContextType {
  isEnabled: boolean;
  enableFields: () => void;
  fillAddressFields: (address: Address) => void;
}

interface AddressFieldsProviderProps {
  children: React.ReactNode;
  form: Form;
}

const AddressFieldsContext = createContext<
  AddressFieldsContextType | undefined
>(undefined);

export const AddressFieldsProvider: React.FC<AddressFieldsProviderProps> = ({
  children,
  form,
}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const { setValue } = useFormContext();

  const enableFields = () => setIsEnabled(true);

  const fillAddressFields = (address: Address) => {
    setIsEnabled(true);

    // Get the state field from the form
    const stateField = form.fields.find(
      (field) => field.type === "address_state"
    );

    // Set address line 1
    if (address.Line1) {
      setValue("lineOne", address.Line1);
    }

    // Set city - direct text value
    if (address.City) {
      setValue("city", address.City);
    }

    // Set state - match by ProvinceCode to key
    if (address.ProvinceCode && stateField?.options) {
      const matchedState = stateField.options.find(
        (option) => option.key === address.ProvinceCode
      );
      if (matchedState) {
        setValue("state", matchedState.key);
      }
    }

    // Set postal code - with formatting
    if (address.PostalCode) {
      setValue(
        "zip",
        removeSpacesAndHyphens(stripTextAfterHyphen(address.PostalCode))
      );
    }
  };

  return (
    <AddressFieldsContext.Provider
      value={{ isEnabled, enableFields, fillAddressFields }}
    >
      {children}
    </AddressFieldsContext.Provider>
  );
};

export const useAddressFields = () => {
  const context = useContext(AddressFieldsContext);
  if (!context) {
    throw new Error(
      "useAddressFields must be used within AddressFieldsProvider"
    );
  }
  return context;
};
