import { usePaymentMethods as usePaymentMethodsApi } from "src/api/client/payment-methods/payment-methods";
import { isVirtualCard } from "src/features/payment-methods/types/payment-method-types";

export const usePaymentMethods = () => {
  const {
    data: paymentMethods,
    isLoading,
    isError,
    refetch,
    isFetching,
  } = usePaymentMethodsApi({
    query: {
      refetchOnWindowFocus: false,
      select: (data) => data.data,
    },
  });

  const defaultPaymentMethod = paymentMethods?.find(
    (method) => method.attributes.default
  );

  const virtualCard = paymentMethods?.find((method) =>
    isVirtualCard(method.attributes.accountType)
  );

  return {
    paymentMethodsLoading: isLoading,
    paymentMethodsError: isError,
    refetchPaymentMethods: refetch,
    paymentMethodsFetching: isFetching,
    defaultPaymentMethod,
    paymentMethods,
    defaultPaymentMethodId: defaultPaymentMethod?.id,
    virtualCard,
  };
};
