export type CategoryType =
  | "Accommodation"
  | "Food & Drink"
  | "Travel"
  | "Other"
  | "Prescription Charges";

export const handleCategoryNameToTranslationKey = (
  categoryName: CategoryType
) => {
  switch (categoryName) {
    case "Accommodation":
      return "ACCOMMODATION";
    case "Food & Drink":
      return "FOOD_DRINK";
    case "Travel":
      return "TRAVEL";
    case "Other":
      return "OTHER";
    case "Prescription Charges":
      return "PRESCRIPTION_CHARGES";
    default:
      return categoryName;
  }
};
