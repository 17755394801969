import type {
  ValidationErrorResponseResponse,
  ServerError as ApiServerError,
} from "src/api/model";
import ErrorBase from "./ErrorBase";

export class ServerError extends ErrorBase {
  constructor(serverData?: ApiServerError) {
    const { title, detail } = ServerError.parseError(serverData);

    super({
      name: "ServerError",
      title: title,
      message: detail,
      icon: "/images/server_warning.svg",
    });
    Object.setPrototypeOf(this, ServerError.prototype);
  }

  /**
   * Parse the server response to extract error details.
   */
  private static parseError(serverData?: ApiServerError): {
    title: string;
    detail: string;
  } {
    if (
      !serverData ||
      !serverData.errors ||
      !Array.isArray(serverData.errors) ||
      serverData.errors.length === 0
    ) {
      return { title: "error", detail: "error_somethingWentWrong" };
    }
    // Check if the error is a validation error and process accordingly
    if (ServerError.isValidationErrorResponse(serverData)) {
      const details = serverData.errors.map(
        (error) => error.detail || "error_somethingWentWrong"
      );
      return {
        title: "error_validation_title",
        detail: details.join("\n"), // Concatenate all error details into a single string
      };
    }

    // For other error types, return the first error's title and detail
    const errorItem = serverData.errors[0];
    return {
      title: errorItem.title,
      detail: errorItem.detail || "error_somethingWentWrong",
    };
  }

  /**
   * Check if the server response is a validation error response.
   */
  private static isValidationErrorResponse(
    serverData: ApiServerError
  ): serverData is ValidationErrorResponseResponse {
    return (
      (serverData as ValidationErrorResponseResponse).errors?.[0]?.source !==
      undefined
    );
  }
}
