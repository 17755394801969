import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";

import StorageManager from "../../services/storage";

import {
  useCountdown,
  useErrorHandler,
  useIdleTimeout,
} from "../../common/hooks";
import { reset } from "../../redux/store";
import LanguageButton from "./LanguageButton";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  clearMileageClaimData,
  clearReceiptClaimData,
} from "../../redux/slices/claimsSlice";
import { useConfig } from "src/config/useConfig";
import {
  selectAssets,
  selectInactivityTimeoutMinutes,
  selectTheme,
} from "src/config/selectors";

interface IAppHeaderProps {
  onSidebarCollapse: () => void;
}

const AppHeader: React.FC<IAppHeaderProps> = ({ onSidebarCollapse }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { getConfigValue } = useConfig();
  const clientTheme = getConfigValue(selectTheme);
  const clientAssets = getConfigValue(selectAssets);
  const inactivityTimeoutMinutes = getConfigValue(
    selectInactivityTimeoutMinutes
  );
  const countdown = useCountdown(59 * 1000);
  const { handleUnauthenticatedUser } = useErrorHandler();
  useEffect(() => {
    //TODO: build a better state management
    if (
      location.pathname.indexOf("/submit/") === -1 &&
      location.pathname.indexOf("/payment-methods/") === -1
    ) {
      clearClaimsData();
    }
  }, [location]);

  const [inactivityTimeoutDialog, setInactivityTimeoutDialog] = useState(false);

  const signout = () => {
    reset();
    StorageManager.clear();
    navigate("/login");
  };

  const clearClaimsData = () => {
    dispatch(clearMileageClaimData());
    dispatch(clearReceiptClaimData());
  };

  const onPrompt = () => {
    countdown.reset();
    setInactivityTimeoutDialog(true);
  };

  const onIdle = () => {
    setInactivityTimeoutDialog(false);
    handleUnauthenticatedUser({ state: { from: location, isInactive: true } });
  };

  const { idleTimer } = useIdleTimeout(
    onPrompt,
    onIdle,
    inactivityTimeoutMinutes
  );

  const cancelTimeout = () => {
    setInactivityTimeoutDialog(false);
    idleTimer.reset();
    countdown.reset();
  };

  const styles = {
    appBar: {
      bgcolor: clientTheme.appBarColor,
    },
    appLogo: {
      width: clientTheme.topBarLogoWidth,
      height: "54px",
      marginLeft: 2,
      cursor: "pointer",
    },
  };

  return (
    <>
      <AppBar position="sticky" sx={styles.appBar}>
        <Toolbar>
          <IconButton sx={{ color: "lightGray" }} onClick={onSidebarCollapse}>
            <MenuIcon />
          </IconButton>
          <Box
            component={"img"}
            sx={styles.appLogo}
            src={clientAssets.lightLogo}
            onClick={() => navigate("/")}
          />

          <Box sx={{ flexGrow: 1 }} />
          <LanguageButton />
        </Toolbar>
      </AppBar>

      <ConfirmationDialog
        label="timeout-dialog"
        maxWidth="sm"
        open={inactivityTimeoutDialog}
        title={t("session_to_expire_title")}
        message={
          <span
            dangerouslySetInnerHTML={{
              __html: t("session_to_expire_description_p1", {
                "0": countdown.timeLeftValues.seconds,
              }),
            }}
          />
        }
        confirmButtonText={t("settings_logout")}
        cancelButtonText={t("stay_signed_in")}
        onConfirm={signout}
        onDismiss={cancelTimeout}
      />
    </>
  );
};

export default AppHeader;
