import { Location as RouterLocation } from "react-router-dom";
import StorageManager from "src/services/storage";
import { AppRoute } from "src/types/routes";

interface NavigateOptions {
  state?: unknown;
  replace?: boolean;
}

const windowNavigate = (path: string, options: NavigateOptions = {}) => {
  const { state, replace } = options;

  if (replace) {
    window.history.replaceState(state, "", path);
  } else {
    window.history.pushState(state, "", path);
  }

  window.dispatchEvent(new PopStateEvent("popstate"));
};

export const navigateToLogin = (
  location: Pick<Location, "pathname" | "search" | "hash"> | RouterLocation,
  navigateOptions: NavigateOptions = {},
  navigateFn = windowNavigate
) => {
  StorageManager.removeFormData();
  StorageManager.removeToken();

  navigateFn(AppRoute.LOGIN, {
    state: { from: location },
    replace: true,
    ...navigateOptions,
  });
};
