import { Alert, Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import { usePageTitle } from "../common/TitleContext";
import ImageUploader from "../components/ImageUploader";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearReceiptClaimData,
  setReceiptClaimImageData,
} from "../redux/slices/claimsSlice";
import { useTranslation } from "react-i18next";
import CaptureImage from "../components/CaptureImage";
import CardContainer from "./CardContainer";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { isSafari } from "react-device-detect";
import { useCheckMobileScreen } from "src/common/hooks";
import { RootState } from "../redux/store";
import { convertDataUrlToBlob } from "../types/common";

const styles = {
  root: {
    flexGrow: 1,
  },
  previewImage: {
    maxWidth: "100%",
    maxHeight: 385,
    margin: 5,
  },
  containerBox: {
    border: "dashed",
    borderColor: "rgba(0, 0, 0, 0.12)",
    borderRadius: 12,
    height: 250,
    textAlign: "center" as const,
    cursor: "pointer",
    position: "relative" as const,
  },
  previewBox: {
    height: 400,
    textAlign: "center" as const,
    position: "relative" as const,
  },
  // Same as the dropzone style
  containerText: {
    margin: 0,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: "1.5rem",
    lineHeight: 1.334,
    letterSpacing: "0em",
    marginBottom: 24,
    marginTop: 24,
  },
  deleteIcon: {
    position: "absolute" as const,
    top: 5,
    right: 5,
    color: "red",
  },
};

const ReceiptImagePage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();

  usePageTitle(t("claim_chooseClaimReceipt"));

  // Get the image we already have in state so we can reuse it, instead of starting the process again.
  const receiptImageData = useSelector(
    (state: RootState) => state.claims.receiptClaim
  )?.receiptData;

  const [isLoading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [hasFileAvailable, setHasFileAvailable] = useState<boolean>(false);
  const [isWebCamOpen, setIsWebCamOpen] = useState<boolean>(false);
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState<null | HTMLElement>(
    null
  );

  const captureRef = useRef<HTMLInputElement>(null);
  const uploadRef = useRef<HTMLInputElement>(null);
  const captureIcon = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Don't delete the state if we have existing image
    if (!receiptImageData) {
      dispatch(clearReceiptClaimData());
    }
  }, []);

  useEffect(() => {
    if (receiptImageData || selectedFile) {
      setHasFileAvailable(true);
    } else {
      setHasFileAvailable(false);
    }
  }, [selectedFile, receiptImageData]);

  const handleImageUpload = async (file: File) => {
    convertImageFile(file, async (result, extension) => {
      if (result) {
        dispatch(setReceiptClaimImageData(result));
        navigate("/payment/submit/receipt/visit");
      }
    });
  };

  const handleContinueClick = (event: any) => {
    event.preventDefault();

    if (selectedFile) {
      handleImageUpload(selectedFile);
    } else if (receiptImageData) {
      navigate("/payment/submit/receipt/visit");
    }
  };

  const convertImageFile = (
    file: File,
    callback: (base64String: string | null, extension: string) => void
  ) => {
    const maxSizeInMB = 5;
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target!.result as string;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d")!;
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        let quality = 0.7;
        const dataUrl = canvas.toDataURL("image/jpeg", quality);
        let resizedDataUrl = dataUrl;

        while (dataUrl.length > maxSizeInMB * 1024 * 1024 && quality > 0.1) {
          quality -= 0.1;
          resizedDataUrl = canvas.toDataURL("image/jpeg", quality);
        }

        const base64String = resizedDataUrl;
        callback(base64String, "jpeg");
      };
    };

    reader.readAsDataURL(file);
  };

  const handleMobileMenuOpen = () => {
    if (isSafari) {
      uploadRef.current?.click();
    } else {
      if (!mobileMenuAnchor) {
        setMobileMenuAnchor(captureIcon.current);
      } else {
        handleMobileMenuClose();
      }
    }
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchor(null);
  };

  const handleImageDelete = () => {
    setSelectedFile(null);
    setHasFileAvailable(false);
    dispatch(clearReceiptClaimData());
  };

  const handleImageCapture = (e: any) => {
    const {
      target: { files },
    } = e;

    if (files?.length) {
      setSelectedFile(files[0]);
    }
  };

  return (
    <CardContainer>
      <CardContainer.Content>
        <Alert data-test-id="warning-alert" severity="warning" sx={{ mb: 2 }}>
          {t("receiptClaim_protectYourIdentity_message")}
        </Alert>
        <form>
          <Grid container spacing={2}>
            {isWebCamOpen && (
              <Grid item xl={12} style={{ width: "100%" }}>
                <CaptureImage
                  onCapture={(file) => {
                    setSelectedFile(file);
                    setIsWebCamOpen(false);
                  }}
                  onCancel={() => {
                    setIsWebCamOpen(false);
                  }}
                />
              </Grid>
            )}

            {!isWebCamOpen && (
              <>
                {!hasFileAvailable && (
                  <>
                    {!isMobile && (
                      <>
                        <Grid item xl={6} style={{ width: "100%" }}>
                          <ImageUploader
                            files={[]}
                            onFileChange={(files) => {
                              if (files.length > 0) {
                                setSelectedFile(files[0]);
                              }
                            }}
                          />
                        </Grid>

                        <Grid
                          item
                          xl={6}
                          onClick={() => {
                            setIsWebCamOpen(true);
                          }}
                          style={{ width: "100%" }}
                        >
                          <div style={styles.containerBox}>
                            <p style={styles.containerText}>
                              {t("take_photo")}
                            </p>
                            <CameraAltIcon sx={{ fontSize: 48 }} />
                          </div>
                        </Grid>
                      </>
                    )}

                    {isMobile && (
                      <Grid
                        item
                        xl={6}
                        onClick={handleMobileMenuOpen}
                        style={{ width: "100%" }}
                      >
                        <div style={styles.containerBox}>
                          <p style={styles.containerText}>
                            {t("pick_image_chooser_title")}
                          </p>
                          <span ref={captureIcon}>
                            <CameraAltIcon sx={{ fontSize: 48 }} />
                          </span>

                          <Menu
                            data-test-id="mobile-upload-menu"
                            anchorEl={mobileMenuAnchor}
                            open={Boolean(mobileMenuAnchor)}
                            onClose={handleMobileMenuClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                uploadRef.current?.click();
                              }}
                            >
                              {t("pick_image_gallery")}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                captureRef.current?.click();
                              }}
                            >
                              {t("pick_image_camera")}
                            </MenuItem>
                          </Menu>

                          <input
                            type="file"
                            accept="image/*"
                            capture="environment"
                            ref={captureRef}
                            style={{ display: "none" }}
                            onChange={handleImageCapture}
                          />

                          <input
                            type="file"
                            accept="image/*"
                            ref={uploadRef}
                            style={{ display: "none" }}
                            onChange={handleImageCapture}
                          />
                        </div>
                      </Grid>
                    )}
                  </>
                )}

                {hasFileAvailable && (
                  <Grid item xs={12} style={{ width: "100%" }}>
                    <div style={styles.previewBox}>
                      <img
                        src={
                          selectedFile
                            ? window.URL.createObjectURL(selectedFile)
                            : window.URL.createObjectURL(
                                convertDataUrlToBlob(receiptImageData!)
                              )
                        }
                        alt="img"
                        style={styles.previewImage}
                      />
                    </div>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    size="large"
                    sx={{ mt: 2 }}
                    loading={isLoading}
                    onClick={handleContinueClick}
                    disabled={!hasFileAvailable}
                  >
                    {t("continue_text")}
                  </LoadingButton>

                  {hasFileAvailable && (
                    <Typography align="center">
                      <Button
                        type="reset"
                        fullWidth
                        data-test-id="cancel-capture-button"
                        variant="text"
                        color="primary"
                        size="small"
                        sx={{ pl: 2, pr: 2, mt: 2 }}
                        onClick={handleImageDelete}
                      >
                        {t("cancel")}
                      </Button>
                    </Typography>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </CardContainer.Content>
    </CardContainer>
  );
};

export default ReceiptImagePage;
