import axios, { AxiosError } from "axios";
import { NotAuthenticatedError } from "./types/NotAuthenticatedError";
import { UnauthorizedError } from "./types/UnauthorizedError";
import { NoConnectionError } from "./types/NoConnectionError";
import { GeneralError } from "./types/GeneralError";
import { ServerError } from "./types/ServerError";
import { isApiServerError } from "src/common/errorHandler";

export const getErrorProps = (error: Error | AxiosError) => {
  const isAxiosError = axios.isAxiosError(error);
  const statusCode = isAxiosError ? error.response?.status : undefined;

  if (isAxiosError && !error.response) {
    return new NoConnectionError();
  }

  const errorByStatusCode = statusCode
    ? getErrorByStatusCode(statusCode)
    : null;

  if (errorByStatusCode) {
    return errorByStatusCode;
  }

  if (isAxiosError && isApiServerError(error.response?.data)) {
    return new ServerError(error.response?.data);
  }

  return new GeneralError(
    "message" in error ? error.message : undefined,
    "stack" in error ? error.stack : undefined
  );
};

const getErrorByStatusCode = (statusCode: number) => {
  switch (statusCode) {
    case 401:
      return new NotAuthenticatedError();
    case 403:
      return new UnauthorizedError();
    case 408:
    case 500:
    case 503:
    case 522:
    case 523:
    case 524:
    case 599:
      return new NoConnectionError();
    default:
      return null;
  }
};
