import PaymentIcon from "@mui/icons-material/Payment";
import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../../common/helpers";
import { ErrorCard } from "src/components/ErrorCard";
import { parseError } from "src/common/errorHandler";
import {
  selectClientId,
  selectDemoMode,
  selectTheme,
} from "src/config/selectors";
import { useConfig } from "src/config/useConfig";
import { usePaymentMethods } from "src/common/hooks/usePaymentMethods";
import { PaymentMethodAttributes } from "src/api/model/resources-payment-methods.yml";

interface ManageCardProps {
  virtualCard: PaymentMethodAttributes;
}

const ManageCard = (props: ManageCardProps) => {
  const { virtualCard } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getConfigValue } = useConfig();
  const clientTheme = getConfigValue(selectTheme);
  const clientId = getConfigValue(selectClientId);
  const { paymentMethodsLoading, paymentMethodsError, refetchPaymentMethods } =
    usePaymentMethods();

  if (paymentMethodsError) {
    const { title, message } = parseError(paymentMethodsError);
    return (
      <ErrorCard
        title={t(title)}
        message={t(message)}
        buttonLabel={t("retry")}
        onButtonClick={() => refetchPaymentMethods()}
      />
    );
  }

  if (paymentMethodsLoading)
    return (
      <Paper data-test-id="payments-skeleton" elevation={0}>
        <Skeleton variant="rounded" height={144.5} />
      </Paper>
    );

  const isNmible = clientId === "nmible";
  const isDemoMode = getConfigValue(selectDemoMode);
  const balance = isDemoMode ? 36.5 : virtualCard.balance ?? 0;
  return (
    <Card
      variant="outlined"
      sx={{
        border: "none",
        backgroundColor: clientTheme.virtualCardBackgroundColor,
      }}
    >
      <CardContent
        sx={{
          position: "relative",
          // TODO: Add this value in client config file. And add it conditionally.
          backgroundImage: `url(/card/${clientId}.svg)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: isNmible
            ? "right 5px top 0px"
            : "right 15px top 18px",
          backgroundSize: isNmible ? "auto 80%" : "auto 20%",
          color: "white",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">$ {formatNumber(balance)}</Typography>
        </Box>

        <Typography variant="body2">{t("vcc_availableBalance")}</Typography>

        <Button
          startIcon={<PaymentIcon />}
          variant="outlined"
          sx={{
            marginTop: 2,
            color: "white",
            borderColor: "white",
            ":hover": { borderColor: "white" },
          }}
          onClick={() => {
            navigate("/card/details");
          }}
        >
          {t("card_manage")}
        </Button>
      </CardContent>
    </Card>
  );
};

export default ManageCard;
