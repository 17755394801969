import React from "react";
import { MuiTelInput } from "mui-tel-input";
import { useFormContext, Controller } from "react-hook-form";
import { useTheme } from "@mui/material";
import { CountryCode, isValidNumberForRegion } from "libphonenumber-js";
import { FormControlProps, BaseFormControl } from "./BaseFormControl";
import { useTranslation } from "react-i18next";

export const PhoneFormControl: React.FC<FormControlProps> = ({
  field,
  name,
  countryCode,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext();

  const defaultValue =
    field.defaultValue !== undefined ? field.defaultValue : "";

  return (
    <BaseFormControl field={field} name={name}>
      <Controller
        name={name}
        defaultValue={defaultValue}
        rules={{
          validate: (value) =>
            isValidNumberForRegion(value, countryCode as CountryCode) ||
            t("error_invalidPhone"),
        }}
        render={({ field: { onChange, value, ref } }) => (
          <MuiTelInput
            value={value || ""}
            onChange={onChange}
            ref={ref}
            inputProps={
              theme.direction === "rtl"
                ? { style: { direction: "rtl", textAlign: "right" } }
                : {}
            }
            data-test-id="form-phone"
            fullWidth
            forceCallingCode
            disableDropdown
            defaultCountry={countryCode as CountryCode}
            onlyCountries={[countryCode as CountryCode]}
            label={field.label}
            error={!!errors[name]}
            disabled={field.disabled}
          />
        )}
      />
    </BaseFormControl>
  );
};
