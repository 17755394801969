import { ENV } from "./envVariables";
import { Client } from "./types";
import { createTheme } from "@mui/material/styles";

const baseTheme = createTheme(); // default MUI theme

export const createDefaultConfig = () =>
  ({
    clientName: "Nmible",
    clientId: Client.Nmible,
    demoMode: ENV.APP.DEMO_MODE,
    inactivityTimeoutMinutes: 120,
    pwaPromptThresholdDays: 2,
    enableNotification: false,
    theme: {
      muiTheme: baseTheme,
      topBarLogoWidth: 70,
      logoHeight: 30,
      appBarColor: "#ffffff",
      virtualCardBackgroundColor: "#1976d2",
    },
    assets: {
      lightLogo: "",
      loginLogo: "",
      favIcon: "",
    },
    features: {},
  } as const);

export const defaultConfig = Object.freeze(createDefaultConfig());
