import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import { Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useVisits } from "../common/hooks/useVisits";
import { dateString } from "../types/common";
import CardContainer from "./CardContainer";
import { ErrorCard } from "src/components/ErrorCard";
import { parseError } from "src/common/errorHandler";

export const VisitsPage = () => {
  const { t } = useTranslation();
  const { visits, visitsLoading, visitsError, refetchVisits } = useVisits();

  const renderPageContent = () => {
    if (visitsError) {
      const { message, title } = parseError(visitsError);
      return (
        <ErrorCard
          title={t(title)}
          message={t(message)}
          buttonLabel={t("retry")}
          onButtonClick={() => refetchVisits()}
        />
      );
    }

    if (visitsLoading) {
      return (
        <Timeline
          data-test-id="loading-timeline"
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {Array.from(new Array(3)).map((_, index) => (
            <TimelineItem key={`loading-${index}`}>
              <TimelineSeparator>
                <Skeleton variant="circular" width={24} height={24} />
              </TimelineSeparator>
              <TimelineContent>
                <Skeleton variant="text" width="50%" height={24} />
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      );
    }

    return (
      <Timeline
        data-test-id="visits-timeline"
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {visits.map((visit, index) => (
          <TimelineItem data-test-id="visit" key={visit.id}>
            <TimelineSeparator>
              <TimelineConnector
                sx={index === 0 ? { visibility: "hidden" } : null}
              />
              <TimelineDot>
                <CalendarTodayIcon sx={{ p: 0.5 }} />
              </TimelineDot>
              <TimelineConnector
                sx={
                  index === visits.length - 1 ? { visibility: "hidden" } : null
                }
              />
            </TimelineSeparator>

            <TimelineContent sx={{ py: "12px", px: 2, my: "auto" }}>
              <Typography
                data-test-id="visit-name"
                variant="h6"
                component="span"
              >
                {visit.attributes.name}
              </Typography>

              <Typography data-test-id="visit-date">
                {dateString(visit)}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    );
  };

  return (
    <CardContainer showBorder={true} isLoading={visitsLoading}>
      <CardContainer.Header>
        <CardContainer.HeaderContent>
          <CardContainer.HeaderTextItem>
            {t("visits_scheduleTitle")}
          </CardContainer.HeaderTextItem>
        </CardContainer.HeaderContent>
      </CardContainer.Header>
      <CardContainer.Content>{renderPageContent()}</CardContainer.Content>
    </CardContainer>
  );
};
