import { List } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ExpenseListItem from "./ExpenseListItem";
import { ExpenseRowData } from "../PaymentsPage.types";
import { useUserDetails } from "src/common/hooks/useUserDetails";
import { getSymbol } from "src/common/helpers";
interface ExpensesListProps {
  data: ExpenseRowData[];
}

const ExpensesList = ({ data }: ExpensesListProps) => {
  const navigate = useNavigate();
  const { currencyCode } = useUserDetails();
  const currencySymbol = getSymbol(currencyCode);

  const handleExpenseClick = (id: string) => {
    navigate(`/payment/${id}/details`);
  };

  return (
    <List>
      {data.map((expense) => {
        return (
          <ExpenseListItem
            expense={expense}
            currencySymbol={currencySymbol ?? ""}
            key={expense.id}
            onClick={() => handleExpenseClick(expense.id)}
          />
        );
      })}
    </List>
  );
};

export default ExpensesList;
