import { Alert, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePageTitle } from "../common/TitleContext";
import { getSymbol } from "../common/helpers";
import { useErrorHandler } from "src/common/hooks";
import { useVisits } from "../common/hooks/useVisits";
import VehicleDetailsPreview from "../components/VehicleDetailsPreview";
import { clearMileageClaimData } from "../redux/slices/claimsSlice";
import { showToast } from "../redux/slices/toastSlice";
import { RootState } from "../redux/store";
import { dateString, isElectricVehicle } from "../types/common";
import { MileageClaimData, VehicleQuestion } from "../types/paymentTypes";
import SummaryPageUI, {
  Action,
  DetailSection,
  VisitDetailSection,
} from "./SummaryPageUI";
import { VisitJsonApiBlockWithId } from "../api/model/resources-visits.yml";
import { useCreateNewExpense } from "../api/client/expenses/expenses";
import { listAllParticipantVisits } from "../api/client/participant-visits/participant-visits";
import { useCreateNewExpenseItem } from "../api/client/expense-items/expense-items";
import { useUserDetails } from "src/common/hooks/useUserDetails";
import { useExpenseTypes } from "src/common/hooks/useExpenseTypes";
import { getTranslationByLanguageCode } from "src/common/hooks/translations";
import { standardizeTranslations } from "src/common/hooks/translations";
import i18n from "i18next";

const MileageSummaryPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleMutationError } = useErrorHandler();
  const { currencyId, currencyCode, distanceUnit } = useUserDetails();
  const { visits, visitsLoading } = useVisits();
  const [visit, setVisit] = useState<VisitJsonApiBlockWithId | null>(null);
  const [isLoadingParticipantVisit, setLoadingParticipantVisit] =
    useState(false);
  const [participantVisitId, setParticipantVisitId] = useState<
    string | undefined
  >();

  usePageTitle(t("claim_chooseClaimMileage"));

  const claim: MileageClaimData | null = useSelector(
    (state: RootState) => state.claims.mileageClaim
  );
  const questions = useSelector(
    (state: RootState) => state.auth.profile?.distance_rule_screens ?? []
  );

  const paymentCurrencySymbol = getSymbol(currencyCode);

  useEffect(() => {
    if (claim?.visit_id) {
      const visit = visits.find((item) => {
        return item.id === claim?.visit_id;
      });
      if (visit) {
        const visitTranslations = standardizeTranslations(
          visit.attributes.translations
        );
        const translatedVisit = {
          ...visit,
          attributes: {
            ...visit.attributes,
            ...getTranslationByLanguageCode(visitTranslations, i18n.language),
          },
        };
        setVisit(translatedVisit);
        setLoadingParticipantVisit(true);
        listAllParticipantVisits({
          "filter[visit]": visit.id,
        })
          .then((response) => {
            const id = response.data?.[0].id;
            setParticipantVisitId(id);
          })
          .finally(() => {
            setLoadingParticipantVisit(false);
          });
      }
    }
  }, [claim, visits.length]);

  const [mileageType, setMileageType] = useState("");
  const { expenseTypesLoading, expenseTypes } = useExpenseTypes();

  useEffect(() => {
    const mileageId = expenseTypes.filter(
      (item) => item.attributes.name?.toLowerCase() === "mileage"
    )?.[0]?.id;
    if (mileageId) setMileageType(mileageId);
  }, [expenseTypes]);

  const { mutate: createExpense, isPending: isAccepting } = useCreateNewExpense(
    {
      mutation: {
        onError: (error) => {
          handleMutationError(error);
        },
        onSuccess: (data) => {
          createItems({
            data: {
              data: {
                type: "expense-items",
                attributes: {
                  amount: claim!.total_amount!.toFixed(2),
                },
                relationships: {
                  expense: {
                    data: {
                      type: "expenses",
                      id: data.data?.id,
                    },
                  },
                  expenseType: {
                    data: {
                      type: "expense-types",
                      id: mileageType,
                    },
                  },
                },
              },
            },
          });
        },
      },
    }
  );

  const { mutate: createItems, isPending: isCreatingItems } =
    useCreateNewExpenseItem({
      mutation: {
        onError: (error) => {
          handleMutationError(error);
        },
        onSuccess: () => {
          dispatch(showToast(t("claim_toastConfirmationMessage")));
          dispatch(clearMileageClaimData());
          navigate("/payments", { replace: true });
        },
      },
    });

  const submitClaim = () => {
    if (!participantVisitId) return;
    createExpense({
      data: {
        data: {
          type: "expenses",
          attributes: {
            title: "Mileage Claim",
            expenseDate: claim?.visit_date ?? "",
          },
          relationships: {
            participantVisit: {
              data: {
                type: "participant-visits",
                id: participantVisitId,
              },
            },
            currency: {
              data: {
                type: "currencies",
                id: currencyId.toString(),
              },
            },
          },
        },
      },
    });
  };

  const handleDeclinePayment = () => {
    dispatch(clearMileageClaimData());
    navigate("/");
  };

  if (!claim) {
    return <CircularProgress />;
  }

  const actions: Action[] = [
    {
      label: t("cancel"),
      onClick: handleDeclinePayment,
      color: "secondary",
      variant: "outlined",
      loading: false,
    },
    {
      label: t("reviewClaimDetail_submitMyClaim"),
      onClick: submitClaim,
      color: "primary",
      variant: "contained",
      loading: isAccepting || isCreatingItems,
    },
  ];

  const isElectric = isElectricVehicle();

  const detailSections: DetailSection[] =
    claim.rateCalculationResults?.rates.flatMap((item) => {
      return [
        {
          title: t(distanceUnit!),
          content: `${item.distance} ${t(distanceUnit!)}`,
        },
        {
          title:
            distanceUnit === "miles"
              ? t("mileageReview_RatePerMile")
              : t("mileageReview_RatePerKilometer"),
          subtitle: isElectric
            ? t("vehicleSummary_IncludesElectricVehicle")
            : undefined,
          content: `${getSymbol(currencyCode)}${item.rate?.toFixed(2)}`,
        },
      ];
    }) ?? [];

  const visitSection: VisitDetailSection = {
    title: t("claimDetail_relatedVisit"),
    content: visit?.attributes.name ?? t("loading_text"),
    date: visit ? dateString(visit) : t("loading_text"),
  };

  const totalSection: DetailSection = {
    title: t("mileageReview_Total"),
    content: `${paymentCurrencySymbol}${claim.total_amount?.toFixed(2)}`,
  };

  function vehicleDetails(
    questions: VehicleQuestion[]
  ): React.ReactNode | undefined {
    if (questions.length === 0) return undefined;
    return VehicleDetailsPreview(questions);
  }
  const isLoading =
    isLoadingParticipantVisit || visitsLoading || expenseTypesLoading;

  return (
    <>
      {(!mileageType || expenseTypesLoading) && (
        <Alert severity="error">
          You can’t submit a fuel allowance expense because this option isn’t
          enabled for your study. If you believe this is an error, please
          contact the admin for assistance.
        </Alert>
      )}
      <SummaryPageUI
        title={t("claim_reviewYourClaim")}
        isLoading={isLoading}
        showChangePaymentMethodButton={true}
        subtitle={t("claim_reviewSubtitle")}
        detailSections={detailSections}
        visitSection={visitSection}
        actions={actions}
        totalSection={totalSection}
        bottomContent={vehicleDetails(questions)}
      />
    </>
  );
};

export default MileageSummaryPage;
