import React from "react";
import { FormField } from "../../types/payment-method-types";
import { FormHelperText, FormControl } from "@mui/material";
import { useFormContext } from "react-hook-form";

export interface FormControlProps {
  field: FormField;
  name: string;
  countryCode?: string;
  children?: React.ReactNode;
}

export const BaseFormControl: React.FC<FormControlProps> = ({
  name,
  children,
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const error = errors[name]?.message as string;

  return (
    <FormControl fullWidth error={!!error}>
      {children}
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
