// Field Types
export type FieldType =
  | "string"
  | "number"
  | "email"
  | "tel"
  | "search"
  | "select"
  | "radio"
  | "object"
  | "address"
  | "address_lineOne"
  | "address_city"
  | "address_state"
  | "address_zip"
  | "iban"
  | "name";

export interface SelectOption {
  key: string;
  value: string;
}

export interface FieldValidation {
  required: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
}

export interface FormField extends FieldValidation {
  type: FieldType;
  name: string;
  label: string;
  description?: string;
  placeholder?: string;
  options?: SelectOption[];
  defaultValue?: string | number;
  disabled?: boolean;
  fields?: FormField[];
}

// Form Types
export interface Form {
  title: string;
  fields: FormField[];
  then?: Form; // Next form in the sequence
  name: string;
  type: FieldType;
}

// Payment Method Configuration Types
export interface PaymentMethod {
  id: PaymentMethodType;
  title: string;
  type: PaymentMethodType;
  form: Form;
  endpoint: string;
  country: string;
}

export enum PaymentMethodType {
  DebitCard = "DEBIT_CARD",

  BankAccount = "ach-out", // SaaS backend return this
  BankAccountVariation1 = "Bank account", // PPS returns this when getting payment account
  BankAccountVariation2 = "BANK_ACCOUNT", // PPS returns this in GET available payment methods

  VirtualCard = "virtual-card", // SaaS backend return this
  VirtualCardVariation1 = "Virtual card", // PPS returns this when getting payment account
  VirtualCardVariation2 = "VIRTUAL_CARD", // PPS returns this in GET available payment methods
  VirtualCardVariation3 = "Virtual credit card", // PPS returns this too

  Check = "CHECK",
  CheckVariation1 = "Email", // PPS returns this too

  Cash = "Cash pick up",
}

// REMOVE when PPS and SaaS backend are updated
export function isVirtualCard(type?: string) {
  return (
    type === PaymentMethodType.VirtualCard ||
    type === PaymentMethodType.VirtualCardVariation1 ||
    type === PaymentMethodType.VirtualCardVariation2
  );
}

// REMOVE when PPS and SaaS backend are updated
export function isBankAccount(type?: string) {
  return (
    type === PaymentMethodType.BankAccount ||
    type === PaymentMethodType.BankAccountVariation1 ||
    type === PaymentMethodType.BankAccountVariation2
  );
}

// REMOVE when PPS and SaaS backend are updated
export function isCheck(type?: string) {
  return (
    type === PaymentMethodType.Check ||
    type === PaymentMethodType.CheckVariation1
  );
}
