import { ClientConfig, ConfigSelector, NonNullableConfig } from "./types";
import { defaultConfig } from "./default";
import { ENV } from "./envVariables";
export class ConfigService {
  private static instance: ConfigService;
  private config: ClientConfig;

  private constructor() {
    this.config = defaultConfig;
    this.loadConfig();
  }

  public static getInstance(): ConfigService {
    if (!ConfigService.instance) {
      ConfigService.instance = new ConfigService();
    }
    return ConfigService.instance;
  }

  private async loadConfig() {
    const clientKey = ENV.APP.CLIENT;

    try {
      const { [clientKey + "Config"]: clientConfig } = await import(
        `./clients/${clientKey}.ts`
      );
      this.config = clientConfig;
    } catch (error) {
      console.warn(
        `Config not found for client ${clientKey}, using default config`
      );
    }
  }

  public getValue<T>(selector: ConfigSelector<T>): NonNullableConfig<T> {
    const value = selector(this.config);

    if (value !== undefined) {
      return value as NonNullableConfig<T>;
    }
    return selector(defaultConfig) as NonNullableConfig<T>;
  }

  public createClientConfig<T extends Partial<ClientConfig>>(
    clientConfig: T
  ): ClientConfig {
    return {
      ...defaultConfig,
      ...clientConfig,
      theme: {
        ...defaultConfig.theme,
        ...clientConfig.theme,
      },
    };
  }
}

export const configService = ConfigService.getInstance();
