import { ClientConfig, SupportMethod } from "./types";

export const selectMaterialTheme = (config: ClientConfig) =>
  config.theme.muiTheme;
export const selectTheme = (config: ClientConfig) => config.theme;
export const selectAssets = (config: ClientConfig) => config.assets;
export const selectLoginLogo = (config: ClientConfig) =>
  config.assets.loginLogo;
export const selectIsFeatureEnabled =
  (featureKey: keyof ClientConfig["features"]) => (config: ClientConfig) =>
    config.features[featureKey] ?? false;

export const selectSupportMethod = (config: ClientConfig) =>
  config.supportEmail
    ? SupportMethod.email
    : config.supportTelephone
    ? SupportMethod.telephone
    : undefined;

export const selectSupportContact = (config: ClientConfig) =>
  config.supportEmail || config.supportTelephone || "";

/** Returns the unique identifier for the client */
export const selectClientId = (config: ClientConfig) => config.clientId;

/** Returns the display name of the client */
export const selectClientName = (config: ClientConfig) => config.clientName;

export const selectDemoMode = (config: ClientConfig) => config.demoMode;

export const selectPwaPromptThresholdDays = (config: ClientConfig) =>
  config.pwaPromptThresholdDays;

export const selectInactivityTimeoutMinutes = (config: ClientConfig) =>
  config.inactivityTimeoutMinutes;

export const selectEnableNotification = (config: ClientConfig) =>
  config.enableNotification;
