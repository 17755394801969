import { useTranslation } from "react-i18next";
import { getLegalDocumentLinks } from "./utils";
import { useConfig } from "src/config/useConfig";
import { selectClientName } from "src/config/selectors";

interface PrivacyPolicyProps {
  clientId?: string;
}

export const PrivacyPolicy = (props: PrivacyPolicyProps) => {
  const { clientId } = props;
  const { t } = useTranslation();
  const { getConfigValue } = useConfig();
  const clientName = getConfigValue(selectClientName);
  const { content } = getLegalDocumentLinks("privacy", { clientId });

  return (
    <iframe
      data-test-id="privacy-policy-iframe"
      src={content}
      title={t("client_privacy", { client: clientName })}
      width="100%"
      height="100%"
      style={{
        border: "none",
        overflow: "hidden",
      }}
    />
  );
};
