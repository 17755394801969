import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { usePageTitle } from "../common/TitleContext";
import { ConfirmationDialog } from "../components/ConfirmationDialog";
import { Claim } from "../types/paymentTypes";
import getPaymentDetails from "./PaymentDetailsProvider";
import SummaryPageUI from "./SummaryPageUI";
import { useReadExpenseById } from "../api/client/expenses/expenses";
import SummaryPageSkeleton from "../components/SummaryPageSkeleton";
import { AppRoute } from "src/types/routes";
import { useExpenseTypes } from "src/common/hooks/useExpenseTypes";

function PaymentDetailsPage() {
  const { t } = useTranslation();
  const { expenseTypes } = useExpenseTypes();
  const { id } = useParams<{ id: string }>();
  usePageTitle(t("payment_title"));
  const { data, isLoading } = useReadExpenseById(id!, {
    include:
      "expenseItems,currency,receipt,participantVisit.visit,expenseItems.expenseType",
  });
  const navigate = useNavigate();

  const [isApproving, setApproving] = useState(false);
  const [isRejecting, setRejecting] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({
    title: "",
    message: "",
    confirmButton: "",
    onConfirm: () => {},
  });

  const handleApprove = (claim: Claim) => {
    setDialogConfig({
      title: t("claimDetail_approveClaim"),
      message: t("claimDetail_approveClaimConfirmationMessage"),
      confirmButton: t("claimDetail_approve"),
      onConfirm: () => handleConfirmApprove(claim.id),
    });

    setDialogOpen(true);
  };

  const handleReject = (claim: Claim) => {
    setDialogConfig({
      title: t("claimDetail_rejectClaim"),
      message: t("claimDetail_rejectClaimConfirmationMessage"),
      confirmButton: t("claimDetail_reject"),
      onConfirm: () => handleConfirmReject(claim.id),
    });

    setDialogOpen(true);
  };

  const handleConfirmApprove = async (claimId: number) => {
    // setDialogOpen(false);
    // setApproving(true);
    // try {
    //   await api(paymentService.approveClaim(claimId));
    //   dispatch(showToast(t("claim_toastConfirmationMessage")));
    //   dispatch(resetCarerClaims());
    // } catch (error) {
    //   handleServerError(error as AxiosError);
    // } finally {
    //   navigate("/payments");
    //   setApproving(false);
    // }
  };

  const handleConfirmReject = async (claimId: number) => {
    // setDialogOpen(false);
    // setRejecting(true);
    // try {
    //   await api(paymentService.rejectClaim(claimId));
    //   dispatch(showToast(t("claimDetail_claimRejected")));
    //   dispatch(resetCarerClaims());
    // } catch (error) {
    //   handleServerError(error as AxiosError);
    // } finally {
    //   navigate("/payments");
    //   setRejecting(false);
    // }
  };

  useEffect(() => {
    if (!data?.data && !isLoading) {
      navigate(AppRoute.PAYMENTS);
    }
  }, [data, isLoading, navigate]);

  if (isLoading || !data) {
    return (
      <SummaryPageSkeleton
        showChangePaymentMethodButton={false}
        detailSectionsCount={1}
        actionsCount={0}
      />
    );
  }

  return (
    <>
      <SummaryPageUI
        hidePaymentMethodSection={true}
        {...getPaymentDetails(
          data,
          expenseTypes,
          handleApprove,
          handleReject,
          isApproving,
          isRejecting
        )}
      />

      <ConfirmationDialog
        label="payment-details-dialog"
        open={isDialogOpen}
        title={dialogConfig.title}
        message={dialogConfig.message}
        confirmButtonText={dialogConfig.confirmButton}
        cancelButtonText={t("cancel")}
        onConfirm={dialogConfig.onConfirm}
        onDismiss={() => setDialogOpen(false)}
      />
    </>
  );
}

export default PaymentDetailsPage;
