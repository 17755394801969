import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { PaymentMethod } from "src/features/payment-methods/types/payment-method-types";

export const RESET_STATE = "RESET_STATE";

// TODO: Remove this slice after we get availablePaymentMethods from the API
interface PaymentMethodsState {
  availablePaymentMethods: PaymentMethod[];
}

const initialState: PaymentMethodsState = {
  availablePaymentMethods: [],
};

export const paymentMethodsSlice = createSlice({
  name: "paymentMethods",
  initialState,
  reducers: {
    setAvailablePaymentMethods: (
      state,
      action: PayloadAction<PaymentMethod[]>
    ) => {
      state.availablePaymentMethods = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return initialState;
    });
  },
});

export const paymentMethods = (state: RootState) =>
  state.paymentMethods.availablePaymentMethods;

export const { setAvailablePaymentMethods } = paymentMethodsSlice.actions;
export default paymentMethodsSlice.reducer;
