import { getLegalDocumentLinks } from "./utils";
import { useTranslation } from "react-i18next";

interface TermsAndConditionsProps {
  clientId?: string;
}

export const TermsAndConditions = (props: TermsAndConditionsProps) => {
  const { clientId } = props;
  const { t } = useTranslation();
  const { content } = getLegalDocumentLinks("terms", { clientId });
  return (
    <iframe
      data-test-id="tc-iframe"
      src={content}
      title={t("terms_termsAndConditions")}
      width="100%"
      height="100%"
      style={{
        border: "none",
        overflow: "hidden",
      }}
    />
  );
};
