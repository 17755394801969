import { Navigate, Route, Routes } from "react-router-dom";

// import PaymentInputForm from "../containers/PaymentInputForm";

import ChangePINPage from "../pages/ChangePinPage";
import ClaimVisitsPage from "../pages/ClaimVisitsPage";
import LoginPage from "../pages/LoginPage";
import MileageDistancePage from "../pages/MileageDistancePage";
import MileageSummaryPage from "../pages/MileageSummaryPage";
import MileageThresholdPage from "../pages/MileageThresholdPage";
import NotFound from "../pages/NotFound";
import StipendSummaryPage from "../pages/PaymentSummaryPage";
import PrivateRoutes from "../pages/PrivateRoutes";
import ReceiptAmountPage from "../pages/ReceiptAmountPage";
import ReceiptCategoriesPage from "../pages/ReceiptCategoriesPage";
import ReceiptImagePage from "../pages/ReceiptImagePage";
import ReceiptSummaryPage from "../pages/ReceiptSummaryPage";
import SettingsPage from "../pages/SettingsPage";
import Support from "../pages/SupportPage";
import PaymentDetailsPage from "../pages/PaymentDetailsPage";
// import VccCardCreated from "../pages/VccCardCreatedPage";
// import VccCardPreview from "../pages/VccCardPreview";
// import VccCardSetup from "../pages/VccCardSetup";
import VccVisualise from "../pages/VccVisualisePage";
// import VehicleQuestionsPage from "../pages/VehicleQuestionsPage";
import PublicRoutes from "../pages/PublicRoutes";
import CardOnboardingPage from "../pages/card/CardOnboardingPage";
import NewCardSuccessPage from "../pages/card/NewCardSuccessPage";
import { PaymentsPage } from "../pages/PaymentsPage";
import TrialDetailsPage from "../pages/TrialDetailsPage";
import CardDetailsPage from "../pages/card/CardDetailsPage";
import CardFAQDetailsPage from "../pages/card/CardFAQDetailsPage";
import CardFAQsPage from "../pages/card/CardFAQsPage";
import { VisitsPage } from "../pages/VisitsPage";
import ExpenseDetails from "../pages/ExpenseDetails";
import TaxOnboardingPage from "../pages/Tax/TaxOnboardingPage";
import CreateTaxFormPage from "../pages/Tax/CreateTaxFormPage";
import TaxFormSubmissionPage from "../pages/Tax/TaxFormSubmissionPage";
import StipendDetails from "../pages/StipendDetails";
import MicropaymentDetails from "../pages/MircropaymentDetails";
import USTaxGuard from "../pages/Tax/USTaxGuard";
import USTaxPage from "../pages/Tax/USTaxPage";
import PaymentMethodPage from "../features/payment-methods/pages/payment-method-page";
import PaymentMethodLayout from "../features/payment-methods/pages/payment-method-layout";
import PaymentMethodPreview from "../features/payment-methods/pages/payment-method-preview";
import { useMemo } from "react";
import PaymentMethodsPage from "src/features/payment-methods/pages/select-payment-method-page";
import {
  selectSupportMethod,
  selectClientId,
  selectIsFeatureEnabled,
} from "src/config/selectors";
import { useConfig } from "src/config/useConfig";
import { TermsAndConditions } from "src/components/LegalDocuments/TermsAndConditions";
import { PrivacyPolicy } from "src/components/LegalDocuments/PrivacyPolicy";
import { CardAgreement } from "src/components/LegalDocuments/CardAgreement";

const AppRoutes = () => {
  const { getConfigValue } = useConfig();
  const supportMethod = getConfigValue(selectSupportMethod);
  const showSupportRoute = Boolean(supportMethod);
  const clientId = getConfigValue(selectClientId);
  const loginPagePrivacyPolicyEnabled = getConfigValue(
    selectIsFeatureEnabled("loginPagePrivacyPolicy")
  );

  if (window.top && window.self !== window.top) {
    // The page is inside an iframe, break out
    window.top.location.href = window.self.location.href;
    return;
  }

  const paymentMethodLayout = useMemo(() => <PaymentMethodLayout />, []);

  return (
    <Routes>
      <Route element={<PublicRoutes />}>
        <Route path="/login" element={<LoginPage />} />
      </Route>

      <Route element={<PrivateRoutes />}>
        <Route path="/payment-methods" element={paymentMethodLayout}>
          <Route path="list" element={<PaymentMethodsPage />} />
          <Route
            path=":paymentMethodName/create/:formName?"
            element={<PaymentMethodPage />}
          />
          <Route
            path=":paymentMethodName/preview"
            element={<PaymentMethodPreview />}
          />
          <Route path="new-card-onboarding" element={<CardOnboardingPage />} />
          <Route path="new-card-success" element={<NewCardSuccessPage />} />
        </Route>

        <Route path="/" element={<Navigate replace to="/payments" />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/visits" element={<VisitsPage />} />
        {/* <Route path="/visits" element={<ComingSoonPage />} /> */}
        <Route path="/trial" element={<TrialDetailsPage />} />
        {/* <Route path="/trial" element={<ComingSoonPage />} /> */}
        <Route path="/settings/change-pin" element={<ChangePINPage />} />

        <Route path="/expense/:id" element={<ExpenseDetails />} />
        <Route path="/stipend/:id" element={<StipendDetails />} />
        <Route path="/micropayment/:id" element={<MicropaymentDetails />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        {loginPagePrivacyPolicyEnabled && (
          <Route
            path="/privacy-policy"
            element={<PrivacyPolicy clientId={clientId} />}
          />
        )}
        <Route path="/vcc-card" element={<VccVisualise />} />
        <Route path="/card/details" element={<CardDetailsPage />} />
        <Route path="/card/faq" element={<CardFAQsPage />} />
        <Route path="/card/faq/:id" element={<CardFAQDetailsPage />} />
        <Route path="/card/agreement" element={<CardAgreement />} />
        {/* <Route
          path="/enable-notifications"
          element={<EnableNotificationsPage />}
        /> */}
        <Route path="/payments" element={<PaymentsPage />} />

        {/* SUBMIT PAGES START*/}
        <Route path="/receipt/submit/upload" element={<ReceiptImagePage />} />

        {/* <Route
          path="/payment/submit/:id/payment-methods/:paymentMethodIndex"
          element={<PaymentInputForm />}
        /> */}

        {/* <Route
          path="/payment/submit/:id/payment-methods/:paymentMethodIndex/preview"
          element={<VccCardPreview />}
        /> */}
        {/* <Route
          path="/payment/submit/:id/payment-methods/:paymentMethodIndex/created"
          element={<VccCardCreated />}
        /> */}

        {/* <Route path="/payment-methods/VIRTUAL_CARD" element={<CardForm />} /> */}

        <Route path="/payment/submit/:id/visit" element={<ClaimVisitsPage />} />
        <Route
          path="/payment/submit/:id/summary"
          element={<StipendSummaryPage />}
        />
        <Route path="/payment/:id/details" element={<PaymentDetailsPage />} />
        <Route
          path="/payment/submit/receipt/categories"
          element={<ReceiptCategoriesPage />}
        />
        <Route
          path="/payment/submit/receipt/summary"
          element={<ReceiptSummaryPage />}
        />
        <Route
          path="/payment/submit/receipt/amount/:category"
          element={<ReceiptAmountPage />}
        />

        <Route
          path="/payment/submit/mileage/distance"
          element={<MileageDistancePage />}
        />
        {/* <Route
          path="/payment/submit/mileage/vehicle"
          element={<VehicleQuestionsPage />}
        /> */}
        <Route
          path="/payment/submit/mileage/threshold"
          element={<MileageThresholdPage />}
        />
        <Route
          path="/payment/submit/mileage/summary"
          element={<MileageSummaryPage />}
        />

        {/* TAX PAGES */}
        <Route path="/tax" element={<USTaxGuard />}>
          <Route index element={<USTaxPage />} />
          <Route path="success" element={<Navigate replace to="/tax" />} />
          <Route path="cancelled" element={<Navigate replace to="/tax" />} />
          <Route path="onboarding" element={<TaxOnboardingPage />} />
          <Route path="submit" element={<CreateTaxFormPage />} />
          <Route path="form" element={<TaxFormSubmissionPage />} />
        </Route>

        <Route path="/not-found" element={<NotFound />} />
        {showSupportRoute && <Route path="/support" element={<Support />} />}
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
