import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import type { ErrorCardProps } from "./ErrorCard.types";

export const ErrorCard = (props: ErrorCardProps) => {
  const { title, message, buttonLabel, onButtonClick, sx } = props;
  return (
    <Card
      variant="outlined"
      sx={[
        {
          border: "none",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <CardContent sx={{ width: "100%" }}>
        <Stack
          direction="column"
          spacing={1}
          sx={{ alignItems: "center", textAlign: "center" }}
        >
          {title && (
            <Typography
              sx={{ textTransform: "capitalize", fontWeight: "bold" }}
              variant="body1"
            >
              {title}
            </Typography>
          )}
          {message && (
            <Typography variant="body1" color="text.secondary" mb={2}>
              {message}
            </Typography>
          )}
        </Stack>
      </CardContent>
      <CardActions
        disableSpacing
        sx={{ width: "100%", justifyContent: "center", mb: 2 }}
      >
        <Button variant="contained" onClick={onButtonClick}>
          {buttonLabel}
        </Button>
      </CardActions>
    </Card>
  );
};
