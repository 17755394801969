import { useCallback } from "react";
import { configService } from "./configService";
import { ClientConfig } from "./types";

export function useConfig() {
  const _getConfigValue = useCallback(getConfigValue, []);

  return { getConfigValue: _getConfigValue };
}

export const getConfigValue = <T>(selector: (config: ClientConfig) => T): T => {
  return configService.getValue(selector);
};
