import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { isNotificationSupported } from "../common/constants";
import { ENV } from "src/config/envVariables";

export const firebaseConfig = {
  apiKey: ENV.FIREBASE.API_KEY,
  authDomain: ENV.FIREBASE.AUTH_DOMAIN,
  projectId: ENV.FIREBASE.PROJECT_ID,
  storageBucket: ENV.FIREBASE.STORAGE_BUCKET,
  messagingSenderId: ENV.FIREBASE.MESSAGING_SENDER_ID,
  appId: ENV.FIREBASE.APP_ID,
  measurementId: ENV.FIREBASE.MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const messaging = (() => {
  if (!isNotificationSupported) return null;
  try {
    return getMessaging(app);
  } catch (error) {
    console.log(error);

    return null;
  }
})();
