/**
 * Standardizes the translations object to have lowercase keys
 * @param translations - The translations object to standardize
 * @returns The standardized translations object
 */
export const standardizeTranslations = (
  translations: Record<string, Record<string, string>> | [] = {}
) => {
  if (Array.isArray(translations)) {
    return {};
  }

  return Object.fromEntries(
    Object.entries(translations).map(([key, value]) => [
      key.toLowerCase(),
      value,
    ])
  );
};

/**
 * Gets the translation for a given language code
 * @param translations - The translations object
 * @param languageCode - The language code to get the translation for
 * @returns The translation for the given language code
 */
export const getTranslationByLanguageCode = (
  translations: Record<string, Record<string, string>>,
  languageCode: string
) => {
  return translations[languageCode.toLowerCase()] || {};
};
