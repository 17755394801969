/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Self Service API
 * An API which allows a client to interact with the self-service system.
This system exists to manage studies, track participants and oversee expenses.

This API conforms to the JSON:API specification. You can find the specification, [here](https://jsonapi.org/format/).

JSON:API allows clients to pull relationship data within all endpoints using the `include` query parameter.
When this parameter is provided, that relation will be found within the root `included` array located at the end of
each response. 

When updating resources via a `PUT` request, no partial updates are allowed. Please ensure to send all `attributes`
and `relationships`. The only exception to this rule are computed fields - you may omit these from your request body.

This API implements soft deletes for all entities and the soft delete filter is enabled for all endpoints. By
default, no soft delete entities will be shown within list endpoints unless specifically requested. See individual
endpoints for more information.

 * OpenAPI spec version: 0.1.0-alpha
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  PaymentMethodCreateRequestBody,
  PaymentMethodCreateResponseResponse,
  PaymentMethodReadResponseResponse,
  PaymentMethodSetDefaultRequestBody,
  PaymentMethodSetDefaultResponseResponse,
  PaymentMethodVisualiseResponseResponse
} from '../../model/resources-payment-methods.yml'
import type {
  BadRequestErrorResponseResponse,
  CreateNewPaymentMethodParams,
  NotFoundErrorResponseResponse,
  ServerErrorResponseResponse,
  ValidationErrorResponseResponse
} from '../../model'
import { nmibleInstance } from '../../../api/mutator/axios-instance';
import type { ErrorType, BodyType } from '../../../api/mutator/axios-instance';
import { PaymentMethodsResponse } from 'src/api/model/resources-payment-methods.yml/paymentMethodsResponse';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `payment-methods.create` permission.

# Extra information
- This endpoint will return the newly created payment method.

 * @summary Create a new payment method.
 */
export const createNewPaymentMethod = (
    paymentMethodCreateRequestBody: BodyType<PaymentMethodCreateRequestBody>,
    params?: CreateNewPaymentMethodParams,
 options?: SecondParameter<typeof nmibleInstance>,signal?: AbortSignal
) => {
      
      
      return nmibleInstance<PaymentMethodCreateResponseResponse>(
      {url: `/payment-methods`, method: 'POST',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: paymentMethodCreateRequestBody,
        params, signal
    },
      options);
    }
  


export const getCreateNewPaymentMethodMutationOptions = <TError = ErrorType<BadRequestErrorResponseResponse | ValidationErrorResponseResponse | ServerErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNewPaymentMethod>>, TError,{data: BodyType<PaymentMethodCreateRequestBody>;params?: CreateNewPaymentMethodParams}, TContext>, request?: SecondParameter<typeof nmibleInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createNewPaymentMethod>>, TError,{data: BodyType<PaymentMethodCreateRequestBody>;params?: CreateNewPaymentMethodParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createNewPaymentMethod>>, {data: BodyType<PaymentMethodCreateRequestBody>;params?: CreateNewPaymentMethodParams}> = (props) => {
          const {data,params} = props ?? {};

          return  createNewPaymentMethod(data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateNewPaymentMethodMutationResult = NonNullable<Awaited<ReturnType<typeof createNewPaymentMethod>>>
    export type CreateNewPaymentMethodMutationBody = BodyType<PaymentMethodCreateRequestBody>
    export type CreateNewPaymentMethodMutationError = ErrorType<BadRequestErrorResponseResponse | ValidationErrorResponseResponse | ServerErrorResponseResponse>

    /**
 * @summary Create a new payment method.
 */
export const useCreateNewPaymentMethod = <TError = ErrorType<BadRequestErrorResponseResponse | ValidationErrorResponseResponse | ServerErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNewPaymentMethod>>, TError,{data: BodyType<PaymentMethodCreateRequestBody>;params?: CreateNewPaymentMethodParams}, TContext>, request?: SecondParameter<typeof nmibleInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createNewPaymentMethod>>,
        TError,
        {data: BodyType<PaymentMethodCreateRequestBody>;params?: CreateNewPaymentMethodParams},
        TContext
      > => {

      const mutationOptions = getCreateNewPaymentMethodMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * # Authentication/authorization
- 🔐 You must be authenticated as a participant.
- 🪪 You must have the `payment-method.read` permission.

# Extra information
- This endpoint will fetch the local payment method information as well as query PPS to fetch updated information.

 * @summary Read payment method by ID.
 */
export const readPaymentMethodById = (
    id: string,
 options?: SecondParameter<typeof nmibleInstance>,signal?: AbortSignal
) => {
      
      
      return nmibleInstance<PaymentMethodReadResponseResponse>(
      {url: `/payment-methods/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getReadPaymentMethodByIdQueryKey = (id: string,) => {
    return [`/payment-methods/${id}`] as const;
    }

    
export const getReadPaymentMethodByIdQueryOptions = <TData = Awaited<ReturnType<typeof readPaymentMethodById>>, TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof readPaymentMethodById>>, TError, TData>>, request?: SecondParameter<typeof nmibleInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadPaymentMethodByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readPaymentMethodById>>> = ({ signal }) => readPaymentMethodById(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readPaymentMethodById>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ReadPaymentMethodByIdQueryResult = NonNullable<Awaited<ReturnType<typeof readPaymentMethodById>>>
export type ReadPaymentMethodByIdQueryError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>


export function useReadPaymentMethodById<TData = Awaited<ReturnType<typeof readPaymentMethodById>>, TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof readPaymentMethodById>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof readPaymentMethodById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof nmibleInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useReadPaymentMethodById<TData = Awaited<ReturnType<typeof readPaymentMethodById>>, TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof readPaymentMethodById>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof readPaymentMethodById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof nmibleInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useReadPaymentMethodById<TData = Awaited<ReturnType<typeof readPaymentMethodById>>, TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof readPaymentMethodById>>, TError, TData>>, request?: SecondParameter<typeof nmibleInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Read payment method by ID.
 */

export function useReadPaymentMethodById<TData = Awaited<ReturnType<typeof readPaymentMethodById>>, TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof readPaymentMethodById>>, TError, TData>>, request?: SecondParameter<typeof nmibleInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getReadPaymentMethodByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * # Authentication/authorization
- 🔐 You must be authenticated as a participant.
- 🪪 You must have the `payment-method.read` permission.

# Extra information
- This endpoint will query PPS to get a valid card ID and signature to pass to Highnote.

 * @summary Visualise payment method by ID.
 */
export const visualisePaymentMethodById = (
    id: string,
 options?: SecondParameter<typeof nmibleInstance>,signal?: AbortSignal
) => {
      
      
      return nmibleInstance<PaymentMethodVisualiseResponseResponse>(
      {url: `/payment-methods/${id}/visualise`, method: 'GET', signal
    },
      options);
    }
  

export const getVisualisePaymentMethodByIdQueryKey = (id: string,) => {
    return [`/payment-methods/${id}/visualise`] as const;
    }

    
export const getVisualisePaymentMethodByIdQueryOptions = <TData = Awaited<ReturnType<typeof visualisePaymentMethodById>>, TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof visualisePaymentMethodById>>, TError, TData>>, request?: SecondParameter<typeof nmibleInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVisualisePaymentMethodByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof visualisePaymentMethodById>>> = ({ signal }) => visualisePaymentMethodById(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof visualisePaymentMethodById>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type VisualisePaymentMethodByIdQueryResult = NonNullable<Awaited<ReturnType<typeof visualisePaymentMethodById>>>
export type VisualisePaymentMethodByIdQueryError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>


export function useVisualisePaymentMethodById<TData = Awaited<ReturnType<typeof visualisePaymentMethodById>>, TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof visualisePaymentMethodById>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof visualisePaymentMethodById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof nmibleInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useVisualisePaymentMethodById<TData = Awaited<ReturnType<typeof visualisePaymentMethodById>>, TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof visualisePaymentMethodById>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof visualisePaymentMethodById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof nmibleInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useVisualisePaymentMethodById<TData = Awaited<ReturnType<typeof visualisePaymentMethodById>>, TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof visualisePaymentMethodById>>, TError, TData>>, request?: SecondParameter<typeof nmibleInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Visualise payment method by ID.
 */

export function useVisualisePaymentMethodById<TData = Awaited<ReturnType<typeof visualisePaymentMethodById>>, TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof visualisePaymentMethodById>>, TError, TData>>, request?: SecondParameter<typeof nmibleInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getVisualisePaymentMethodByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * # Authentication/authorization
- 🔐 You must be authenticated as a participant.
- 🪪 You must have the `payment-method.create` permission.

# Extra information
- This endpoint will communicate the PPS and set this payment method as the default. 
- It's only possible to set a virtual card as the latest. Attempting `ach-out` will result in a 500.

 * @summary Set the payment method as the latest payment method.
 */
export const setDefaultPaymentMethodById = (
    id: string,
    paymentMethodSetDefaultRequestBody: BodyType<PaymentMethodSetDefaultRequestBody>,
 options?: SecondParameter<typeof nmibleInstance>,signal?: AbortSignal
) => {
      
      
      return nmibleInstance<PaymentMethodSetDefaultResponseResponse>(
      {url: `/payment-methods/${id}/set-default`, method: 'POST',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: paymentMethodSetDefaultRequestBody, signal
    },
      options);
    }
  


export const getSetDefaultPaymentMethodByIdMutationOptions = <TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setDefaultPaymentMethodById>>, TError,{id: string;data: BodyType<PaymentMethodSetDefaultRequestBody>}, TContext>, request?: SecondParameter<typeof nmibleInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof setDefaultPaymentMethodById>>, TError,{id: string;data: BodyType<PaymentMethodSetDefaultRequestBody>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setDefaultPaymentMethodById>>, {id: string;data: BodyType<PaymentMethodSetDefaultRequestBody>}> = (props) => {
          const {id,data} = props ?? {};

          return  setDefaultPaymentMethodById(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SetDefaultPaymentMethodByIdMutationResult = NonNullable<Awaited<ReturnType<typeof setDefaultPaymentMethodById>>>
    export type SetDefaultPaymentMethodByIdMutationBody = BodyType<PaymentMethodSetDefaultRequestBody>
    export type SetDefaultPaymentMethodByIdMutationError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>

    /**
 * @summary Set the payment method as the latest payment method.
 */
export const useSetDefaultPaymentMethodById = <TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setDefaultPaymentMethodById>>, TError,{id: string;data: BodyType<PaymentMethodSetDefaultRequestBody>}, TContext>, request?: SecondParameter<typeof nmibleInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof setDefaultPaymentMethodById>>,
        TError,
        {id: string;data: BodyType<PaymentMethodSetDefaultRequestBody>},
        TContext
      > => {

      const mutationOptions = getSetDefaultPaymentMethodByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * # Authentication/authorization
- 🔐 You must be authenticated as a participant.
- 🪪 You must have the `payment-method.index` permission.

# Extra information
- This endpoint will return a paginated list of payment methods scoped to the logged in participant.

 * @summary Get payment methods.
 */
export const getPaymentMethods = (
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<PaymentMethodsResponse>(
    {
      url: `/payment-methods`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getPaymentMethodsQueryKey = () => {
  return [`/payment-methods`] as const;
};

export const getPaymentMethodsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPaymentMethods>>,
  TError = ErrorType<
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
  >
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getPaymentMethods>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPaymentMethodsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPaymentMethods>>
  > = ({ signal }) => getPaymentMethods(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPaymentMethods>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type PaymentMethodsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPaymentMethods>>
>;
export type PaymentMethodsQueryError = ErrorType<
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ServerErrorResponseResponse
>;

export function usePaymentMethods<
  TData = Awaited<ReturnType<typeof getPaymentMethods>>,
  TError = ErrorType<
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
  >
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getPaymentMethods>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getPaymentMethods>>,
        TError,
        TData
      >,
      "initialData"
    >;
  request?: SecondParameter<typeof nmibleInstance>;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function usePaymentMethods<
  TData = Awaited<ReturnType<typeof getPaymentMethods>>,
  TError = ErrorType<
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
  >
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getPaymentMethods>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getPaymentMethods>>,
        TError,
        TData
      >,
      "initialData"
    >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function usePaymentMethods<
  TData = Awaited<ReturnType<typeof getPaymentMethods>>,
  TError = ErrorType<
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
  >
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getPaymentMethods>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Visualise payment method by ID.
 */

export function usePaymentMethods<
  TData = Awaited<ReturnType<typeof getPaymentMethods>>,
  TError = ErrorType<
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
  >
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getPaymentMethods>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getPaymentMethodsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

type PaymentMethodActionJsonApiBlockWithId = {
  id: string;
  type: "payment-methods";
  attributes: {
    hasAcceptedCardholderAgreement: boolean;
  };
};

type PaymentMethodActionUpdateRequestBody = {
  data: {
    id: string;
    type: "payment-methods";
    attributes: {
      hasAcceptedCardholderAgreement: boolean;
    };
  };
};

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `payment-method.create` permission.

# Extra information
 - This endpoint will allow a user to accept the payment method cardholder agreement
 - This endpoint will return no content.

 * @summary Action a Payment Method by ID.
 */
export const paymentMethodActionById = (
  id: string,
  paymentMethodActionUpdateRequestBody: PaymentMethodActionUpdateRequestBody,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<PaymentMethodActionJsonApiBlockWithId>(
    {
      url: `/payment-methods/${id}/action`,
      method: "PUT",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: paymentMethodActionUpdateRequestBody,
    },
    options
  );
};

export const getPaymentActionByIdMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof paymentMethodActionById>>,
    TError,
    { id: string; data: PaymentMethodActionUpdateRequestBody },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof paymentMethodActionById>>,
  TError,
  { id: string; data: PaymentMethodActionUpdateRequestBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof paymentMethodActionById>>,
    { id: string; data: PaymentMethodActionUpdateRequestBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return paymentMethodActionById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AcceptCardholderAgreementMutationResult = NonNullable<
  Awaited<ReturnType<typeof paymentMethodActionById>>
>;

export type AcceptCardholderAgreementByIdMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Action a Payment Method by ID.
 */
export const useUpdatePaymentMethodActionById = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof paymentMethodActionById>>,
    TError,
    { id: string; data: PaymentMethodActionUpdateRequestBody },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof paymentMethodActionById>>,
  TError,
  { id: string; data: PaymentMethodActionUpdateRequestBody },
  TContext
> => {
  const mutationOptions = getPaymentActionByIdMutationOptions(options);

  return useMutation(mutationOptions);
};