import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button } from "@mui/material";

import { useLocation } from "react-router-dom";
import { getLanguageName, supportedLanguagesEnv } from "../../i18n";
import { setIsLanguageSelectorDialogOpen } from "../../redux/slices/languageSelectorDialogSlice";
import { useLanguages } from "../../common/hooks/useLanguages";
export default function LanguageButton({ color }: { color?: string }) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const isLogin = location.pathname === "/login";
  const { languages: availableStudyLanguages, languagesLoading } =
    useLanguages();

  useEffect(() => {
    if (isLogin) return;
    const currentLang = i18n.language;
    function isStudyLanguage(lang: string): boolean {
      return getStudyLanguageByCode(lang) !== undefined;
    }

    function getStudyLanguageByCode(code: string) {
      return availableStudyLanguages.find((item) => {
        const itemLangCode = item.attributes.code;
        return itemLangCode === code;
      });
    }
    const isCurrentLangSupportedByStudy = isStudyLanguage(currentLang);
    if (!isCurrentLangSupportedByStudy && !languagesLoading)
      dispatch(
        setIsLanguageSelectorDialogOpen({ isOpen: true, isForced: true })
      );
  }, [i18n.language, availableStudyLanguages]);

  const currentLanguageText = useMemo(() => {
    if (i18n.language) {
      const name = getLanguageName(i18n.language);

      return name;
    }
  }, [i18n.language]);

  const handleFlagClick = () => {
    dispatch(setIsLanguageSelectorDialogOpen({ isOpen: true }));
  };

  const isSingleLanguage = isLogin
    ? supportedLanguagesEnv.length <= 1
    : availableStudyLanguages.length <= 1;

  if (isSingleLanguage) return null;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <DarkModeSwitch
        moonColor="black"
        sunColor="white"
        style={{ marginRight: "8px", padding: "8px" }}
        checked={mode === "dark"}
        onChange={toggleColorMode}
        size={40}
      /> */}

      <Button
        data-test-id="language-button"
        onClick={handleFlagClick}
        variant="text"
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ color: color ?? "white", fontWeight: "600", pl: 0 }}
      >
        {currentLanguageText}
      </Button>
    </Box>
  );
}
