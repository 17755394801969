import { useInfiniteQuery } from "@tanstack/react-query";
import {
  getListAllMicropaymentsQueryKey,
  listAllMicropayments,
} from "src/api/client/micropayments/micropayments";
import { getPageNumber } from "../helpers";
import {
  getTranslationByLanguageCode,
  standardizeTranslations,
} from "./translations";
import i18n from "i18next";

export const useMicropayments = () => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    error,
  } = useInfiniteQuery({
    queryKey: getListAllMicropaymentsQueryKey(),
    queryFn: async ({ pageParam = 1 }) => {
      const response = await listAllMicropayments({
        "page[number]": pageParam.toString(),
        "page[size]": "10",
      });

      return response;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const nextLink = lastPage?.links?.next;
      if (nextLink) {
        const pageNumber = getPageNumber(nextLink);
        if (pageNumber > allPages.length) return pageNumber;
      }
      return undefined;
    },
  });

  const micropayments =
    data?.pages.flatMap((pageResponse) => pageResponse.data ?? []) ?? [];

  const translatedMicropayments = micropayments.map((micropayment) => {
    const micropaymentTranslations = standardizeTranslations(
      micropayment.attributes.translations
    );

    return {
      ...micropayment,
      attributes: {
        ...micropayment.attributes,
        ...getTranslationByLanguageCode(
          micropaymentTranslations,
          i18n.language
        ),
      },
    };
  });

  return {
    micropayments: translatedMicropayments,
    micropaymentsError: error,
    fetchNextMicropaymentsPage: fetchNextPage,
    hasNextMicropaymentsPage: hasNextPage,
    isFetchingMicropayments: isFetching,
    isFetchingNextMicropaymentsPage: isFetchingNextPage,
  };
};
