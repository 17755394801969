import i18n from "i18next";
import { ENV } from "src/config/envVariables";

const DOCUMENT_TYPES = {
  PRIVACY: "privacy",
  TERMS: "terms",
  CARD_AGREEMENT: "card-agreement",
} as const;

type DocumentType = (typeof DOCUMENT_TYPES)[keyof typeof DOCUMENT_TYPES];

const FILE_EXTENSIONS = {
  HTML: "html",
  PDF: "pdf",
} as const;

export const getLegalDocumentLinks = (
  documentType: DocumentType,
  {
    clientId,
  }: Partial<{
    clientId: string;
  }> = {}
): { pdf: string; content: string } => {
  const language = i18n.language.toLowerCase();
  const clientPath = clientId ? `/${clientId}` : "";
  const basePath = `${ENV.AMAZON.LINK}/${language}${clientPath}`;

  const documents = {
    [DOCUMENT_TYPES.PRIVACY]: {
      content: `${basePath}/privacy.${FILE_EXTENSIONS.HTML}`,
      pdf: `${basePath}/privacy.${FILE_EXTENSIONS.PDF}`,
    },
    [DOCUMENT_TYPES.TERMS]: {
      content: `${basePath}/summary.${FILE_EXTENSIONS.HTML}`,
      pdf: `${basePath}/tc.${FILE_EXTENSIONS.PDF}`,
    },
    [DOCUMENT_TYPES.CARD_AGREEMENT]: {
      content: `${basePath}/card-agreement/cha.${FILE_EXTENSIONS.HTML}`,
      pdf: `${basePath}/card-agreement/cha.${FILE_EXTENSIONS.PDF}`,
    },
  };

  return documents[documentType];
};
