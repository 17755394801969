export const getEnvConfig = () => ({
  EVERVAULT: {
    TEAM: import.meta.env.VITE_EVERVAULT_TEAM,
    APP: import.meta.env.VITE_EVERVAULT_APP,
    FUNCTION: import.meta.env.VITE_EVERVAULT_FUNCTION,
    SERVER: import.meta.env.VITE_EVERVAULT_SERVER,
  },
  SENTRY: {
    DSN: import.meta.env.VITE_SENTRY_DSN,
    ENV: import.meta.env.VITE_SENTRY_ENV,
  },
  SUPPORT: {
    TELEPHONE: import.meta.env.VITE_SUPPORT_TELEPHONE,
  },
  LOQATE: {
    SERVER: import.meta.env.VITE_LOQATE_SERVER,
    KEY: import.meta.env.VITE_LOQATE_KEY,
  },
  FIREBASE: {
    API_KEY: import.meta.env.VITE_FIREBASE_API_KEY,
    AUTH_DOMAIN: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    PROJECT_ID: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    STORAGE_BUCKET: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    MESSAGING_SENDER_ID: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    APP_ID: import.meta.env.VITE_FIREBASE_APP_ID,
    MEASUREMENT_ID: import.meta.env.VITE_FIREBASE_MEASURMENT_ID,
    VAPID_KEY: import.meta.env.VITE_FIREBASE_VAPID_KEY,
  },
  APP: {
    GLOBAL_MESSAGE: import.meta.env.VITE_GLOBAL_MESSAGE,
    DEMO_MODE: import.meta.env.VITE_DEMO_MODE === "true",
    CLIENT: import.meta.env.VITE_CLIENT.toLowerCase(),
    SUPPORTED_LANGUAGES:
      import.meta.env.VITE_SUPPORTED_LANGUAGES?.split(",").map((code: string) =>
        code.trim()
      ) ?? [],
  },
  AMAZON: {
    LINK: import.meta.env.VITE_AMAZON_LINK,
  },
  NMIBLE: {
    SERVER: import.meta.env.VITE_NMIBLE_SERVER,
  },
});

export const ENV = getEnvConfig();

export type SupportedLanguage = (typeof ENV.APP.SUPPORTED_LANGUAGES)[number];
