import PWAInstall from "@khmyznikov/pwa-install/dist/pwa-install.react.js";
import * as React from "react";
import { useEffect, useRef } from "react";
import StorageManager from "../services/storage";
import { useConfig } from "src/config/useConfig";
import { selectPwaPromptThresholdDays } from "src/config/selectors";

interface PWAInstallComponentProps {
  onInstallSuccess?: (event: Event) => void;
  onInstallFail?: (event: Event) => void;
  onUserChoiceResult?: (event: Event) => void;
  onInstallAvailable?: (event: Event) => void;
  onInstallHowTo?: (event: Event) => void;
  onInstallGallery?: (event: Event) => void;
  manifestUrl?: string;
  icon?: string;
  name?: string;
  description?: string;
  installDescription?: string;
  disableDescription?: boolean;
  disableScreenshots?: boolean;
  manualApple?: boolean;
  manualChrome?: boolean;
  disableChrome?: boolean;
}

const PWAInstallComponent: React.FC<PWAInstallComponentProps> = ({
  onInstallSuccess,
  onInstallFail,
  onUserChoiceResult,
  onInstallAvailable,
  onInstallHowTo,
  onInstallGallery,
  ...props
}) => {
  const pwaInstallRef = useRef<any>(null);
  const { getConfigValue } = useConfig();
  const promptThreshold = getConfigValue(selectPwaPromptThresholdDays);

  const nonNullProps = Object.fromEntries(
    Object.entries(props).filter(([_, value]) => value != null)
  );

  useEffect(() => {
    const currentElement = pwaInstallRef.current;

    if (currentElement) {
      const handleInstallSuccess = (event: Event) => onInstallSuccess?.(event);
      const handleInstallFail = (event: Event) => onInstallFail?.(event);
      const handleInstallGallery = (event: Event) => onInstallGallery?.(event);

      const handleInstallHowTo = (event: Event) => {
        if (shouldShowPWAInstallPrompt()) {
          onInstallHowTo?.(event);
          StorageManager.setPWAInstructionsShownDate();
        }
      };

      const handleUserChoiceResult = (event: Event) => {
        if ((event as any).detail.message === "dismissed") {
          StorageManager.setDismissPWAInstallDate();
        }

        onUserChoiceResult?.(event);
      };

      const handleInstallAvailable = (event: Event) => {
        if (shouldShowPWAInstallPrompt()) {
          currentElement.showDialog(true);
        }

        onInstallAvailable?.(event);
      };

      currentElement.addEventListener(
        "pwa-install-success-event",
        handleInstallSuccess
      );

      currentElement.addEventListener(
        "pwa-install-fail-event",
        handleInstallFail
      );

      currentElement.addEventListener(
        "pwa-user-choice-result-event",
        handleUserChoiceResult
      );

      currentElement.addEventListener(
        "pwa-install-available-event",
        handleInstallAvailable
      );

      currentElement.addEventListener(
        "pwa-install-how-to-event",
        handleInstallHowTo
      );

      currentElement.addEventListener(
        "pwa-install-gallery-event",
        handleInstallGallery
      );

      return () => {
        currentElement.removeEventListener(
          "pwa-install-success-event",
          handleInstallSuccess
        );

        currentElement.removeEventListener(
          "pwa-install-fail-event",
          handleInstallFail
        );

        currentElement.removeEventListener(
          "pwa-user-choice-result-event",
          handleUserChoiceResult
        );

        currentElement.removeEventListener(
          "pwa-install-available-event",
          handleInstallAvailable
        );

        currentElement.removeEventListener(
          "pwa-install-how-to-event",
          handleInstallHowTo
        );

        currentElement.removeEventListener(
          "pwa-install-gallery-event",
          handleInstallGallery
        );
      };
    }
  }, [
    onInstallSuccess,
    onInstallFail,
    onUserChoiceResult,
    onInstallAvailable,
    onInstallHowTo,
    onInstallGallery,
  ]);

  const shouldShowPWAInstallPrompt = (): boolean => {
    const instructionsDate = StorageManager.getPWAInstructionsShownDate();

    if (instructionsDate) {
      return false;
    }

    const dismissDate = StorageManager.getDismissPWAInstallDate();

    if (!dismissDate) {
      return true;
    }

    const currentDate = new Date();
    const thresholdDate = new Date(dismissDate);

    thresholdDate.setDate(thresholdDate.getDate() + promptThreshold);

    return currentDate >= thresholdDate;
  };

  return <PWAInstall ref={pwaInstallRef} {...nonNullProps} />;
};

export default PWAInstallComponent;
