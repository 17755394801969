import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { Alert, Link, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isBankAccount } from "src/features/payment-methods/types/payment-method-types";
import { describePaymentAccount } from "src/features/payment-methods/utils/payment-account";
import { PaymentMethodAttributes } from "src/api/model/resources-payment-methods.yml";

const paymentMethodIcon = (paymentMethod: string) => {
  if (isBankAccount(paymentMethod)) {
    return <AccountBalanceIcon color="action" />;
  }
  if (paymentMethod === "Email") {
    return <AttachEmailIcon color="action" />;
  }

  return <CreditCardIcon color="action" />;
};

interface LatestPaymentProps {
  paymentAccount: PaymentMethodAttributes;
  showChangeButton?: boolean;
  useTableStyle?: boolean;
}

const LatestPayment: React.FC<LatestPaymentProps> = ({
  paymentAccount,
  showChangeButton = true,
  useTableStyle = false,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onProceed = () => {
    navigate("/payment-methods/list");
  };

  const cardId = paymentAccount.cardNumber ?? paymentAccount.accountIdentifier;

  return (
    <Alert
      icon={paymentMethodIcon(paymentAccount.accountType)}
      data-test-id="payment-account"
      sx={{
        textAlign: "left",
        backgroundColor: "white",
        border: "1px solid #efefef",
        borderRadius: "8px",
        my: "1.3rem",
        ...(useTableStyle && {
          width: "100%",
          border: "none",
          mt: "0px",
          mb: "0px",
        }),
      }}
    >
      <Typography
        data-test-id="payment-account-title"
        variant="subtitle2"
        color="text.secondary"
      >
        {t("mileageReview_PaymentMethod")}
      </Typography>

      <Typography variant="body2" data-test-id="payment-account-description">
        {describePaymentAccount({
          accountType: paymentAccount.accountType,
          identifier: cardId,
          t,
        })}
      </Typography>

      {showChangeButton && (
        <Link
          onClick={onProceed}
          underline="always"
          data-test-id="update-payment-method"
          sx={{ cursor: "pointer" }}
        >
          {t("mileageReview_ChangePaymentMethod")}
        </Link>
      )}
    </Alert>
  );
};

export default LatestPayment;
