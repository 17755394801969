import { useTranslation } from "react-i18next";
import { getLegalDocumentLinks } from "./utils";

interface CardAgreementProps {
  clientId?: string;
}

export const CardAgreement = (props: CardAgreementProps) => {
  const { clientId } = props;
  const { t } = useTranslation();
  const { content } = getLegalDocumentLinks("card-agreement", {
    clientId,
  });

  return (
    <iframe
      data-test-id="card-agreement-iframe"
      src={content}
      title={t("card_agreement")}
      width="100%"
      height="100%"
      style={{
        border: "none",
        overflow: "hidden",
      }}
    />
  );
};
