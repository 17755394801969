import React from "react";
import { Box, Grid } from "@mui/material";
import CardActions from "./components/CardActions";
import { CardProvider } from "./components/CardContext";
import CardViewer from "./components/CardViewer";

const CardDetailsPage: React.FC = () => {
  return (
    <CardProvider>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <CardActions />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardViewer />
          </Grid>
        </Grid>
      </Box>
    </CardProvider>
  );
};

export default CardDetailsPage;
