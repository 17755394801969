import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { usePageTitle } from "../common/TitleContext";
import { getSymbol } from "../common/helpers";
import { useVisits } from "../common/hooks/useVisits";
import { RootState } from "../redux/store";
import { dateString } from "../types/common";
import { Stipend } from "../types/stipendTypes";
import SummaryPageUI, {
  Action,
  DetailSection,
  VisitDetailSection,
} from "./SummaryPageUI";
import { VisitJsonApiBlockWithId } from "../api/model/resources-visits.yml";
import { useUserDetails } from "src/common/hooks/useUserDetails";

const StipendSummaryPage = () => {
  const { t } = useTranslation();

  usePageTitle(t("claim_stipend_title"));

  const [visit, setVisit] = useState<VisitJsonApiBlockWithId | null>(null);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isAccepting, setIsAccepting] = useState(false);
  const { currencyCode } = useUserDetails();

  const loadClaimById = (id: string) => {
    /* TODO: Implement, If we come from login redirect the claims are still not loaded so we load just this one claim */
  };

  const stipend = useSelector((state: RootState) => {
    const stipendData = state.claims.waitingStipends.find(
      (stipend) => stipend.id === parseInt(id ?? "-1")
    ) as Stipend;

    if (!stipendData) {
      loadClaimById(id ?? "-1");
    }

    return stipendData;
  });

  const currencySymbol = getSymbol(currencyCode);

  const hasTax = stipend.withholding_tax_percentage > 0;

  const { visits } = useVisits();

  useEffect(() => {
    if (stipend?.visit_id) {
      const visit = visits.find((item) => {
        return parseInt(item.id!, 10) === stipend?.visit_id;
      });
      if (visit) setVisit(visit);
    }
  }, [stipend, visits.length]);

  const handleAcceptPayment = () => {
    // if (!id || !latestPaymentAccount) return;
    // if (isAccepting) return;
    // setIsAccepting(true);
    // api(approveStipend(id, latestPaymentAccount))
    //   .then((response) => {
    //     dispatch(showToast(t("claim_stipend_approvedToastMessage")));
    //     navigate("/payments");
    //   })
    //   .catch((error) => {
    //     console.error("Error accepting the stipend:", error);
    //   })
    //   .finally(() => {
    //     setIsAccepting(false);
    //   });
  };

  const handleDeclinePayment = () => {
    navigate(`/payment/${id}/decline`);
  };

  if (!stipend) {
    return <CircularProgress />;
  }

  const actions: Action[] = [
    {
      label: t("claim_stipend_declineButton"),
      onClick: handleDeclinePayment,
      color: "secondary",
      variant: "outlined",
      loading: false,
    },
    {
      label: t("claim_stipend_acceptButton"),
      onClick: handleAcceptPayment,
      color: "primary",
      variant: "contained",
      loading: isAccepting,
    },
  ];
  const detailSections: DetailSection[] = [];

  const visitSection: VisitDetailSection = {
    title: t("claimDetail_relatedVisit"),
    content: visit?.attributes.name ?? t("loading_text"),
    date: visit ? dateString(visit) : t("loading_text"),
  };

  if (hasTax) {
    detailSections.push(
      {
        title: t("claim_originalTotal"),
        content: `${currencySymbol}${stipend.total_amount?.toFixed(2)}`,
      },
      {
        title: t("claim_irsWithholding", {
          "0": `${(stipend.withholding_tax_percentage * 100)?.toFixed(2)}%`,
        }),
        content: `${currencySymbol}${(
          stipend.total_amount - stipend.amount
        ).toFixed(2)}`,
      }
    );
  }
  const totalSection: DetailSection = {
    title: t("mileageReview_Total"),
    content: `${currencySymbol}${stipend.amount?.toFixed(2)}`,
  };

  return (
    <SummaryPageUI
      title={t("claim_reviewYourClaim")}
      isLoading={false}
      showChangePaymentMethodButton={true}
      subtitle={t("claim_reviewSubtitle")}
      detailSections={detailSections}
      visitSection={visitSection}
      actions={actions}
      totalSection={totalSection}
    />
  );
};

export default StipendSummaryPage;
