import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { ENV } from "./config/envVariables";

interface SupportedLanguages {
  [language: string]: {
    regions: Region[];
    default: string;
  };
}
interface Region {
  id: string;
  name: string;
}
interface LocaleResult {
  locale: string;
  usedFallback: boolean;
}

function getLocale(
  languageIdentifier: string,
  supportedLanguages: SupportedLanguages,
  countryCode?: string
): LocaleResult {
  const parts = languageIdentifier.split("-");
  const language = parts[0].toLowerCase();
  const region = parts.length > 1 ? parts[1].toUpperCase() : undefined;

  // First priority is given to the provided countryCode, if valid
  const regionalIdentifier = countryCode
    ? `${language}-${countryCode.toUpperCase()}`
    : region
    ? `${language}-${region}`
    : language;

  // Check if the language is supported
  if (supportedLanguages[language]) {
    // Check for exact regional match or a match with countryCode
    if (
      regionalIdentifier &&
      supportedLanguages[language].regions
        .map((item) => item.id)
        .includes(regionalIdentifier)
    ) {
      return { locale: regionalIdentifier, usedFallback: false };
    }

    // Fallback to the default for the language
    return {
      locale: supportedLanguages[language].default,
      usedFallback: false,
    };
  }

  // Fallback to first supported language instead of hardcoded 'en'
  const firstSupportedLanguage = Object.values(supportedLanguages)[0]?.default;
  return { locale: firstSupportedLanguage, usedFallback: true };
}

const customDetector = (lng: string) => {
  const determinedLocale = getLocale(lng, supportedLanguages);

  return determinedLocale.locale;
};

const languageNames: { [key: string]: string } = {
  bg: "Български", // Bulgarian
  de: "Deutsch", // German
  en: "English", // English
  "es-ES": "Español", // Spanish (Spain)
  es: "Español (Latinoamérica)", // Spanish (Latin America)
  "fr-CA": "Français (Canada)", // French (Canada)
  sv: "Svenska", // Swedish
  fi: "Suomeksi", // Finnish
  fr: "Français", // French
  pt: "Português", // Portuguese
  vi: "Tiếng Việt", // Vietnamese
  zh: "华语", // Chinese (Simplified)
  it: "Italiano", // Italian (Italy)
  ja: "日本語", // Japanese (Japan)
  ko: "한국어", // Korean (South Korea)
  ar: "العربية", // Arabic
  cs: "Čeština", // Czech
  da: "Dansk", // Danish
  et: "Eesti", // Estonian
  lt: "Lietuvių", // Lithuanian
  nl: "Nederlands", // Dutch
  no: "Norsk", // Norwegian (Norway)
  pl: "Polski", // Polish
  ru: "Русский", // Russian
  th: "ไทย", // Thai
  el: "Ελληνικά", // Greek
  he: "עברית", // Hebrew
  hu: "Magyar", // Hungarian
  ro: "Română", // Romanian
  sk: "Slovenčina", // Slovak
  tr: "Türkçe", // Turkish
  uk: "Українська", // Ukrainian
  zf: "繁體中文", // Traditional Chinese (non-standard code)
};

export const getLanguageName = (regionId: string): string => {
  return languageNames[regionId] || regionId;
};

export const supportedLanguagesEnv = ENV.APP.SUPPORTED_LANGUAGES;

export const getSupportedLanguages = (): SupportedLanguages => {
  const languages: SupportedLanguages = {};

  if (supportedLanguagesEnv.length === 0) {
    console.error("No supported languages found in environment variables.");
    return languages;
  }

  const supportedCodes = supportedLanguagesEnv;

  // Group by base language
  supportedCodes.forEach((code) => {
    const [language] = code.split("-");

    if (!languages[language]) {
      languages[language] = {
        regions: [],
        default: code, // First occurrence will be the default
      };
    }

    languages[language].regions.push({
      id: code,
      name: getLanguageName(code),
    });
  });

  return languages;
};

export const supportedLanguages: SupportedLanguages = getSupportedLanguages();

export const getRegionById = (regionId: string): Region | undefined => {
  for (const languageKey in supportedLanguages) {
    const language = supportedLanguages[languageKey];
    const region = language.regions.find((item) => item.id === regionId);
    if (region) {
      return region;
    }
  }

  return undefined;
};

export const getAllRegions = (): Region[] => {
  const regions: Region[] = [];
  Object.values(supportedLanguages).forEach((language) => {
    regions.push(...language.regions);
  });
  return regions;
};

export const changeUserLanguage = (language: string): LocaleResult => {
  const localeResult = getLocale(language ?? "en", supportedLanguages);

  i18n.changeLanguage(localeResult.locale);

  return localeResult;
};

const i18nInstance = i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    load: "currentOnly",
    fallbackLng: supportedLanguagesEnv[0], // Use first supported language as fallback
    supportedLngs: Object.keys(supportedLanguages).flatMap((lang) =>
      supportedLanguages[lang].regions.map((item) => item.id)
    ),
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage"],
      caches: ["localStorage"],
      lookupQuerystring: "lng",
      convertDetectedLanguage: customDetector,
    },
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
    react: {
      useSuspense: true,
    },
  });

export default i18nInstance;
