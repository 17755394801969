import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import { ConfirmationDialogProps } from "./ConfirmationDialog.types";

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {
    open,
    title,
    message,
    onConfirm,
    onDismiss,
    confirmButtonText,
    cancelButtonText,
    label = "",
    maxWidth,
    fullWidth,
    confirmButtonIcon,
    cancelButtonIcon,
    hideConfirmation = false,
    ActionsComponent,
    dismissible = true,
    hideCancelButton,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const showCloseButton =
    hideCancelButton !== undefined ? !hideCancelButton : dismissible;

  return (
    <Dialog
      dir={theme.direction}
      open={open}
      onClose={onDismiss}
      data-test-id={label}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      aria-labelledby={`${label}-title`}
      aria-describedby={`${label}-description`}
    >
      <DialogTitle data-test-id={`${label}-title`} id={`${label}-title`}>
        {title}
      </DialogTitle>

      {dismissible && (
        <IconButton
          data-test-id={`${label}-close-icon`}
          aria-label="close"
          onClick={onDismiss}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}

      <DialogContent>
        <DialogContentText
          data-test-id={`${label}-message`}
          id={`${label}-description`}
        >
          {message}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {ActionsComponent && (
          <Box sx={{ marginRight: "auto" }}>{ActionsComponent}</Box>
        )}

        {showCloseButton && (
          <Button
            data-test-id={`${label}-secondary-button`}
            onClick={onDismiss}
            color="primary"
            startIcon={cancelButtonIcon}
          >
            {cancelButtonText || t("cancel")}
          </Button>
        )}
        {!hideConfirmation && (
          <Button
            data-test-id={`${label}-primary-button`}
            onClick={onConfirm}
            color="primary"
            startIcon={confirmButtonIcon}
          >
            {confirmButtonText || t("ok")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
