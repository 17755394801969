// import { FieldValidation } from "../types/payment-method-types";

import {
  validateEmail,
  validatePhone,
} from "../../../components/LoqateValidationUtils";
import { ValidationStatus } from "../../../components/ValidationTypes";

// export class FormValidator {
//   static validate(value: string, validation: FieldValidation): string {
//     if (validation.required && !value) {
//       return "This field is required";
//     }

//     if (validation.minLength && value.length < validation.minLength) {
//       return `Minimum length is ${validation.minLength} characters`;
//     }

//     if (validation.maxLength && value.length > validation.maxLength) {
//       return `Maximum length is ${validation.maxLength} characters`;
//     }

//     if (validation.pattern) {
//       const regex = new RegExp(validation.pattern);
//       if (!regex.test(value)) {
//         return "Invalid format";
//       }
//     }

//     return "";
//   }
// }

export const asyncValidators = {
  email: async (value: string) => {
    try {
      const result = await validateEmail(value);
      return result === ValidationStatus.VALID ? null : "Invalid email";
    } catch (error) {
      return "Email validation failed";
    }
  },
  phone: async (value: string) => {
    try {
      const result = await validatePhone(value, "USA");
      return result === ValidationStatus.VALID ? null : "Invalid phone number";
    } catch (error) {
      return "Phone validation failed";
    }
  },
};
