import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CardAgreementDialogState = {
  isOpen: boolean;
};

const initialState: CardAgreementDialogState = {
  isOpen: false,
};

const cardAgreementDialogSlice = createSlice({
  name: "cardAgreementDialog",
  initialState,
  reducers: {
    setIsCardAgreementDialogOpen: (
      state,
      action: PayloadAction<CardAgreementDialogState>
    ) => {
      state.isOpen = action.payload.isOpen;
    },
  },
});

export const { setIsCardAgreementDialogOpen } =
  cardAgreementDialogSlice.actions;

export default cardAgreementDialogSlice.reducer;
