// LoginPage.tsx
import GetAppIcon from "@mui/icons-material/GetApp";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Radio,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ForgetPinButton from "../common/ForgetPinButton";
import LanguageButton from "../components/AppHeader/LanguageButton";
import { ConfirmationDialog } from "../components/ConfirmationDialog";
import FormErrorBox from "../components/PaymentMethods/FormErrorBox";
import StudyIDDialog from "../components/StudyIDDialog";
import { reset } from "../redux/store";
import StorageManager from "../services/storage";
import { LoginData, Token } from "../types/common";
import CardContainer from "./CardContainer";

import ChangePINPage from "./ChangePinPage";
import { setAvailablePaymentMethods } from "../redux/slices/paymentMethodsSlice";
import {
  getGetParticipantAccountDetailsQueryKey,
  getParticipantAccountDetails,
  useLoginAsParticipant,
  useOnboardAParticipant,
} from "../api/client/participant/participant";
import { ParticipantAttributes } from "../api/model/resources-participants.yml";
import { AxiosError } from "axios";
import { parseError } from "src/common/errorHandler";
import { usPaymentMethods } from "src/features/payment-methods/mocks/us-payment-method-mocks";
import { prPaymentMethods } from "src/features/payment-methods/mocks/pr-payment-method-mocks";
import { gbPaymentMethods } from "src/features/payment-methods/mocks/gb-payment-method-mocks";
import {
  selectLoginLogo,
  selectTheme,
  selectClientId,
  selectIsFeatureEnabled,
  selectClientName,
} from "src/config/selectors";
import { useConfig } from "src/config/useConfig";
import { AppRoute } from "src/types/routes";
import { getLegalDocumentLinks } from "src/components/LegalDocuments/utils";
import { TermsAndConditions } from "src/components/LegalDocuments/TermsAndConditions";
import { PrivacyPolicy } from "src/components/LegalDocuments/PrivacyPolicy";
import { useQueryClient } from "@tanstack/react-query";
import { PaymentMethodJsonApiBlockWithId } from "src/api/model/resources-payment-methods.yml";
import { getListAllLanguagesQueryKey } from "src/api/client/languages/languages";
import { listAllLanguages } from "src/api/client/languages/languages";
import { Client } from "src/config/types";
import { vcPaymentMethods } from "src/features/payment-methods/mocks/vc-payment-method-mocks";

export default function LoginPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { getConfigValue } = useConfig();
  const queryClient = useQueryClient();

  const loginPagePrivacyPolicyEnabled = getConfigValue(
    selectIsFeatureEnabled("loginPagePrivacyPolicy")
  );
  const clientTheme = getConfigValue(selectTheme);
  const clientLoginLogo = getConfigValue(selectLoginLogo);
  const clientId = getConfigValue(selectClientId);
  const clientName = getConfigValue(selectClientName);
  const defaultRedirectPath = AppRoute.PAYMENTS;
  const pathToIgnore = "/submit/";

  const [isNewUser, setIsNewUser] = useState(true);
  const [userType, setUserType] = useState("participant");
  const [participantId, setParticipantId] = useState("");
  const [yearOfBirth, setYearOfBirth] = useState("");
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [openTermsDialog, setOpenTermsDialog] = useState(false);
  const [openPrivacyPolicyDialog, setOpenPrivacyPolicyDialog] = useState(false);
  const [openStudyIDDialog, setOpenStudyIDDialog] = useState(false);
  const [studyID, setStudyID] = useState("");
  const [error, setError] = useState<string>("");
  const [showPIN, setShowPIN] = useState(false);
  const [requiresPinChange, setRequiresPinChange] = useState(false);
  const state: { isInactive?: boolean; from?: Location } = location.state || {};
  const isInactivityRedirect = state.isInactive ?? false;
  let callbackURL: string = state.from?.pathname ?? defaultRedirectPath;

  const {
    mutate: sendLoginRequest,
    isPending: isLoginPending,
    isError: isLoginFailed,
  } = useLoginAsParticipant({
    mutation: {
      onError: (error) => {
        console.log(error);
        handleFailure(error as AxiosError);
      },
      onSuccess: (data) => {
        const participantData = data.data?.attributes;
        if (!participantData) return;
        handleAuthSuccess(participantData);
      },
    },
  });

  const {
    mutate: sendOnboardRequest,
    isPending: isOnboardPending,
    isError: isOnboardFailed,
  } = useOnboardAParticipant({
    mutation: {
      onError: (error) => {
        handleFailure(error as AxiosError);
      },
      onSuccess: () => {
        sendLoginRequest({
          data: { clientId: participantId.trim(), pin: pin.trim() },
        });
      },
    },
  });

  function handleFailure(error: AxiosError) {
    const { message } = parseError(error);
    setError(t(message));
  }

  function handleAuthSuccess(participantData: ParticipantAttributes) {
    const tokenData: Token = {
      access_token: participantData.access,
      requiresPinChange: participantData.requiresPinChange,
    };
    StorageManager.setTokenData(tokenData);
    if (tokenData.requiresPinChange) setRequiresPinChange(true);
    else performPostLoginActions();
  }

  if (callbackURL && callbackURL.toString().includes(pathToIgnore)) {
    callbackURL = defaultRedirectPath;
  }

  useEffect(() => {
    if (isInactivityRedirect) {
      const loginInformation = StorageManager.getLoginInformation();

      setIsNewUser(false);
      setTermsAccepted(true);
      setParticipantId(loginInformation?.clientId || "");
      setYearOfBirth(loginInformation?.yearOfBirth?.toString() || "");
    }
  }, []);

  useEffect(() => {
    reset();
    if (!isInactivityRedirect) StorageManager.clear();
  }, []);

  const closeStudyIDDialog = () => {
    setOpenStudyIDDialog(false);
    setStudyID("");
  };

  const handleStudyIDSubmit = () => {
    if (studyID.trim() === "") {
      return;
    }

    login();
    closeStudyIDDialog();
  };

  const handleOpenTermsDialog = () => {
    setOpenTermsDialog(true);

    return false;
  };

  const handleCloseTermsDialog = () => {
    setOpenTermsDialog(false);
  };

  const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(event.target.checked);
  };

  const handleDownloadPdf = (link: string) => () => {
    window.open(link, "_blank");
  };

  const handleClickShowPIN = () => {
    setShowPIN((show) => !show);
  };

  const isFormValid = useMemo(() => {
    const trimmedParticipantId = participantId.trim();

    if (!trimmedParticipantId) {
      return false;
    }

    if (!pin.trim()) {
      return false;
    }

    if (isNewUser) {
      if (confirmPin.trim() === "" || pin !== confirmPin) {
        return false;
      }

      if (userType === "participant") {
        const trimmedYearOfBirth = yearOfBirth.trim();

        if (trimmedYearOfBirth === "") {
          return false;
        }

        const currentYear = new Date().getFullYear();
        const year = parseInt(trimmedYearOfBirth, 10);
        if (isNaN(year) || year > currentYear) {
          return false;
        }
      }
    }

    if (!termsAccepted) {
      return false;
    }

    if (loginPagePrivacyPolicyEnabled && !privacyPolicyAccepted) {
      return false;
    }

    return true;
  }, [
    participantId,
    confirmPin,
    yearOfBirth,
    pin,
    isNewUser,
    termsAccepted,
    privacyPolicyAccepted,
  ]);

  const executeLoginAttempt = (loginData: LoginData) => {
    if (loginData.isNewUser)
      sendOnboardRequest({
        data: {
          clientId: loginData.clientId,
          yearOfBirth: loginData.yearOfBirth ?? 0,
          pin: loginData.pin,
          pin_confirmation: loginData.pin_confirmation ?? "",
        },
      });
    else
      sendLoginRequest({
        data: { clientId: loginData.clientId, pin: loginData.pin },
      });
  };

  const login = () => {
    const data: LoginData = {
      clientId: participantId.trim(),
      pin: pin.trim(),
      pin_confirmation: confirmPin.trim(),
      yearOfBirth: parseInt(yearOfBirth),
      isNewUser: isNewUser,
    };

    const loginDataToSave = JSON.parse(JSON.stringify(data));
    loginDataToSave.userType = userType;
    delete loginDataToSave.pin;

    StorageManager.setLoginInformation(loginDataToSave);

    executeLoginAttempt(data);
  };

  const handleContinueClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    if (!isFormValid) {
      return;
    }

    login();
  };
  const [performingPostLoginActions, setPerformingPostLoginAction] =
    useState(false);

  function setPaymentMethods(country: string) {
    if (clientId === Client.Elligo) {
      dispatch(setAvailablePaymentMethods(vcPaymentMethods));
    } else if (country === "pr") {
      dispatch(setAvailablePaymentMethods(prPaymentMethods));
    } else if (country === "us") {
      dispatch(setAvailablePaymentMethods(usPaymentMethods));
    } else if (country === "gb") {
      dispatch(setAvailablePaymentMethods(gbPaymentMethods));
    } else {
      throw new Error("Unsupported country");
    }
  }

  const performPostLoginActions = async () => {
    try {
      setPerformingPostLoginAction(true);

      await queryClient.prefetchQuery({
        queryKey: getListAllLanguagesQueryKey(),
        queryFn: () => listAllLanguages(),
      });

      const participantDetailsQuery = await queryClient.fetchQuery({
        queryKey: getGetParticipantAccountDetailsQueryKey(),
        queryFn: () =>
          getParticipantAccountDetails({
            params: {
              include:
                "language,arm,site,arm.visitSchedule.study,site.country,arm.visitSchedule.study.defaultCurrency,paymentMethods",
            },
          }),
      });

      if (!participantDetailsQuery.data) return;

      queryClient.setQueryData(
        getGetParticipantAccountDetailsQueryKey(),
        participantDetailsQuery
      );

      const country = participantDetailsQuery.included
        ?.find((item: { type: string }) => item.type === "countries")
        ?.attributes.code.toLowerCase();

      setPaymentMethods(country);

      const paymentMethods = participantDetailsQuery.included?.filter(
        (item: { type: string }) => item.type === "payment-methods"
      ) as PaymentMethodJsonApiBlockWithId[];

      const defaultPaymentMethod = paymentMethods.find(
        (method) => method.attributes.default
      );

      setPerformingPostLoginAction(false);
      if (defaultPaymentMethod?.id) {
        navigate(callbackURL, { replace: true });
      } else {
        navigate(AppRoute.PAYMENT_METHODS_LIST, { replace: true });
      }
    } catch (error) {
      console.log("Failed to execute post login actions", error);
    }
  };

  const cancelRelogin = () => {
    StorageManager.removeLoginInformation();

    setIsNewUser(true);
    setTermsAccepted(false);
    setUserType("participant");
    setParticipantId("");
    setYearOfBirth("");
    setPin("");
    navigate("/login");
  };

  const theme = useTheme();
  const isFailed = isLoginFailed || isOnboardFailed;
  const termsLinks = getLegalDocumentLinks("terms");
  const privacyPolicyLinks = loginPagePrivacyPolicyEnabled
    ? getLegalDocumentLinks("privacy", { clientId })
    : null;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ maxWidth: 800, margin: "auto" }}
    >
      <img
        data-test-id="logo"
        src={clientLoginLogo}
        alt={`${clientId} Logo`}
        style={{ height: clientTheme.logoHeight, marginBottom: "16px" }}
      />

      <Grid>
        <CardContainer>
          <CardContainer.Header>
            <CardContainer.HeaderContent>
              <CardContainer.HeaderTextItem>
                {!isInactivityRedirect
                  ? t("virtualCard_continueButtonTitle")
                  : t("session_expired")}
              </CardContainer.HeaderTextItem>
              <CardContainer.HeaderTextItem>
                {t("payment_dynamic_description")}
              </CardContainer.HeaderTextItem>
            </CardContainer.HeaderContent>
            <CardContainer.TopRightContent>
              <LanguageButton color={theme.palette.text.secondary} />
            </CardContainer.TopRightContent>
          </CardContainer.Header>

          <CardContainer.Content>
            {isFailed && <FormErrorBox error={error} />}

            <form>
              {!isInactivityRedirect && (
                <>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel
                      data-test-id="new-or-existing-label"
                      component="legend"
                    >
                      {t("login_new_or_existing")}
                    </FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        data-test-id="new-user"
                        control={
                          <Radio
                            checked={isNewUser}
                            onChange={() => setIsNewUser(true)}
                          />
                        }
                        label={t("auth_register", {
                          client: clientName,
                        })}
                      />
                      <FormControlLabel
                        data-test-id="existing-user"
                        control={
                          <Radio
                            checked={!isNewUser}
                            onChange={() => setIsNewUser(false)}
                          />
                        }
                        label={t("auth_signIn")}
                      />
                    </FormGroup>
                  </FormControl>

                  <TextField
                    data-test-id="participant-textfield"
                    label={t("auth_participantIdNumber")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={participantId}
                    name="username"
                    onChange={(e) => setParticipantId(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />

                  {isNewUser && (
                    <TextField
                      data-test-id="dateOfBirth-textfield"
                      label={t("auth_accountActivation_dateOfBirth")}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={yearOfBirth}
                      name="year_of_birth"
                      onChange={(e) => setYearOfBirth(e.target.value)}
                      inputProps={{ maxLength: 4 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                </>
              )}

              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  {isNewUser ? t("auth_pin") : t("enterPin_title")}
                </InputLabel>

                <OutlinedInput
                  onChange={(e) => setPin(e.target.value)}
                  value={pin}
                  data-test-id="pin-textfield"
                  id="outlined-adornment-password"
                  type={showPIN ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPIN}
                        edge="end"
                      >
                        {showPIN ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={isNewUser ? t("auth_pin") : t("enterPin_title")}
                />
              </FormControl>

              {isNewUser && (
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-confirm-password">
                    {t("confirmPin_title")}
                  </InputLabel>

                  <OutlinedInput
                    onChange={(e) => setConfirmPin(e.target.value)}
                    value={confirmPin}
                    data-test-id="confirm-pin-textfield"
                    id="outlined-adornment-confirm-password"
                    type={showPIN ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPIN}
                          edge="end"
                        >
                          {showPIN ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={t("confirmPin_title")}
                  />
                </FormControl>
              )}
              <Stack direction="column">
                {!isInactivityRedirect && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-test-id="accept-tc-checkbox"
                        checked={termsAccepted}
                        onChange={handleTermsChange}
                        name="termsAndConditions"
                        color="primary"
                      />
                    }
                    label={
                      <Typography
                        data-test-id="accept-tc-label"
                        variant="subtitle1"
                      >
                        {t("accept")} &nbsp;
                        <Typography
                          data-test-id="accept-tc-link"
                          variant="subtitle1"
                          component={Link}
                          onClick={(e) => {
                            e.preventDefault();
                            handleOpenTermsDialog();
                          }}
                        >
                          {t("terms_termsAndConditions")}.
                        </Typography>
                      </Typography>
                    }
                  />
                )}

                {loginPagePrivacyPolicyEnabled && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-test-id="accept-privacy-checkbox"
                        checked={privacyPolicyAccepted}
                        onChange={(event) => {
                          setPrivacyPolicyAccepted(event.target.checked);
                        }}
                        name="privacyPolicy"
                        color="primary"
                      />
                    }
                    label={
                      <Typography
                        data-test-id="accept-privacy-label"
                        variant="subtitle1"
                      >
                        {t("accept")} &nbsp;
                        <Typography
                          data-test-id="accept-privacy-link"
                          variant="subtitle1"
                          component={Link}
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenPrivacyPolicyDialog(true);
                          }}
                        >
                          {t("client_privacy", { client: clientName })}
                        </Typography>
                      </Typography>
                    }
                  />
                )}
              </Stack>

              <LoadingButton
                data-test-id="submit-button"
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                sx={{ mt: 2, mb: 2 }}
                loading={
                  isLoginPending ||
                  isOnboardPending ||
                  performingPostLoginActions
                }
                onClick={handleContinueClick}
                disabled={!isFormValid} // Button will be disabled if form is not valid
              >
                {t("continue_text")}
              </LoadingButton>

              {isNewUser && (
                <ForgetPinButton title={t("participantIdHelp_subtitle")} />
              )}

              {(isInactivityRedirect || !isNewUser) && <ForgetPinButton />}

              {isInactivityRedirect && (
                <Button
                  data-test-id="cancel-button"
                  fullWidth
                  onClick={() => cancelRelogin()}
                  sx={{
                    color: "red",
                  }}
                >
                  {t("cancel")}
                </Button>
              )}
            </form>
          </CardContainer.Content>
        </CardContainer>
      </Grid>

      {/* Terms and Conditions Dialog */}
      <ConfirmationDialog
        label="tc-dialog"
        maxWidth="md"
        fullWidth={true}
        open={openTermsDialog}
        title={t("terms_termsAndConditions")}
        message={
          <span
            style={{
              padding: 0,
              height: "60vh",
              overflow: "hidden",
              display: "block",
            }}
          >
            <TermsAndConditions />
          </span>
        }
        ActionsComponent={
          <Button
            data-test-id="terms-and-conditions-dialog-action-button"
            onClick={handleDownloadPdf(termsLinks.pdf)}
            startIcon={<GetAppIcon />}
          >
            {t("button_download_pdf")}
          </Button>
        }
        cancelButtonText={t("button_close")}
        onDismiss={handleCloseTermsDialog}
        hideConfirmation={true}
      />

      {/* Privacy Policy Dialog */}
      {loginPagePrivacyPolicyEnabled && privacyPolicyLinks && (
        <ConfirmationDialog
          label="privacy-policy-dialog"
          maxWidth="md"
          fullWidth={true}
          open={openPrivacyPolicyDialog}
          title={t("client_privacy", { client: clientName })}
          message={
            <span
              style={{
                padding: 0,
                height: "60vh",
                overflow: "hidden",
                display: "block",
              }}
            >
              <PrivacyPolicy clientId={clientId} />
            </span>
          }
          ActionsComponent={
            <Button
              data-test-id="privacy-policy-dialog-action-button"
              onClick={handleDownloadPdf(privacyPolicyLinks.pdf)}
              startIcon={<GetAppIcon />}
            >
              {t("button_download_pdf")}
            </Button>
          }
          cancelButtonText={t("button_close")}
          onDismiss={() => setOpenPrivacyPolicyDialog(false)}
          hideConfirmation={true}
        />
      )}

      {/* Study ID Dialog */}
      <StudyIDDialog
        open={openStudyIDDialog}
        onClose={closeStudyIDDialog}
        studyID={studyID}
        setStudyID={setStudyID}
        onSubmit={handleStudyIDSubmit}
      />

      {/* Change PIN Dialog */}

      <Dialog
        open={requiresPinChange}
        fullWidth
        maxWidth="sm"
        disableEscapeKeyDown
        onClose={(reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setRequiresPinChange(false);
          }
        }}
      >
        <ChangePINPage
          isDialogMode={true}
          onSuccess={() => {
            setRequiresPinChange(false);
            performPostLoginActions();
          }}
        />
      </Dialog>
    </Box>
  );
}
