import type React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import CardContainer from "src/pages/CardContainer";
import { useTranslation } from "react-i18next";
import {
  isVirtualCard,
  PaymentMethod,
  PaymentMethodType,
} from "../types/payment-method-types";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { resetForm } from "src/redux/slices/paymentMethodFormSlice";
import { showToast } from "src/redux/slices/toastSlice";
import { usePaymentMethodForms } from "../contexts/payment-method-form-context";
import { AppRoute } from "src/types/routes";
import { usePaymentMethods } from "src/common/hooks/usePaymentMethods";

const PaymentMethodsPage: React.FC = () => {
  const { t } = useTranslation();
  const {
    setDefaultPaymentMethod,
    isSettingDefaultPaymentMethod,
    goToNextPage,
  } = usePaymentMethodForms();
  const dispatch = useDispatch();

  const paymentMethods = useSelector(
    (state: RootState) => state.paymentMethods.availablePaymentMethods
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethodType>();

  const { virtualCard } = usePaymentMethods();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetForm());
  }, []);

  const handlePaymentMethodChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedPaymentMethod(event.target.value as PaymentMethodType);
  };

  const handleCreateNewPaymentAccount = async () => {
    if (!selectedPaymentMethod) return;

    const currentPaymentAccountId = virtualCard?.id;
    if (isVirtualCard(selectedPaymentMethod)) {
      if (currentPaymentAccountId) {
        setDefaultPaymentMethod(currentPaymentAccountId, onSuccess);
      } else {
        navigate(AppRoute.CARD_ONBOARDING);
      }
    } else {
      navigate(`/payment-methods/${selectedPaymentMethod}/create`);
    }
  };

  function onSuccess() {
    dispatch(showToast(t("paymentMethod_updated")));
    goToNextPage();
  }

  function accountDescription(method: PaymentMethod) {
    if (isVirtualCard(method.type) && virtualCard) {
      const identifier = virtualCard.attributes?.cardNumber;

      return t("claimDetail_vccDescription", {
        "0": identifier?.slice(-4) ?? "****",
      });
    }

    return method.title;
  }

  function content() {
    return (
      <>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="payment method"
            name="payment-method"
            value={selectedPaymentMethod}
            onChange={handlePaymentMethodChange}
          >
            {paymentMethods.map((method) => (
              <FormControlLabel
                key={method.id}
                value={method.id}
                control={<Radio />}
                label={accountDescription(method)}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Box mt={3}>
          <LoadingButton
            variant="contained"
            fullWidth
            color="primary"
            size="large"
            onClick={handleCreateNewPaymentAccount}
            loading={isSettingDefaultPaymentMethod}
            disabled={!selectedPaymentMethod || isSettingDefaultPaymentMethod}
          >
            {t("continue_text")}
          </LoadingButton>
        </Box>
      </>
    );
  }

  return (
    <CardContainer>
      <CardContainer.Header>
        <CardContainer.HeaderContent>
          <CardContainer.HeaderTextItem>
            {t("payment_dynamic_title")}
          </CardContainer.HeaderTextItem>
          <CardContainer.HeaderTextItem>
            {t("payment_description")}
          </CardContainer.HeaderTextItem>
        </CardContainer.HeaderContent>
      </CardContainer.Header>

      <CardContainer.Content>{content()}</CardContainer.Content>
    </CardContainer>
  );
};

export default PaymentMethodsPage;
