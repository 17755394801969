import {
  PaymentMethod,
  PaymentMethodType,
  Form,
} from "../types/payment-method-types";

const addressForm: Form = {
  title: "Address Information",
  name: "address",
  type: "address",
  fields: [
    {
      type: "search",
      name: "search",
      label: "Search address",
      required: false,
    },
    {
      type: "address_lineOne",
      name: "lineOne",
      label: "House name or number and street",
      required: true,
      minLength: 2,
      maxLength: 100,
    },
    {
      type: "address_city",
      name: "city",
      label: "City",
      required: true,
      minLength: 2,
      maxLength: 40,
    },
    {
      type: "address_state",
      name: "state",
      label: "State",
      required: true,
      defaultValue: "PR",
      disabled: true,
      options: [
        {
          key: "PR",
          value: "Puerto Rico",
        },
      ],
    },
    {
      type: "address_zip",
      name: "zip",
      label: "ZIP Code",
      required: true,
      minLength: 1,
      maxLength: 10,
    },
  ],
};

export const prPaymentMethods: PaymentMethod[] = [
  {
    id: PaymentMethodType.VirtualCard,
    title: "Virtual Card",
    type: PaymentMethodType.VirtualCard,
    country: "PR",
    form: {
      title: "Virtual Card Information",
      name: "virtual-card",
      type: "object",
      fields: [
        {
          type: "name",
          name: "givenName",
          label: "Given Name",
          description: "Your given name",
          required: true,
        },
        {
          type: "name",
          name: "middleName",
          label: "Middle Name",
          description: "Your middle name",
          required: false,
        },
        {
          type: "name",
          name: "familyName",
          label: "Family Name",
          description: "Your family name",
          required: true,
        },
        {
          type: "tel",
          name: "phone",
          label: "Phone",
          required: true,
        },
        {
          type: "email",
          name: "email",
          label: "Email",
          required: true,
          minLength: 3,
          maxLength: 200,
          pattern: "(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$)",
        },
      ],
      then: addressForm,
    },
    endpoint: "/v2/payment-methods/vc",
  },
  {
    id: PaymentMethodType.BankAccount,
    title: "Bank Account",
    type: PaymentMethodType.BankAccount,
    country: "PR",
    form: {
      title: "Bank Account Information",
      name: "ach-out",
      type: "object",
      fields: [
        {
          type: "name",
          name: "fullName",
          label: "First and Last Name",
          required: true,
          minLength: 2,
          maxLength: 140,
          pattern:
            "^[a-zA-Z0-9_'.-]{2,}\\s+[a-zA-Z0-9_'.-]{2,}(\\s+[a-zA-Z0-9_'.-]{2,})*$",
          description:
            "Enter your first and last name as it appears on your bank account",
        },
        {
          type: "string",
          name: "routing",
          label: "Routing Number",
          required: true,
          minLength: 9,
          maxLength: 9,
          pattern: "^[0-9]+$",
        },
        {
          type: "string",
          name: "account",
          label: "Account Number",
          required: true,
          minLength: 4,
          maxLength: 17,
          pattern: "^[0-9]+$",
        },
        {
          type: "radio",
          name: "type",
          label: "Account type",
          required: true,
          options: [
            { key: "Checking", value: "Checking" },
            { key: "Savings", value: "Savings" },
          ],
        },
      ],
      then: addressForm,
    },
    endpoint: "/v1/payment-methods/bank-account",
  },
];
