import React from "react";
import { TextField } from "@mui/material";
import { FormControlProps, BaseFormControl } from "./BaseFormControl";
import { useFormContext } from "react-hook-form";

export const EmailFormControl: React.FC<FormControlProps> = ({
  field,
  name,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const defaultValue =
    field.defaultValue !== undefined ? field.defaultValue : "";

  return (
    <BaseFormControl field={field} name={name}>
      <TextField
        {...register(name, { value: defaultValue })}
        fullWidth
        type="email"
        label={field.label}
        placeholder={field.placeholder}
        error={!!errors[name]}
        helperText={field.description}
        disabled={field.disabled}
      />
    </BaseFormControl>
  );
};
