import AirlinesIcon from "@mui/icons-material/Airlines";
import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePageTitle } from "../common/TitleContext";
import { RootState } from "../redux/store";
import CardContainer from "./CardContainer";
import { calculateMileageRate } from "../services/payment";
import { useUserDetails } from "src/common/hooks/useUserDetails";
function MileageDistancePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  usePageTitle(t("claim_chooseClaimMileage"));
  const { distanceUnit, site } = useUserDetails();
  const travelRateValue = site?.attributes.travelRateValue;

  const previouslyInputedDistance = useSelector((state: RootState) => {
    if (state.claims.mileageClaim?.items?.length) {
      // If there are multiple rates on different distances, the input gets split to multiple items
      // Here we aggregate the total value
      return state.claims.mileageClaim?.items.reduce(
        (a, x) => a + x.distance,
        0
      );
    }
  });

  const [amount, setAmount] = useState(
    previouslyInputedDistance?.toString() || ""
  );
  const isContinueButtonDisabled = () => {
    const amountNumber = parseFloat(amount);

    return !(amountNumber > 0);
  };

  const onContinue = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const distance = parseFloat(amount);
    const rate = travelRateValue && parseFloat(travelRateValue);
    if (isNaN(distance)) throw Error(`No distance found ${distance}`);

    if (!rate) throw Error(`No rate found ${rate}`);

    calculateMileageRate(distance, distanceUnit, rate);
    navigate("/payment/submit/mileage/summary");
  };

  return (
    <CardContainer>
      <CardContainer.Header>
        <CardContainer.HeaderContent>
          <CardContainer.HeaderTextItem>
            {t("mileagesCount_HeaderTitle")}
          </CardContainer.HeaderTextItem>
          <CardContainer.HeaderTextItem>
            {t("mileagesCount_HeaderSubTitle")}
          </CardContainer.HeaderTextItem>
        </CardContainer.HeaderContent>
      </CardContainer.Header>
      <CardContainer.Content>
        <form>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel
              data-test-id="amount-label"
              htmlFor="outlined-adornment-amount"
            >
              {t("distance")}
            </InputLabel>

            <OutlinedInput
              data-test-id="amount-input"
              id="outlined-adornment-amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              type="number"
              inputProps={{
                min: 0,
              }}
              startAdornment={
                <InputAdornment
                  data-test-id="amount-start-adornment"
                  position="start"
                >
                  <AirlinesIcon></AirlinesIcon>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment
                  data-test-id="amount-end-adornment"
                  position="end"
                >
                  {t(distanceUnit)}
                </InputAdornment>
              }
              label={t("distance")}
            />
          </FormControl>

          <LoadingButton
            data-test-id="submit-button"
            sx={{ textTransform: "initial", fontWeight: "bold", mt: 2 }}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            fullWidth
            disabled={isContinueButtonDisabled()}
            onClick={onContinue}
          >
            {t("continue_text")}
          </LoadingButton>
        </form>
      </CardContainer.Content>
    </CardContainer>
  );
}

export default MileageDistancePage;
