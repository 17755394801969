import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/redux/store";
import { Box, Typography } from "@mui/material";

import AppHeader from "../components/AppHeader/AppHeader";
import AppMenu from "../components/AppMenu";
import { useTitle } from "../common/TitleContext";
import { setIsCardAgreementDialogOpen } from "src/redux/slices/cardAgreementDialogSlice";
import { usePaymentMethods } from "src/common/hooks/usePaymentMethods";
import { CardAgreementDialog } from "src/components/CardAgreementDialog";
import {
  getPaymentMethodsQueryKey,
  useUpdatePaymentMethodActionById,
} from "src/api/client/payment-methods/payment-methods";
import { useQueryClient } from "@tanstack/react-query";

const styles = {
  pageTitle: {
    textAlign: "left",
    mb: 2,
    fontWeight: "bold",
    color: "primary.main",
  },
  container: {
    display: "flex",
    height: "calc(100% - 64px)",
  },
  mainSection: {
    p: 4,
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
};

interface LayoutProps {
  isAuthenticated: boolean;
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ isAuthenticated, children }) => {
  const { title } = useTitle();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const cardAgreementDialogOpen = useSelector(
    (state: RootState) => state.cardAgreementDialog.isOpen
  );
  const setCardAgreementDialogOpen = (isOpen: boolean) => {
    dispatch(setIsCardAgreementDialogOpen({ isOpen }));
  };
  const { virtualCard, paymentMethodsLoading } = usePaymentMethods();
  const { mutate: updatePaymentMethod } = useUpdatePaymentMethodActionById({
    mutation: {
      onSuccess: async () => {
        setCardAgreementDialogOpen(false);
        await queryClient.invalidateQueries({
          queryKey: getPaymentMethodsQueryKey(),
        });
      },
    },
  });

  const onAcceptCardAgreement = () => {
    if (!virtualCard) {
      return;
    }

    updatePaymentMethod({
      id: virtualCard.id!,
      data: {
        data: {
          id: virtualCard.id!,
          type: "payment-methods",
          attributes: {
            ...virtualCard.attributes,
            hasAcceptedCardholderAgreement: true,
          },
        },
      },
    });
  };

  useEffect(() => {
    if (isAuthenticated && !paymentMethodsLoading && virtualCard) {
      if (!virtualCard.attributes.hasAcceptedCardholderAgreement) {
        setCardAgreementDialogOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtualCard, paymentMethodsLoading, isAuthenticated]);

  return (
    <>
      {isAuthenticated ? (
        <AppHeader
          onSidebarCollapse={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
        />
      ) : null}

      <Box sx={isAuthenticated ? styles.container : null}>
        {isAuthenticated ? (
          <AppMenu
            collapsed={isSidebarCollapsed}
            onSidebarCollapse={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
          />
        ) : null}

        <Box component={"main"} sx={styles.mainSection}>
          {title.length > 0 && (
            <Typography
              data-test-id="navbar-title"
              variant="h4"
              component="h1"
              sx={styles.pageTitle}
            >
              {title}
            </Typography>
          )}

          {children}
        </Box>
      </Box>
      <CardAgreementDialog
        open={cardAgreementDialogOpen}
        dismissible={false}
        onAccept={onAcceptCardAgreement}
      />
    </>
  );
};

export default Layout;
