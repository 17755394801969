import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import Layout from "src/containers/Layout";
import { selectIsAuthenticated } from "src/redux/slices/authSlice";

const PrivateRoutes = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const location = useLocation();

  return isAuthenticated ? (
    <Layout isAuthenticated={true}>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

export default PrivateRoutes;
