import { useEffect, useState } from "react";

export const useCountdown = (timeRemainingMilliseconds: number) => {
  const [countDown, setCountDown] = useState(timeRemainingMilliseconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDown - 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, [countDown]);

  const reset = () => {
    setCountDown(timeRemainingMilliseconds);
  };

  return {
    timeLeftValues: getReturnValues(countDown),
    reset: reset,
  };
};

const getReturnValues = (countDown: number) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));

  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));

  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};
