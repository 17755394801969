/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Self Service API
 * An API which allows a client to interact with the self-service system.
This system exists to manage studies, track participants and oversee expenses.

This API conforms to the JSON:API specification. You can find the specification, [here](https://jsonapi.org/format/).

JSON:API allows clients to pull relationship data within all endpoints using the `include` query parameter.
When this parameter is provided, that relation will be found within the root `included` array located at the end of
each response. 

When updating resources via a `PUT` request, no partial updates are allowed. Please ensure to send all `attributes`
and `relationships`. The only exception to this rule are computed fields - you may omit these from your request body.

This API implements soft deletes for all entities and the soft delete filter is enabled for all endpoints. By
default, no soft delete entities will be shown within list endpoints unless specifically requested. See individual
endpoints for more information.

 * OpenAPI spec version: 0.1.0-alpha
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  LanguageCreateRequestBody,
  LanguageCreateResponseResponse,
  LanguageDeleteResponseResponse,
  LanguageIndexResponseResponse,
  LanguageReadResponseResponse,
  LanguageRestoreResponseResponse,
  LanguageUpdateRequestBody,
  LanguageUpdateResponseResponse,
} from "../../model/resources-languages.yml";
import type {
  BadRequestErrorResponseResponse,
  CreateNewLanguageParams,
  ListAllLanguagesParams,
  NotFoundErrorResponseResponse,
  ReadLanguageByIdParams,
  RestoreLanguageByIdParams,
  ServerErrorResponseResponse,
  UpdateLanguageByIdParams,
  ValidationErrorResponseResponse,
} from "../../model";
import { nmibleInstance } from "../../mutator/axios-instance";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `languages.index` permission.

# Extra information
- This endpoint will return a paginated list of languages.

 * @summary List all languages.
 */
export const listAllLanguages = (
  params?: ListAllLanguagesParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<LanguageIndexResponseResponse>(
    { url: `/languages`, method: "GET", params, signal },
    options
  );
};

export const getListAllLanguagesQueryKey = (
  params?: ListAllLanguagesParams
) => {
  return [`/languages`, ...(params ? [params] : [])] as const;
};

export const getListAllLanguagesQueryOptions = <
  TData = Awaited<ReturnType<typeof listAllLanguages>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllLanguagesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllLanguages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListAllLanguagesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listAllLanguages>>
  > = ({ signal }) => listAllLanguages(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAllLanguages>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ListAllLanguagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAllLanguages>>
>;
export type ListAllLanguagesQueryError =
  | BadRequestErrorResponseResponse
  | ServerErrorResponseResponse;

export function useListAllLanguages<
  TData = Awaited<ReturnType<typeof listAllLanguages>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params: undefined | ListAllLanguagesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllLanguages>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllLanguages>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useListAllLanguages<
  TData = Awaited<ReturnType<typeof listAllLanguages>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllLanguagesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllLanguages>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllLanguages>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useListAllLanguages<
  TData = Awaited<ReturnType<typeof listAllLanguages>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllLanguagesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllLanguages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary List all languages.
 */

export function useListAllLanguages<
  TData = Awaited<ReturnType<typeof listAllLanguages>>,
  TError = BadRequestErrorResponseResponse | ServerErrorResponseResponse
>(
  params?: ListAllLanguagesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof listAllLanguages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getListAllLanguagesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `languages.create` permission.

# Extra information
- This endpoint will return the newly created language.

 * @summary Create a new language.
 */
export const createNewLanguage = (
  languageCreateRequestBody: LanguageCreateRequestBody,
  params?: CreateNewLanguageParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<LanguageCreateResponseResponse>(
    {
      url: `/languages`,
      method: "POST",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: languageCreateRequestBody,
      params,
      signal,
    },
    options
  );
};

export const getCreateNewLanguageMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNewLanguage>>,
    TError,
    { data: LanguageCreateRequestBody; params?: CreateNewLanguageParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createNewLanguage>>,
  TError,
  { data: LanguageCreateRequestBody; params?: CreateNewLanguageParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createNewLanguage>>,
    { data: LanguageCreateRequestBody; params?: CreateNewLanguageParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return createNewLanguage(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateNewLanguageMutationResult = NonNullable<
  Awaited<ReturnType<typeof createNewLanguage>>
>;
export type CreateNewLanguageMutationBody = LanguageCreateRequestBody;
export type CreateNewLanguageMutationError =
  | BadRequestErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Create a new language.
 */
export const useCreateNewLanguage = <
  TError =
    | BadRequestErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNewLanguage>>,
    TError,
    { data: LanguageCreateRequestBody; params?: CreateNewLanguageParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createNewLanguage>>,
  TError,
  { data: LanguageCreateRequestBody; params?: CreateNewLanguageParams },
  TContext
> => {
  const mutationOptions = getCreateNewLanguageMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `languages.read` permission.

# Extra information
- This endpoint will the requested language.

 * @summary Read language by ID.
 */
export const readLanguageById = (
  id: string,
  params?: ReadLanguageByIdParams,
  options?: SecondParameter<typeof nmibleInstance>,
  signal?: AbortSignal
) => {
  return nmibleInstance<LanguageReadResponseResponse>(
    { url: `/languages/${id}`, method: "GET", params, signal },
    options
  );
};

export const getReadLanguageByIdQueryKey = (
  id: string,
  params?: ReadLanguageByIdParams
) => {
  return [`/languages/${id}`, ...(params ? [params] : [])] as const;
};

export const getReadLanguageByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof readLanguageById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadLanguageByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readLanguageById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getReadLanguageByIdQueryKey(id, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof readLanguageById>>
  > = ({ signal }) => readLanguageById(id, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof readLanguageById>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ReadLanguageByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof readLanguageById>>
>;
export type ReadLanguageByIdQueryError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ServerErrorResponseResponse;

export function useReadLanguageById<
  TData = Awaited<ReturnType<typeof readLanguageById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params: undefined | ReadLanguageByIdParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readLanguageById>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof readLanguageById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useReadLanguageById<
  TData = Awaited<ReturnType<typeof readLanguageById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadLanguageByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readLanguageById>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof readLanguageById>>,
          TError,
          TData
        >,
        "initialData"
      >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useReadLanguageById<
  TData = Awaited<ReturnType<typeof readLanguageById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadLanguageByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readLanguageById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Read language by ID.
 */

export function useReadLanguageById<
  TData = Awaited<ReturnType<typeof readLanguageById>>,
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ServerErrorResponseResponse
>(
  id: string,
  params?: ReadLanguageByIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof readLanguageById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof nmibleInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getReadLanguageByIdQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `languages.update` permission.

# Extra information
- This endpoint will not accept partial updates - please [see](https://jsonapi.org/format/#:~:text=A%20request%20MUST%20completely%20succeed%20or%20fail%20(in%20a%20single%20%E2%80%9Ctransaction%E2%80%9D).%20No%20partial%20updates%20are%20allowed.).
- This endpoint will return the updated language.

 * @summary Update language by ID.
 */
export const updateLanguageById = (
  id: string,
  languageUpdateRequestBody: LanguageUpdateRequestBody,
  params?: UpdateLanguageByIdParams,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<LanguageUpdateResponseResponse>(
    {
      url: `/languages/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/vnd.api+json" },
      data: languageUpdateRequestBody,
      params,
    },
    options
  );
};

export const getUpdateLanguageByIdMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateLanguageById>>,
    TError,
    {
      id: string;
      data: LanguageUpdateRequestBody;
      params?: UpdateLanguageByIdParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateLanguageById>>,
  TError,
  {
    id: string;
    data: LanguageUpdateRequestBody;
    params?: UpdateLanguageByIdParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateLanguageById>>,
    {
      id: string;
      data: LanguageUpdateRequestBody;
      params?: UpdateLanguageByIdParams;
    }
  > = (props) => {
    const { id, data, params } = props ?? {};

    return updateLanguageById(id, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateLanguageByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateLanguageById>>
>;
export type UpdateLanguageByIdMutationBody = LanguageUpdateRequestBody;
export type UpdateLanguageByIdMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Update language by ID.
 */
export const useUpdateLanguageById = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateLanguageById>>,
    TError,
    {
      id: string;
      data: LanguageUpdateRequestBody;
      params?: UpdateLanguageByIdParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateLanguageById>>,
  TError,
  {
    id: string;
    data: LanguageUpdateRequestBody;
    params?: UpdateLanguageByIdParams;
  },
  TContext
> => {
  const mutationOptions = getUpdateLanguageByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `languages.delete` permission.

# Extra information
- This endpoint will return a paginated list of arms.

 * @summary Delete a language by ID.
 */
export const deleteLanguageById = (
  id: string,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<LanguageDeleteResponseResponse>(
    { url: `/languages/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteLanguageByIdMutationOptions = <
  TError =
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteLanguageById>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteLanguageById>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteLanguageById>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteLanguageById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteLanguageByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteLanguageById>>
>;

export type DeleteLanguageByIdMutationError =
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Delete a language by ID.
 */
export const useDeleteLanguageById = <
  TError =
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteLanguageById>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteLanguageById>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteLanguageByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `languages.restore` permission.

# Extra information
- This endpoint requires no request body.
- This endpoint will return the restored language.

 * @summary Restore a language by ID.
 */
export const restoreLanguageById = (
  id: string,
  params?: RestoreLanguageByIdParams,
  options?: SecondParameter<typeof nmibleInstance>
) => {
  return nmibleInstance<LanguageRestoreResponseResponse>(
    { url: `/languages/${id}/restore`, method: "PUT", params },
    options
  );
};

export const getRestoreLanguageByIdMutationOptions = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreLanguageById>>,
    TError,
    { id: string; params?: RestoreLanguageByIdParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof restoreLanguageById>>,
  TError,
  { id: string; params?: RestoreLanguageByIdParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof restoreLanguageById>>,
    { id: string; params?: RestoreLanguageByIdParams }
  > = (props) => {
    const { id, params } = props ?? {};

    return restoreLanguageById(id, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RestoreLanguageByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof restoreLanguageById>>
>;

export type RestoreLanguageByIdMutationError =
  | BadRequestErrorResponseResponse
  | NotFoundErrorResponseResponse
  | ValidationErrorResponseResponse
  | ServerErrorResponseResponse;

/**
 * @summary Restore a language by ID.
 */
export const useRestoreLanguageById = <
  TError =
    | BadRequestErrorResponseResponse
    | NotFoundErrorResponseResponse
    | ValidationErrorResponseResponse
    | ServerErrorResponseResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreLanguageById>>,
    TError,
    { id: string; params?: RestoreLanguageByIdParams },
    TContext
  >;
  request?: SecondParameter<typeof nmibleInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof restoreLanguageById>>,
  TError,
  { id: string; params?: RestoreLanguageByIdParams },
  TContext
> => {
  const mutationOptions = getRestoreLanguageByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
