import { useEffect, useState } from "react";
import { Alert, AlertTitle, Button } from "@mui/material";
import CardContainer from "../CardContainer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TaxFormJsonApiBlockWithId } from "../../api/model/resources-tax-forms.yml";
import { useUserDetails } from "src/common/hooks/useUserDetails";
interface Props {
  taxForms: TaxFormJsonApiBlockWithId[];
  hasPendingStipends: boolean;
}

export default function TaxAlert({ taxForms, hasPendingStipends }: Props) {
  const { taxRequirement } = useUserDetails();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (!taxRequirement.workflow) return;

    const isIntegration = taxRequirement.workflow === "integration";
    const submittedTaxForms = taxForms.length > 0;
    const showAlert = isIntegration && !submittedTaxForms;
    setShowAlert(showAlert);
  }, [taxForms, taxRequirement]);

  if (!showAlert) return null;

  return (
    <CardContainer showBorder={true}>
      <CardContainer.Content>
        <Alert
          severity={hasPendingStipends ? "error" : "info"}
          variant="outlined"
          sx={{
            backgroundColor: "white",
            border: "none",
            px: 0,
          }}
          action={
            <Button
              data-test-id="tax-alert-button"
              onClick={() => navigate("/tax")}
              variant="contained"
              sx={{
                fontWeight: "bold",
                minWidth: "7rem",
              }}
              size="medium"
            >
              {t("findOutMore")}
            </Button>
          }
        >
          <AlertTitle
            data-test-id="tax-alert-description"
            sx={{ fontWeight: "bold" }}
          >
            {hasPendingStipends
              ? t("tax_required_msg")
              : t("tax_requiresTaxInfo")}
          </AlertTitle>
        </Alert>
      </CardContainer.Content>
    </CardContainer>
  );
}
