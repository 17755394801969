import { AxiosError } from "axios";
import {
  Box,
  Card,
  CardContent,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { listAllExpenses } from "src/api/client/expenses/expenses";
import { useListAllTaxForms } from "src/api/client/tax-forms/tax-forms";
import { listAllStipends } from "src/api/model/stipends";
import { getPageNumber, hasCompletedTaxForm } from "src/common/helpers";
import LoadMoreButton from "src/components/LoadMoreButton";
import SubmitClaimButton from "src/components/Payment/SubmitClaimButton";
import StorageManager from "src/services/storage";
import { StipendIndexResponseResponseIncluded } from "src/api/model/resources-stipends.yml";
import { AppRoute } from "src/types/routes";
import { ExpenseIndexResponseResponse } from "src/api/model/resources-expenses.yml";
import { ErrorCard } from "src/components/ErrorCard";
import type { ServerError } from "src/api/model";
import { parseError } from "src/common/errorHandler";
import ManageCard from "../card/ManageCard";
import PendingStipendsList from "../PendingStipendsList";
import ProtectYourIdentity from "../ProtectYourIdentity";
import TaxAlert from "../Tax/TaxAlert";
import { mapExpensesData } from "./helpers";
import ExpensesList from "./components/ExpensesList";
import LatestPayment from "src/components/Payment/LatestPayment";
import { usePaymentMethods } from "src/common/hooks/usePaymentMethods";
import { useExpenseTypes } from "src/common/hooks/useExpenseTypes";
import { useUserDetails } from "src/common/hooks/useUserDetails";
import { useMicropayments } from "src/common/hooks/useMicropayments";

export const PaymentsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isProtectYourIdentityOpen, setProtectYourIdentityOpen] =
    useState(false);

  const { isTaxCompliant } = useUserDetails();

  const {
    defaultPaymentMethod,
    defaultPaymentMethodId,
    paymentMethodsLoading,
    virtualCard,
    paymentMethodsFetching,
    paymentMethods,
  } = usePaymentMethods();

  const {
    data: taxFormsData,
    isPending: isLoadingTaxForms,
    error: taxFormsError,
  } = useListAllTaxForms();
  const completedTaxForm =
    isTaxCompliant || hasCompletedTaxForm(taxFormsData?.data ?? []);
  const {
    micropayments,
    fetchNextMicropaymentsPage,
    hasNextMicropaymentsPage,
    isFetchingMicropayments,
    isFetchingNextMicropaymentsPage,
    micropaymentsError,
  } = useMicropayments();

  const {
    data: stipendsPages,
    fetchNextPage: fetchNextStipendPage,
    hasNextPage: hasNextStipendPage,
    isFetching: isLoadingStipends,
    isFetchingNextPage: loadingNextStipends,
    error: stipendsError,
  } = useInfiniteQuery({
    queryKey: ["/stipends"],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await listAllStipends({
        "page[number]": pageParam.toString(),
        "page[size]": "10",
        include: "participantVisit",
      });

      return response;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const nextLink = lastPage?.links?.next;
      if (nextLink) {
        const pageNumber = getPageNumber(nextLink);
        if (pageNumber > allPages.length) return pageNumber;
      }
      return undefined;
    },
  });

  const stipendsData = stipendsPages?.pages.flatMap(
    (pageResponse) => pageResponse.data ?? []
  );
  const participantVisits = stipendsPages?.pages.flatMap(
    (pageResponse) =>
      // @ts-expect-error  included type should be an array
      pageResponse.included.filter(
        (item: StipendIndexResponseResponseIncluded) =>
          item.type === "participant-visits"
      ) ?? []
  );

  const { expenseTypesLoading, expenseTypesError } = useExpenseTypes();

  const {
    data: expensesData,
    error: expensesError,
    refetch: refetchExpenses,
    fetchNextPage,
    hasNextPage,
    isFetching: loadingExpenses,
    isFetchingNextPage: loadingNextExpenses,
  } = useInfiniteQuery({
    queryKey: ["expenses"],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await listAllExpenses({
        "page[number]": pageParam.toString(),
        "page[size]": "10",
        include: "receipt,expenseItems.expenseType",
      });
      return response;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const nextLink = lastPage?.links?.next;
      if (nextLink) {
        const pageNumber = getPageNumber(nextLink);
        if (pageNumber > allPages.length) return pageNumber;
      }
      return undefined;
    },
  });

  useEffect(() => {
    if (
      !paymentMethodsLoading &&
      paymentMethods &&
      paymentMethods.length === 0
    ) {
      navigate(AppRoute.PAYMENT_METHODS_LIST);
    }
  }, [defaultPaymentMethodId, paymentMethodsLoading]);

  function loadMoreData() {
    if (hasNextPage && !loadingExpenses) {
      fetchNextPage();
    }
  }
  function loadMoreStipendsData() {
    if (hasNextStipendPage && !isLoadingStipends) {
      fetchNextStipendPage();
    }
  }
  function loadMoreMicropaymentsData() {
    if (hasNextMicropaymentsPage && !isFetchingMicropayments) {
      fetchNextMicropaymentsPage();
    }
  }

  const onSubmitMileage = () => {
    navigate(AppRoute.MILEAGE_SUBMIT);
  };

  const onSubmitReceipt = () => {
    if (StorageManager.hideProtectYourIdentityMessage()) {
      navigate(AppRoute.RECEIPT_SUBMIT);
    } else {
      setProtectYourIdentityOpen(true);
    }
  };

  const isLoading = () =>
    (loadingExpenses && !loadingNextExpenses) ||
    (isLoadingStipends && !loadingNextStipends) ||
    (isFetchingMicropayments && !isFetchingNextMicropaymentsPage) ||
    expenseTypesLoading ||
    isLoadingTaxForms;

  const getError = () =>
    expensesError ||
    expenseTypesError ||
    micropaymentsError ||
    stipendsError ||
    taxFormsError;

  const onCloseProtectYourIdentity = () => {
    setProtectYourIdentityOpen(false);
  };

  const onContinueProtectYourIdentity = () => {
    setProtectYourIdentityOpen(false);
    navigate(AppRoute.RECEIPT_SUBMIT);
  };

  const error = getError();

  const renderExpensesSection = (
    isLoading: boolean,
    error: Error | ServerError | AxiosError | null,
    expensesData?: InfiniteData<ExpenseIndexResponseResponse | undefined>
  ) => {
    if (isLoading) {
      return (
        <Paper data-test-id="payments-skeleton" elevation={0}>
          <Skeleton
            variant="rounded"
            height={88}
            style={{ marginBottom: "10px" }}
          />
        </Paper>
      );
    }

    if (error) {
      const { title, message } = parseError(error);
      return (
        <ErrorCard
          title={t(title)}
          message={t(message)}
          buttonLabel={t("retry")}
          onButtonClick={() => refetchExpenses()}
        />
      );
    }

    if (
      !expensesData ||
      expensesData.pages.length === 0 ||
      expensesData.pages[0]?.data?.length === 0
    ) {
      return (
        <Card variant="outlined" sx={{ border: "none" }}>
          <CardContent>
            <Typography
              variant="body1"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "25vh",
                textAlign: "center",
              }}
            >
              {t("payments_empty")}
            </Typography>
          </CardContent>
        </Card>
      );
    }
    const expenses = mapExpensesData(expensesData);

    return (
      <>
        <ExpensesList data={expenses} />
        <LoadMoreButton
          hasMoreData={hasNextPage}
          loadingMoreData={loadingNextExpenses}
          onClick={loadMoreData}
        />
      </>
    );
  };

  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: "transparent",
        minHeight: "calc(100vh - 56px - 32px)",
        paddingBottom: "50px",
      }}
    >
      {virtualCard && <ManageCard virtualCard={virtualCard.attributes} />}

      {!isLoading() && !error && (
        <>
          <TaxAlert
            taxForms={taxFormsData?.data ?? []}
            hasPendingStipends={(stipendsData ?? []).length > 0}
          />
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: {
                xs: "flex-start",
                sm: "flex-end",
              },
            }}
          >
            <SubmitClaimButton
              onSubmitMileage={onSubmitMileage}
              onSubmitReceipt={onSubmitReceipt}
            />
          </Box>
          <PendingStipendsList
            dataType="stipend"
            completedTaxForm={completedTaxForm}
            data={stipendsData ?? []}
            participantVisits={participantVisits ?? []}
          />
          <LoadMoreButton
            hasMoreData={hasNextStipendPage}
            loadingMoreData={loadingNextStipends}
            onClick={loadMoreStipendsData}
          />
          <PendingStipendsList
            dataType="micropayment"
            completedTaxForm={completedTaxForm}
            data={micropayments}
            participantVisits={[]}
          />
          <LoadMoreButton
            hasMoreData={hasNextMicropaymentsPage}
            loadingMoreData={isFetchingNextMicropaymentsPage}
            onClick={loadMoreMicropaymentsData}
          />
        </>
      )}

      {isLoading() ? (
        <Skeleton
          variant="text"
          height={40}
          width={"30%"}
          sx={{
            mt: 2,
            mb: 1,
          }}
        />
      ) : (
        <Typography
          sx={{
            mt: 2,
            mb: 1,
          }}
          variant="subtitle2"
          color="text.secondary"
          fontWeight="600"
        >
          {t("payments")}
        </Typography>
      )}

      {renderExpensesSection(isLoading(), error, expensesData)}

      {defaultPaymentMethod && !paymentMethodsFetching && (
        <LatestPayment paymentAccount={defaultPaymentMethod.attributes} />
      )}

      <ProtectYourIdentity
        isOpen={isProtectYourIdentityOpen}
        onClose={onCloseProtectYourIdentity}
        onContinue={onContinueProtectYourIdentity}
      />
    </Paper>
  );
};
