import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { t } from "i18next";
import { TransformedVccCardType } from "../../types/common";
import { VirtualCard } from "../../types/paymentTypes";
import { PaymentMethodType } from "src/features/payment-methods/types/payment-method-types";
type VccState = {
  vccData: TransformedVccCardType | null;
  vccPaymentData: any | null;
};

export const RESET_STATE = "RESET_STATE";

const initialState: VccState = {
  vccData: null,
  vccPaymentData: null,
};

const vccSlice = createSlice({
  name: "VIRTUAL_CARD",
  initialState,
  reducers: {
    setVccData: (state, action: PayloadAction<VirtualCard>) => {
      state.vccData = {
        target_account_id: action.payload.target_account_id,
        target_account_type: PaymentMethodType.VirtualCard,
        target_account_title: t("virtualCard_title"),
        target_account_identifier: action.payload.card_number,
        balance: action.payload.balance,
        country_iso: action.payload.country_iso,
        currency: action.payload.currency,
      };
    },
    setVccPaymentData: (state, action: PayloadAction<any>) => {
      state.vccPaymentData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return initialState;
    });
  },
});

export const { setVccData, setVccPaymentData } = vccSlice.actions;

export default vccSlice.reducer;
