import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import * as ibantools from "ibantools";
import { FormControlProps, BaseFormControl } from "./BaseFormControl";
import { useFormContext } from "react-hook-form";

export const IbanFormControl: React.FC<FormControlProps> = ({
  field,
  name,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const defaultValue =
    field.defaultValue !== undefined ? field.defaultValue : "";

  // Set default value on mount if available
  useEffect(() => {
    if (defaultValue) {
      const formattedValue = ibantools.friendlyFormatIBAN(
        defaultValue.toString(),
        " "
      );
      setValue(name, formattedValue);
    }
  }, [defaultValue, name, setValue]);

  // Replace the registration code with this simpler version
  const registration = register(name);

  // Handle IBAN formatting
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    let position = target.selectionEnd;
    const length = target.value.length;

    // Mask the input
    const maskedValue = ibantools.friendlyFormatIBAN(target.value, " ");

    // Update form value
    setValue(name, maskedValue, { shouldValidate: true });

    // Fix cursor position
    if (position && position !== length) {
      target.selectionEnd =
        position +
        (target.value.charAt(position - 1) === " " &&
        target.value.charAt(length - 1) === " " &&
        length !== target.value.length
          ? 1
          : 0);
    }
  };

  return (
    <BaseFormControl field={field} name={name}>
      <TextField
        {...registration}
        fullWidth
        label={field.label}
        placeholder={field.placeholder}
        error={!!errors[name]}
        helperText={field.description}
        disabled={field.disabled}
        onChange={handleChange}
        onKeyDown={(event) => {
          if (event.key === " ") {
            event.preventDefault();
          }
        }}
      />
    </BaseFormControl>
  );
};
