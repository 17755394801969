import { Box, ListItemProps } from "@mui/material";
import { useTranslation } from "react-i18next";

import ListItemCompound from "../../../components/ExpenseListItem";
import { ExpenseRowData } from "../PaymentsPage.types";

interface ExpenseListItemProps extends ListItemProps {
  expense: ExpenseRowData;
  currencySymbol: string;
}

export default function ExpenseListItem({
  expense,
  currencySymbol,
  ...props
}: ExpenseListItemProps) {
  const { t } = useTranslation();
  const title = (type: string, id: string) => {
    switch (type.toLowerCase()) {
      case "reward":
        return `Reward - ${id}`;
      default:
        return `Expense - ${id}`;
    }
  };

  return (
    <ListItemCompound {...props}>
      <ListItemCompound.Content>
        <ListItemCompound.Icon type={expense.type} />

        <ListItemCompound.Text>
          <ListItemCompound.PrimaryText>
            {title(expense.type, expense.id)}
          </ListItemCompound.PrimaryText>
          <ListItemCompound.SecondaryText>
            {expense.createdAt}
          </ListItemCompound.SecondaryText>
          <ListItemCompound.Badge status={t(expense.status)} />
        </ListItemCompound.Text>
      </ListItemCompound.Content>

      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <ListItemCompound.TrailingContent>
          {currencySymbol}
          {expense.amount}
        </ListItemCompound.TrailingContent>
      </Box>
    </ListItemCompound>
  );
}
