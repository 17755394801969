import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import currencyToSymbolMap from "currency-symbol-map/map";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { usePageTitle } from "../common/TitleContext";
import ReceiptImagePreview from "../components/ReceiptImagePreview";
import { setReceiptClaimCategoryAmount } from "../redux/slices/claimsSlice";
import { RootState } from "../redux/store";
import CardContainer from "./CardContainer";
import { CategoryAmount } from "../types/paymentTypes";
import { useUserDetails } from "src/common/hooks/useUserDetails";

function ReceiptAmountPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { category } = useParams<{ category: string }>();

  usePageTitle(t("claim_chooseClaimReceipt"));
  const { currencyCode } = useUserDetails();

  const receiptClaim = useSelector(
    (state: RootState) => state.claims.receiptClaim
  );

  const isDisplaced = useSelector(
    (state: RootState) => state.auth.profile?.is_displaced ?? false
  );

  const categories = receiptClaim?.items ?? [];

  const categoryTitle =
    categories.find((cat) => cat.category === category)?.value ?? "";
  const categoryAmount =
    categories.find((cat) => cat.category === category)?.amount?.toString() ??
    "";
  const isFirstCategory = categories[0].category === category;

  const [amount, setAmount] = useState(categoryAmount);
  const [currency, setCurrency] = useState<string>(currencyCode);

  const currencyOptions = Object.entries(currencyToSymbolMap).map(
    ([code, symbol]) => ({ code, label: `${code} (${symbol})` })
  );

  const isContinueButtonDisabled = (): boolean => {
    const amountNumber = parseFloat(amount);

    return !(amountNumber > 0);
  };

  const onContinue = () => {
    const amountNumber = parseFloat(amount);

    if (!isNaN(amountNumber) && category) {
      dispatch(
        setReceiptClaimCategoryAmount({
          key: category,
          amount: amountNumber,
        })
      );

      // We need the next category by order not by amount existing to support "back" functionality
      const currentCategoryIndex = categories?.findIndex(
        (item: CategoryAmount) => item.category === category
      );

      const nextCategory =
        categories.length >= currentCategoryIndex + 1
          ? categories[currentCategoryIndex + 1]
          : false;

      if (nextCategory) {
        navigate(`/payment/submit/receipt/amount/${nextCategory.category}`);
      } else {
        navigate("/payment/submit/receipt/summary");
      }
      //  else {
      //   navigate(`/payment/submit/receipt/payment-methods`);
      // }
    }
  };

  return (
    <CardContainer>
      <CardContainer.Header>
        <CardContainer.HeaderContent>
          <CardContainer.HeaderTextItem>
            {categories.length > 1
              ? categoryTitle
              : t("receiptClaim_amount_totalTitle")}
          </CardContainer.HeaderTextItem>
          <CardContainer.HeaderTextItem>
            {t("receiptClaim_amount_description")}
          </CardContainer.HeaderTextItem>
        </CardContainer.HeaderContent>
        <CardContainer.TopRightContent>
          <ReceiptImagePreview />
        </CardContainer.TopRightContent>
      </CardContainer.Header>
      <CardContainer.Content>
        <form>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Autocomplete
              disableClearable={true}
              data-test-id="amount-control"
              options={currencyOptions}
              value={currencyOptions.find((option) => option.code === currency)}
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) => {
                setCurrency(newValue?.code || "");
              }}
              style={{ width: "auto", minWidth: "140px" }}
              renderInput={(params) => (
                <TextField
                  data-test-id="amount-textfield"
                  {...params}
                  label={t("currencyPicker_title")}
                  variant="outlined"
                />
              )}
              disabled={!isDisplaced || !isFirstCategory}
            />
            <FormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-amount">
                {t("receiptClaim_amount_totalTitle")}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={amount}
                type="number"
                onChange={(e) => setAmount(e.target.value)}
                label={t("receiptClaim_amount_totalTitle")}
                inputProps={{
                  min: 0,
                }}
              />
            </FormControl>
          </Box>

          <Button
            sx={{ textTransform: "initial", fontWeight: "bold", mt: 2 }}
            variant="contained"
            data-test-id="submit-button"
            color="primary"
            size="large"
            fullWidth
            type="submit"
            disabled={isContinueButtonDisabled()}
            onClick={onContinue}
          >
            {t("continue_text")}
          </Button>
        </form>
      </CardContainer.Content>
    </CardContainer>
  );
}

export default ReceiptAmountPage;
