import { Theme as MUITheme } from "@mui/material/styles";

export enum SupportMethod {
  email = "email",
  telephone = "telephone",
}

export type NonNullableConfig<T> = Exclude<T, undefined>;

export interface ThemeConfig<T = MUITheme> {
  muiTheme: T;
  topBarLogoWidth: number | string;
  logoHeight: number;
  appBarColor: string;
  virtualCardBackgroundColor: string;
}

export interface BaseConfig {
  theme: ThemeConfig;
  features: Partial<{
    loginPagePrivacyPolicy: boolean;
  }>;
  assets: {
    favIcon: string;
    lightLogo: string;
    loginLogo: string;
  };
  demoMode: boolean;
}

export enum Client {
  Iqvia = "iqvia",
  Nmible = "nmible",
  Elligo = "elligo",
  Ich = "ich",
}

export interface ClientConfig extends BaseConfig {
  clientName: string;
  clientId: Client;
  supportEmail?: string;
  supportTelephone?: string;
  inactivityTimeoutMinutes: number;
  pwaPromptThresholdDays: number;
  enableNotification?: boolean;
  subClients?: {
    [key: string]: Partial<ClientConfig>; // Or define subclient interface
  };
}

export type ConfigSelector<T> = (config: ClientConfig) => T;
