import { z } from "zod";
import * as ibantools from "ibantools";
import { Form, FormField } from "../types/payment-method-types";
import i18n from "i18next";

export class SchemaGenerator {
  static generateFieldSchema(field: FormField): z.ZodTypeAny {
    const fieldName = field.label || field.name;

    if (field.type === "iban") {
      return z
        .string()
        .transform((val) => val.trim().replace(/\s/g, ""))
        .refine((val) => !val || ibantools.isValidIBAN(val), {
          message: i18n.t("invalid_field_error", { "0": fieldName }),
        });
    }

    if (field.type === "number") {
      const numberSchema = z.coerce.number();
      return field.required
        ? numberSchema.min(1, {
            message: `${field.label} is required`,
          })
        : numberSchema.optional();
    }

    // TODO: Missing translations for form labels and messages
    let stringSchema = z.string().trim();

    if (field.type === "email") {
      stringSchema = stringSchema.email(i18n.t("error_email"));
    }

    if (field.minLength || field.maxLength) {
      let lengthSchema = stringSchema;

      if (
        field.minLength &&
        field.maxLength &&
        field.minLength === field.maxLength
      ) {
        lengthSchema = lengthSchema.length(field.minLength, {
          message: i18n.t("error_valueExactLength", {
            "0": fieldName,
            "1": field.minLength,
          }),
        });
      } else if (field.minLength && field.maxLength) {
        lengthSchema = lengthSchema.min(field.minLength).max(field.maxLength, {
          message: i18n.t("error_valueBetweenMinMax", {
            "0": fieldName,
            "1": field.minLength,
            "2": field.maxLength,
          }),
        });
      } else if (field.maxLength) {
        lengthSchema = lengthSchema.max(field.maxLength, {
          message: i18n.t("error_valueExceedsMax", {
            "0": fieldName,
            "1": field.maxLength,
          }),
        });
      }

      stringSchema = lengthSchema;
    }

    if (field.pattern) {
      stringSchema = stringSchema.regex(new RegExp(field.pattern), {
        message: i18n.t("invalid_field_error", { "0": fieldName }),
      });
    }

    if (field.required && !field.minLength) {
      return stringSchema.min(1, {
        message: `${field.label} is required`,
      });
    }

    return stringSchema.optional();
  }

  static generateFormSchema(form: Form) {
    const shape: Record<string, z.ZodTypeAny> = {};

    form.fields.forEach((field) => {
      if (field.type === "object" && field.fields) {
        const nestedShape: Record<string, z.ZodTypeAny> = {};
        field.fields.forEach((nestedField) => {
          nestedShape[nestedField.name] = this.generateFieldSchema(nestedField);
        });
        shape[field.name] = z.object(nestedShape);
      } else {
        shape[field.name] = this.generateFieldSchema(field);
      }
    });

    return z.object(shape);
  }
}
