import { useState } from "react";
import { type IIdleTimer, useIdleTimer } from "react-idle-timer";

export const useIdleTimeout = (
  onPrompt: () => void,
  onIdle: (event?: Event, idleTimer?: IIdleTimer) => void,
  idleTimeInMinutes: number
) => {
  const idleTimeout = 1000 * 60 * idleTimeInMinutes;

  const [isIdle, setIdle] = useState(false);

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle: 60 * 1000, // One minute before logout
    onPrompt: onPrompt,
    onIdle: onIdle,
  });

  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};
