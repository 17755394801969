import ReplayIcon from "@mui/icons-material/Replay";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { useTranslation } from "react-i18next";
import { ErrorProps } from "./ErrorFallback.types";
import { useConfig } from "src/config/useConfig";
import {
  selectSupportMethod,
  selectSupportContact,
} from "src/config/selectors";

export const Error = (props: ErrorProps) => {
  const {
    icon = "/images/server_warning.svg",
    statusCode,
    title,
    message,
    buttonAction,
    isRefreshBtn = true,
    buttonText,
  } = props;
  const { t } = useTranslation();
  const { getConfigValue } = useConfig();

  const supportMethod = getConfigValue(selectSupportMethod);
  const supportContact = getConfigValue(selectSupportContact);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        marginTop: "20px",
      }}
    >
      <img
        data-test-id="error-icon"
        src={icon}
        alt="Error Icon"
        style={{ width: 148, height: 148, marginBottom: "40px" }}
      />
      <Typography
        data-test-id="error-status-code"
        variant="subtitle2"
        fontWeight={500}
        color="text.secondary"
      >
        {statusCode}
      </Typography>
      <Typography
        data-test-id="error-title"
        variant="h6"
        fontWeight={500}
        color="text.primary"
        style={{ margin: "20px 0" }}
      >
        {t(title)}
      </Typography>
      <Typography data-test-id="error-message" variant="body1">
        {t(message)}
      </Typography>
      <Box sx={{ margin: "30px 0" }}>
        <Button
          data-test-id="error-button"
          size="large"
          variant="contained"
          color="primary"
          onClick={buttonAction}
        >
          {isRefreshBtn && <ReplayIcon style={{ marginRight: "10px" }} />}
          {isRefreshBtn ? t("retry") : t(buttonText ?? "retry")}
        </Button>
      </Box>
      {supportMethod && (
        <>
          <Typography
            data-test-id="help-button"
            variant="subtitle2"
            fontWeight={500}
            color="#000000"
          >
            {t("participantIdHelp_subtitle")}
          </Typography>
          <Typography
            data-test-id="help-email"
            variant="subtitle2"
            fontWeight={500}
            color="#000000"
          >
            {supportContact}
          </Typography>
        </>
      )}
    </Container>
  );
};
