import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import HomeIcon from "@mui/icons-material/Home";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import NumbersIcon from "@mui/icons-material/Numbers";
import DataObjectIcon from "@mui/icons-material/DataObject";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { FieldType } from "../types/payment-method-types";

// Map of property names to icons
const propertyIconMap: Record<string, React.ReactElement> = {
  email: <EmailIcon />,
  name: <PersonIcon />,
  full_name: <PersonIcon />,
  givenName: <PersonIcon />,
  middleName: <PersonIcon />,
  familyName: <PersonIcon />,
  phone: <PhoneIcon />,
  telephone: <PhoneIcon />,
  tel: <PhoneIcon />,
  address: <HomeIcon />,
  addressLineOne: <HomeIcon />,
  address_lineOne: <HomeIcon />,
  city: <LocationCityIcon />,
  address_city: <LocationCityIcon />,
  state: <LocationOnIcon />,
  address_state: <LocationOnIcon />,
  zipCode: <MarkunreadMailboxIcon />,
  zip: <MarkunreadMailboxIcon />,
  address_zip: <MarkunreadMailboxIcon />,
  postalCode: <MarkunreadMailboxIcon />,
  iban: <AccountBalanceIcon />,
  routing: <AccountBalanceIcon />,
  account: <AccountBalanceIcon />,
  sort_code: <AccountBalanceIcon />,
  type: <AccountTreeIcon />,
};

// Map of field types to icons (fallback)
const typeIconMap: Record<FieldType, React.ReactElement> = {
  email: <EmailIcon />,
  name: <PersonIcon />,
  tel: <PhoneIcon />,
  address: <HomeIcon />,
  string: <TextFieldsIcon />,
  number: <NumbersIcon />,
  object: <DataObjectIcon />,
  search: <></>,
  select: <ArrowDropDownCircleIcon />,
  radio: <RadioButtonCheckedIcon />,
  address_lineOne: <HomeIcon />,
  address_city: <LocationCityIcon />,
  address_state: <LocationOnIcon />,
  address_zip: <MarkunreadMailboxIcon />,
  iban: <AccountBalanceIcon />,
};

export const getFieldIcon = (
  propertyName: string,
  fieldType: FieldType
): React.ReactElement => {
  // First try to match by property name (case-insensitive)
  const normalizedPropertyName = propertyName.toLowerCase();
  const propertyIcon = Object.entries(propertyIconMap).find(([key]) =>
    normalizedPropertyName.includes(key.toLowerCase())
  )?.[1];

  if (propertyIcon) {
    return propertyIcon;
  }

  // Fallback to type-based icon
  return typeIconMap[fieldType] || <></>;
};
