// import React, { useEffect, useState } from "react";
// import { Box, Skeleton } from "@mui/material";

// import HtmlVccRenderer from "../components/VccCard/HtmlVccRenderer";
// import VccCard from "../components/VccCard/VccCard";
// import paymentService from "../api/paymentService";
// import { usePageTitle } from "../common/TitleContext";
// import { useTranslation } from "react-i18next";
// import { useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { RootState } from "../redux/store";
// import { getPaymentMethodByType } from "../redux/slices/paymentMethodsSlice";
// import { PaymentType } from "../types/paymentTypes";
// import { useErrorHandler } from "src/common/hooks";

const VccVisualise: React.FC = () => {
  return null;
  // const [htmlCode, setHtmlCode] = useState<any>(null);
  // const [loading, setLoading] = useState(false);
  // const { handleServerError } = useErrorHandler();
  // const { t } = useTranslation();

  // const location = useLocation();
  // const currentPath = location.pathname;
  // const isVccCreation = currentPath.endsWith("/created");

  // const vccPaymentMethod = useSelector((state: RootState) => {
  //   return getPaymentMethodByType(state, PaymentType.VirtualCard);
  // });

  // usePageTitle(vccPaymentMethod?.title || t("virtualCard_title"));

  // useEffect(() => {
  //   setLoading(true);
  //   api(paymentService.getVccPaymentVisualise())
  //     .then((response) => {
  //       setHtmlCode(response);
  //     })
  //     .catch((error) => {
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, []);

  // return (
  //   <Box width="100%">
  //     {loading ? (
  //       <>
  //         <Skeleton
  //           variant="rounded"
  //           height={184}
  //           style={{ marginBottom: 16 }}
  //         />

  //         {!isVccCreation && <Skeleton variant="rounded" height={72} />}
  //       </>
  //     ) : (
  //       <>
  //         {htmlCode && <HtmlVccRenderer htmlCode={htmlCode?.data} />}
  //         {htmlCode && !isVccCreation && <VccCard />}
  //       </>
  //     )}
  //   </Box>
  // );
};

export default VccVisualise;
