import React from "react";
import { TextFormControl } from "./TextFormControl";
import { EmailFormControl } from "./EmailFormControl";
import { PhoneFormControl } from "./PhoneFormControl";
import { DropdownFormControl } from "./DropdownFormControl";
import { RadioGroupFormControl } from "./RadioGroupFormControl";
import { FormControlProps } from "./BaseFormControl";
import { SearchFormControl } from "./SearchFormControl";
import { IbanFormControl } from "./IbanFormControl";

export class FormControlFactory {
  static createFormControl(props: FormControlProps): React.ReactElement {
    switch (props.field.type) {
      case "email":
        return <EmailFormControl {...props} />;
      case "tel":
        return <PhoneFormControl {...props} />;
      case "search":
        return <SearchFormControl {...props} />;
      case "select":
      case "address_state":
        return <DropdownFormControl {...props} />;
      case "radio":
        return <RadioGroupFormControl {...props} />;
      case "iban":
        return <IbanFormControl {...props} />;
      default:
        return <TextFormControl {...props} />;
    }
  }
}
