import React from "react";
import { useTranslation } from "react-i18next";
import CardViewer from "./components/CardViewer";
import OnboardingComponent, {
  OnboardingDataItem,
} from "./components/OnboardingComponent";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/slices/toastSlice";
import { CardProvider } from "./components/CardContext";
import { usePaymentMethodForms } from "../../features/payment-methods/contexts/payment-method-form-context";
const NewCardSuccessPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { goToNextPage } = usePaymentMethodForms();

  function getData(): OnboardingDataItem[] {
    return [
      {
        title: t("vcc_created_title"),
        description: [
          t("cardOnboarding_description_newCard_1"),
          t("cardOnboarding_balance", { balance: "00.00", currency: "USD" }),
        ],
        imageSrc: () => (
          <CardProvider>
            <CardViewer key="v2" />
          </CardProvider>
        ),
      },
    ];
  }

  const onFinish = () => {
    dispatch(showToast(t("paymentMethod_updated")));
    goToNextPage();
  };

  return (
    <OnboardingComponent
      data={getData()}
      finishLabel={t("ok")}
      onFinish={onFinish}
    />
  );
};

export default NewCardSuccessPage;
